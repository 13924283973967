import { ProjectData } from 'src/types'

const projectData: ProjectData = {
  projects: [
    {
      id: 0,
      name: 'Casino Game',
      href: '#',
      description:
        'Uncover the success story behind a casino game’s journey from concept to market dominance, showcasing the strategic blend of captivating gameplay, stunning graphics, and lucrative monetization strategies.',
      imageSrc: '/assets/detail/casino/Untitled-design-29.png',
      tags: ['REACT.JS', 'ELECTRON', 'Unity'],
      stack: 'web3'
    },
    {
      id: 1,
      name: 'SmartSuite',
      href: '#',
      description:
        'SmartSuite is a web and mobile no-code platform that serves as a tool for managing everything needed to get work done: spreadsheets, documents, collaboration tools, databases, file management products and automation capabilities — all in one place.',
      imageSrc: '/assets/detail/workManagement/Snimok_ekrana_2023_08_02_155141_kopiya_ae38f41b70.jpg',
      tags: ['REACT.JS', 'Python', 'Cypress', 'DATABASES'],
      stack: 'web'
    },
    {
      id: 2,
      name: 'White Label Crypto Wallet',
      href: '#',
      description: 'White Label Crypto Wallet Solution for Your Business Grow',
      imageSrc: '/assets/detail/cryptoWallet/use-cases-of-private-blockchain-in-healthcare.jpg',
      tags: ['Ethereum', 'REACT.JS', 'PostgreSQL'],
      stack: 'web3'
    },
    {
      id: 3,
      name: 'Gs Diamonds',
      href: '#',
      description:
        'Gs Diamonds is an Australian owned family business. The company was founded by a married couple with 30 years of experience working with jewelry. They decided to make an online store with additional features for customers.',
      imageSrc: '/assets/detail/Diamond/slide_22-m5FU0QLt35I-21F6.png',
      tags: ['REACT', 'MySQL', 'Laravel'],
      stack: 'web'
    },
    {
      id: 4,
      name: 'Nebeus',
      href: '#',
      description:
        "Nebeus is the first financial services platform that uses blockchain technology to ensure the complete security and transparency of users' assets. It allows you to monitor your bitcoin accounts, pay, trade, and request loans easily.",
      imageSrc: '/assets/detail/nebeus/Nebeus.png.jpg',
      tags: ['REACT NATIVE', 'REDUX', 'BLOCKCHAIN'],
      stack: 'web3'
    },
    {
      id: 5,
      name: 'Pixpel',
      href: '#',
      description:
        'Pixpel is a full-fledged P2E ecosystem where game developers can independently create game tokens and NFTs, and players can buy and sell them within the integrated NFT Marketplace.',
      imageSrc: '/assets/detail/pixpel/Pixpel.png.jpg',
      tags: ['REACT.JS', 'POSTGRE SQL', 'BLOCKCHAIN'],
      stack: 'web3'
    },
    {
      id: 6,
      name: 'Workplace Transformation',
      href: '#',
      description:
        'Our Fortune 500 client is a leading professional services provider that embraces around 400K employees working in 700 office locations worldwide.',
      imageSrc: '/assets/detail/workplace/Enabling-enterprise-workplace_cover-1300px.jpg',
      tags: ['REACT.JS', 'POSTGRE SQL'],
      stack: 'web'
    },
    {
      id: 7,
      name: 'Crypto Moves',
      href: '#',
      description: 'we build Blockchain web and backend systems that bring NFTs & Cryptocurrencies to life.',
      imageSrc: '/assets/detail/cryptoMove/crypto-image.jpg',
      tags: ['REACT.JS', 'POSTGRE SQL', 'Ethereum'],
      stack: 'web3'
    },
    {
      id: 8,
      name: 'Zytrius',
      href: '#',
      description:
        'Zytrius is a fully integrated solution for all CGC Games’ gaming and entertainment needs: slots, dynamic sportsbook, promotions, and interactive auctions',
      imageSrc: '/assets/detail/zytrius/66a0adeea830396f57f2b770_Zytrius Img hero.jpg',
      tags: ['RUST', 'REACT.JS', 'POSTGRE SQL', 'Ethereum'],
      stack: 'web'
    },
    {
      id: 9,
      name: 'WINZONE',
      href: '#',
      description:
        'WINZONE is a sports betting mobile application that has been developed to support the next generation of sports betting.',
      imageSrc: '/assets/detail/winzone/slider2.95d61abb85d440b86c36.png',
      tags: ['NEST.JS', 'REACT.JS', 'POSTGRE SQL', 'ANGULAR'],
      stack: 'web'
    },
    {
      id: 10,
      name: 'Blockchain for EMR ',
      href: '#',
      description: 'Blockchain-powered framework for Electronic Medical Records (EMR)',
      imageSrc: '/assets/detail/health/Blockchain-powered-framework-for-Electronic-Medical-Records-BG-1024x496.jpg',
      tags: ['NEST.JS', 'REACT.JS', 'RIPPLE', 'ETHEREUM'],
      stack: 'web3'
    },
    {
      id: 11,
      name: 'DLCC',
      href: '#',
      description:
        'DLCC is a revolution in the finance sector. The platform is transforming the traditional finance landscape with decentralized finance to facilitate automated crypto trading, lending, and borrowing without geographic restrictions at negotiable fees and interest rates with enhanced security and transparency.',
      imageSrc: '/assets/detail/dlcc/header-800x412.png',
      tags: ['NEST.JS', 'REACT.JS', 'NODE.JS', 'PYTHON'],
      stack: 'web'
    },
    {
      id: 12,
      name: 'Luxury Liquor Marketplace',
      href: '#',
      description:
        'This case study explores Paladin’s vision of transforming the landscape of high-end spirit investment and NFT trading with a seamless cloud-to-cloud migration leveraging AWS. ',
      imageSrc: '/assets/detail/liquior/660289facf9d83a421c5e571_paladin-case-study.png',
      tags: ['REACT.JS', 'NODE.JS', 'ANGULAR'],
      stack: 'web'
    },
    {
      id: 13,
      name: 'Servisus: Simplifying Local Help for Everyday Tasks',
      href: '#',
      description: 'Servisus is a mobile app designed to make everyday tasks easier for people in cities. ',
      imageSrc: '/assets/detail/servisus/img-SERVISUS.png',
      tags: ['FLUTTER', 'NODE.JS', 'GOOGLE CLOUD'],
      stack: 'mobile'
    },
    {
      id: 14,
      name: 'Quotes+ – Your Pets’ Lifelong Protector',
      href: '#',
      description: 'Quotes+ is a comprehensive app that connects pet owners with essential services.',
      imageSrc: '/assets/detail/quote/Quotes-UPWORK-e1726136510219.png',
      tags: ['FLUTTER', 'NODE.JS', 'GOOGLE CLOUD'],
      stack: 'mobile'
    },
    {
      id: 15,
      name: '****** App',
      href: '#',
      description:
        "****** is a food ordering app, and it is designed to deliver the foods as per the customer's requirement from the app.",
      imageSrc: '/assets/detail/appManagement/1-main-img.png',
      tags: ['ANDROID', 'IOS', 'PHP', 'MYSQL'],
      stack: 'mobile'
    },
    {
      id: 16,
      name: '****** App',
      href: '#',
      description:
        "****** is a food ordering app, and it is designed to deliver the foods as per the customer's requirement from the app.",
      imageSrc: '/assets/detail/visitor/newdevrev-collage.png.jpg',
      tags: ['ANDROID', 'IOS', 'PHP', 'MYSQL'],
      stack: 'mobile'
    },
    {
      id: 17,
      name: 'Telehealth',
      href: '#',
      description:
        "Healthcare App for making appointments with doctors, both offline and online, with video-consultation. Also, the system is able to manage doctors' schedules and make relations between clinics and doctors. The app can help improve access to healthcare and make the process of scheduling appointments more efficient and convenient for patients.",
      imageSrc: '/assets/detail/telehealth/Frame-32990.jpg',
      tags: ['ANDROID', 'IOS', 'PHP', 'MYSQL'],
      stack: 'mobile'
    },
    {
      id: 18,
      name: 'arrow to the right',
      href: '#',
      description: 'A mobile app focused on delivering a daily dose of self-endorsement',
      imageSrc: '/assets/detail/afffirmations/iphone-2d5125e1224060ed7db5ab69b90f6732.png',
      tags: ['REACT', 'TYPESCRIPT', 'STORYBOOK', 'REACT NATIVE', 'REDUX'],
      stack: 'mobile'
    },
    {
      id: 19,
      name: 'VerifiID',
      href: '#',
      description: 'Developing a system to replace personal IDs for age verification',
      imageSrc: '/assets/detail/verifiID/verifi_Id_CS_Banner_a0b3e3bedf.png',
      tags: ['REACT', 'NEST', 'REACT NATIVE', 'REACT NATIVE', 'REDUX-SAGA'],
      stack: 'web'
    },
    {
      id: 20,
      name: 'Revenue optimization system',
      href: '#',
      description:
        'If you ever planned to vacation on the Adriatic, you’ve probably come across Maistra Hospitality Group. With 18 hotels, 11 tourist villages, and 6 campsites in internationally sought-after destinations like Dubrovnik and Rovinj, it is the most prestigious hospitality group in Croatia.',
      imageSrc: '/assets/detail/revenue/case-edmond-hero.jpg',
      tags: ['PYTHON', 'TENSORFLOW', 'NUMPY', 'REACT NATIVE', 'AWS'],
      stack: 'ai'
    }
  ]
}

export default projectData
