import { useParams } from 'react-router-dom'

import ProjectDetail from 'src/constants/detailData'
import projectData from 'src/constants/projects'

import DetailLanding from './DetailLanding'
import Description from './Descriptions'
import Service from './Service'
import TechStack from './Techstack'
import ProcessOverview from './ProcessOverview'
import Design from './Design'
import Gocha from './Gocha'
import ViewFooter from './ViewFooter'
import ViewMore from './ViewMore'
import { useEffect } from 'react'

const Details: React.FC = () => {
  const params = useParams()
  const detailData = ProjectDetail.detailData
  const projectId = Number(params.id || 0)
  const projects = projectData.projects

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 500)
  }, [])

  return (
    <div className='detail-container px-5 md:px-6 2xl:px-11 overflow-hidden'>
      {detailData.map((item, index) => (
        <div key={index} className={`flex ${index == projectId ? 'flex flex-col' : 'hidden'}`}>
          <DetailLanding projectIntro={item.projectIntro} projectId={projectId} />
          <Description descriptions={item.descriptions} />
          <Service services={item.services} />
          <TechStack techstacks={item.techstacks} />
          <ProcessOverview firstPart={item.firstPart} />
          <Design secondPart={item.secondPart} />
          <Gocha thirdPart={item.thirdPart} />
          <ViewFooter />
          <ViewMore projects={projects} />
        </div>
      ))}
    </div>
  )
}

export default Details
