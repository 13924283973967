import React, { useEffect, useState } from 'react'
import RotateInOut3D from 'src/animation/RotateInOut3D'
import ScaleInOut from 'src/animation/ScaleInOut'

import IconButton from 'src/components/IconButton'
import SkillRow from 'src/components/SkillRow'
import primarySkillsData from 'src/constants/primarySkills'

const Skill: React.FC = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(0)

  const handleRowClick = (index: number) => {
    setOpenIndex(openIndex === index ? null : index)
  }

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 500)
  }, [])

  return (
    <>
      <div className='overflow-hidden detail-container px-5 py-14 md:px-11 md:py-40 relative'>
        <ScaleInOut delay={0.5}>
          <img
            src='/assets/gif/Loop 3 Dark.gif'
            className='absolute hidden dark:block -top-52 left-1/4 max-md:left-0 max-sm:-top-20 -z-20'
          />
          <img
            src='/assets/gif/Loop 3 Light.gif'
            className='absolute block dark:hidden -top-52 left-1/4 max-md:left-0 max-sm:-top-20 -z-20'
          />
        </ScaleInOut>
        <ScaleInOut delay={1}>
          <div className='flex flex-col justify-center mt-12 items-start gap-10 md:flex-row md:justify-between md:items-center md:self-stretch'>
            <div className='flex flex-col'>
              <p className='text-50 dark:text-white text-black font-normal md:tracking-wider font-bruno'>Primary</p>
              <p className='text-50 dark:text-white text-black font-normal md:tracking-wider font-bruno'>
                Dvelopment Skills
              </p>
            </div>
          </div>
        </ScaleInOut>

        <RotateInOut3D duration={1} delay={1.1} offsetY={100}>
          <div className='mt-50 max-md:mt-8 font-archivo relative'>
            <img
              src='/assets/gif/Loop 4 Dark.gif'
              className='absolute hidden dark:block top-0 max-md:top-50 max-sm:top-1/3 -left-1/3 -z-20'
            />
            <img
              src='/assets/gif/Loop 4 Light.gif'
              className='absolute block dark:hidden top-0 max-md:top-50 max-sm:top-1/3 -left-1/3 -z-20'
            />
            {primarySkillsData.skills.map((skill, index) => (
              <div key={skill.id}>
                <hr className='text-jet' />
                <SkillRow
                  name={skill.name}
                  description={skill.description}
                  isOpen={openIndex === index}
                  onToggle={() => handleRowClick(index)}
                >
                  {skill.icons.map((IconComponent, index) => (
                    <div key={index} className='mt-3'>
                      <IconButton
                        title={skill.lang[index]}
                        icon={<IconComponent />}
                        darkIcon={<IconComponent />}
                        className='font-archivo'
                      />
                    </div>
                  ))}
                </SkillRow>
              </div>
            ))}

            <hr className='text-jet' />
          </div>
        </RotateInOut3D>
      </div>
    </>
  )
}

export default Skill
