import React from 'react'
interface IconButtonProps {
  title: string
  icon: React.ReactNode
  darkIcon: React.ReactNode
  className?: string
}

const IconButton: React.FC<IconButtonProps> = ({ title, icon, darkIcon, className }) => {
  return (
    <button
      className={`text-14 inline-flex p-5 justify-center items-center gap-4 rounded-lg uppercase dark:bg-codgray bg-gray bg-opacity-30 dark:text-white text-black hover:dark:bg-illicitGreen hover:bg-prime transition-all duration-300 ease-in-out hover:dark:text-black hover:text-black focus:dark:bg-prime focus:bg-black focus:dark:text-black focus:text-white font-archivo ${className}`}
    >
      <div className='hidden dark:block'>{icon}</div>
      <div className='block dark:hidden'>{darkIcon}</div>
      {title}
    </button>
  )
}

export default IconButton
