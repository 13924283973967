import { useEffect, useRef, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import links from 'src/constants/links'

interface SidebarProps {
  sidebarOpen: boolean
  setSidebarOpen: (arg: boolean) => void
}

const Sidebar = ({ sidebarOpen, setSidebarOpen }: SidebarProps) => {
  const [isHovered, setIsHovered] = useState(false)

  const gridItems = Array.from({ length: 12 }, () => 0)

  const trigger = useRef<HTMLButtonElement | null>(null)
  const sidebar = useRef<HTMLElement | null>(null)

  const storedSidebarExpanded = localStorage.getItem('sidebar-expanded')
  const [sidebarExpanded, setSidebarExpanded] = useState(
    storedSidebarExpanded === null ? false : storedSidebarExpanded === 'true'
  )

  const handleClose = () => {
    window.scrollTo(0, 0)
    setSidebarOpen(!sidebarOpen)
    for (let index = gridItems.length; index > 0; index--) {
      const gridElement = document.getElementById(`grid-item-${index}`)
      setTimeout(
        () => {
          gridElement?.classList.add(`class_effect`)
        },
        50 * (gridItems.length - index + 2)
      )
    }
    setTimeout(
      () => {
        for (let index = gridItems.length; index > 0; index--) {
          const gridElement = document.getElementById(`grid-item-${index}`)
          setTimeout(
            () => {
              gridElement?.classList.remove(`class_effect`)
            },
            50 * (gridItems.length - index + 2)
          )
        }
      },
      50 * (gridItems.length + 8)
    )
  }

  // Disable scroll when sidebar is open
  useEffect(() => {
    const body = document.querySelector('body')
    if (sidebarOpen) {
      body?.classList.add('overflow-hidden') // Add class to disable scroll
    } else {
      body?.classList.remove('overflow-hidden') // Remove class to enable scroll
    }
  }, [sidebarOpen])
  // Close sidebar on click outside
  useEffect(() => {
    const clickHandler = (event: MouseEvent) => {
      if (
        sidebar.current &&
        trigger.current &&
        !sidebar.current.contains(event.target as Node) &&
        !trigger.current.contains(event.target as Node)
      ) {
        setSidebarOpen(false)
      }
    }

    document.addEventListener('click', clickHandler)
    return () => {
      document.removeEventListener('click', clickHandler)
    }
  }, [sidebarOpen]) // Add sidebarOpen as a dependency

  // Close if the esc key is pressed
  useEffect(() => {
    const keyHandler = (event: KeyboardEvent) => {
      if (!sidebarOpen || event.key !== 'Escape') return
      setSidebarOpen(false)
    }
    document.addEventListener('keydown', keyHandler)
    return () => {
      document.removeEventListener('keydown', keyHandler)
    }
  }, [sidebarOpen])

  useEffect(() => {
    localStorage.setItem('sidebar-expanded', sidebarExpanded.toString())
    const body = document.querySelector('body')
    if (sidebarExpanded) {
      body?.classList.add('sidebar-expanded')
    } else {
      body?.classList.remove('sidebar-expanded')
    }
  }, [sidebarExpanded])

  return (
    <>
      <div className='fixed z-50 inset-0 grid h-full w-full bg-transparent select-none pointer-events-none'>
        {gridItems.map((item, index) => (
          <div key={index} id={`grid-item-${index + 1}`} />
        ))}
      </div>
      <aside
        ref={sidebar}
        className={`fixed z-30 top-5 max-w-600 max-h-800 right-6 max-md:right-0 max-md:top-0 max-md:w-full overflow-y-hidden duration-300 ease-linear dark:bg-black bg-white rounded-3xl ${
          sidebarOpen ? 'translate-x-0 opacity-100 visible overscroll-y-none' : 'translate-x-full opacity-0 invisible'
        }`}
      >
        <div className='flex flex-col gap-6 px-10 pt-10 pb-12 rounded-3xl'>
          <div className='flex flex-col'>
            <div className='flex justify-between'>
              <span className='flex items-center text-18 dark:text-white text-black font-archivo'>Menu</span>
              <div className='flex gap-4 items-center '>
                <span className='text-14 dark:text-white text-black font-archivo'>Close</span>
                <button
                  ref={trigger}
                  className='flex justify-center p-2 items-center rounded-full border border-customGray'
                  onClick={() => setSidebarOpen(!sidebarOpen)}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='20'
                    height='20'
                    viewBox='0 0 20 20'
                    className={`fill-current dark:text-white ${
                      isHovered ? 'animatecss animatecss-rotateOut animatecss-infinite' : ''
                    }`}
                  >
                    <path
                      d='M15.625 4.375L4.375 15.625'
                      stroke='currentColor'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <path
                      d='M15.625 15.625L4.375 4.375'
                      stroke='currentColor'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div className='flex flex-col gap-6'>
            {links.map((item, index) => (
              <NavLink
                key={index}
                to={item.link}
                className='text-left text-18 dark:text-white text-black hover:dark:text-prime hover:text-prime font-archivo'
                onClick={handleClose}
              >
                {item.title}
              </NavLink>
            ))}
          </div>
          <div className='max-sm:flex max-sm:flex-col max-sm:gap-4 max-sm:items-center mt-32 grid grid-cols-2 items-end'>
            <div id='email' className='flex flex-col items-start gap-4'>
              <Link
                to='mailto: contact@silverdigitalbus.io'
                className='text-left text-14 font-medium underline dark:text-white text-black cursor-pointer hover:dark:text-prime hover:text-prime font-archivo'
              >
                contact@silverdigitalbus.io
              </Link>
              <span className='text-left text-14 font-medium dark:text-white text-black font-archivo'>
                <span className='font-archivo'>
                  ORLANDO, FL
                  <span className='underline font-archivo'>32806</span>
                </span>
              </span>
            </div>
            <div id='social' className='flex items-start gap-4 justify-between md:flex-col md:items-end font-archivo'>
              <Link
                to='https://www.linkedin.com/company/silverdigitalbus'
                className='dark:text-white text-black text-14 font-medium uppercase hover:dark:text-prime hover:text-prime font-archivo'
                onClick={() => setSidebarOpen(!sidebarOpen)}
              >
                LinkedIn
              </Link>
              <Link
                to='https://telegram.org/'
                className='dark:text-white text-black text-14 font-medium uppercase hover:dark:text-prime hover:text-prime font-archivo'
                onClick={() => setSidebarOpen(!sidebarOpen)}
              >
                Telegram
              </Link>
              <Link
                to='https://discord.com/'
                className='dark:text-white text-black text-14 font-medium uppercase hover:dark:text-prime hover:text-prime font-archivo'
                onClick={() => setSidebarOpen(!sidebarOpen)}
              >
                Discord
              </Link>
            </div>
          </div>
        </div>
      </aside>
    </>
  )
}

export default Sidebar
