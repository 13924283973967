import React from 'react'

const ViewFooter: React.FC = () => {
  return (
    <div className='w-full flex flex-col py-50 gap-4 border-b border-silverMedal dark:border-customGray items-center'>
      <span className='flex text-black font-archivo dark:text-white text-50 max-md:w-full max-md:justify-center'>
        Thank you ❤️
      </span>
      <span className='flex text-center font-archivo text-14 font-normal w-360 text-black dark:text-white '>
        Ready to call us and make you business worldwide!!?
      </span>
    </div>
  )
}

export default ViewFooter
