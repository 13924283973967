import React from 'react'

interface SkillRowProps {
  classname?: string
  name?: string
  description?: string
  children: React.ReactNode
  isOpen: boolean
  onToggle: () => void
}

const SkillRow: React.FC<SkillRowProps> = ({ classname, name, description, children, isOpen, onToggle }) => {
  return (
    <div className={`${classname}`}>
      <div className='grid grid-cols-1 skilltablet:grid-cols-2 gap-4 py-6 cursor-pointer' onClick={onToggle}>
        <div className={`skill-title dark:text-white text-black font-archivo`}>{name}</div>
        <div className='skill-description font-archivo'>
          {description}
          <div
            className={`grid overflow-hidden transition-all duration-1000 ease-in-out ${isOpen ? 'max-h-screen' : 'max-h-0 transition-all duration-1000 ease-in-out'}`}
          >
            <div className='overflow-hidden'>
              <div className='flex items-center content-center gap-4 flex-wrap mt-2'>{children}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SkillRow
