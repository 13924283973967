import React from 'react'
import Lettering from 'src/animation/Lettering'
import ScaleInOut from 'src/animation/ScaleInOut'
import FadeInOutUp from 'src/animation/FadeInOutUp'
import ImplodeExplodeInOut from 'src/animation/ImplodeExplodeInOut'
import ScrollDown from 'src/components/ScrollDown'

const Hero: React.FC = () => {
  return (
    <div id='hero' className='relative flex flex-col h-screen items-center justify-end dark:bg-hero-dark hero-light'>
      <div className='absolute top-0 left-0 w-full h-full -z-10'>
        <FadeInOutUp from={{ height: '100%' }} delay={0.5}>
          <video autoPlay muted loop className='object-cover h-full w-full'>
            <source src='/assets/mp4/hero.mp4' type='video/mp4' />
          </video>
        </FadeInOutUp>
      </div>
      <div className='detail-container px-50 max-lg:px-6 mb-40 xl:mb-48'>
        <ScaleInOut delay={1}>
          <>
            <p className='text-white text-center font-medium text-50 leading-tight md:text-start font-bruno'>
              Jack of All Trades in
            </p>
            <div className='-skew-x-12'>
              <p className='text-white text-center font-medium text-50 leading-tight md:text-start font-bruno'>
                {' Software'}{' '}
                <strong className='items-center text-black bg-aqua rounded-2xl font-bruno font-normal'>
                  Development.
                </strong>
              </p>
            </div>
          </>
        </ScaleInOut>

        <div className='justify-center items-center gap-6 mt-10 md:grid md:grid-cols-2 md:items-center md:justify-between'>
          <ImplodeExplodeInOut target='#hero-decription'>
            <div id='hero-decription' className='relative text-white text-center text-16 md:text-start font-archivo'>
              <Lettering text='Web, Mobile App development, Web3 Development, AI development, all jack of trades in software development' />
            </div>
          </ImplodeExplodeInOut>
          <ScrollDown />
        </div>
      </div>
    </div>
  )
}
export default Hero
