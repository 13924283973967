import { gsap } from 'gsap-trial'
import { TransitionContext } from '../context/TransitionContext'
import { useState, useContext, useRef, ReactNode } from 'react'
import useIsomorphicLayoutEffect from './useIsomorphicLayoutEffect'

interface Props {
  children: ReactNode
}

export default function TransitionLayout({ children }: Props) {
  const [displayChildren, setDisplayChildren] = useState(children)
  const { timeline, background } = useContext(TransitionContext)
  const el = useRef<HTMLDivElement>(null)

  useIsomorphicLayoutEffect(() => {
    if (children !== displayChildren) {
      if (timeline.duration() === 0) {
        // there are no outro animations, so immediately transition
        setDisplayChildren(children)
      } else {
        timeline.play().then(() => {
          // outro complete so reset to an empty paused timeline
          timeline.seek(0).pause().clear()
          setDisplayChildren(children)
        })
      }
    }
  }, [children])

  useIsomorphicLayoutEffect(() => {
    gsap.to(el.current, {
      background,
      duration: 1
    })
  }, [background])

  return <div ref={el}>{displayChildren}</div>
}
