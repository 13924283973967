export default {
  newProjects: [
    {
      title: 'Runeverse: Alpha Origins',
      description:
        'Our Web Design service is all about creating visually stunning and user-friendly websites that leave a lasting.',
      tags: ['HTML', 'Website Design', 'Development'],
      image: '/assets/resource/image_1.png'
    },
    {
      title: 'Weave.Inc',
      description: '',
      tags: ['Branding Design', 'Website Design', 'App Design', 'Development'],
      image: '/assets/resource/image_2.png'
    },
    {
      title: 'Cryptozy',
      description: '',
      tags: ['Branding Design', 'Website Design', 'App Design', 'Development'],
      image: '/assets/resource/image_3.png'
    },
    {
      title: 'Estatery',
      description: '',
      tags: ['Branding Design', 'Website Design', 'App Design', 'Development'],
      image: '/assets/resource/image_4.png'
    }
  ]
}
