import { ReactLenis } from 'lenis/dist/lenis-react'
import { motion, useMotionTemplate, useScroll, useTransform } from 'framer-motion'
import { useRef, useState, useEffect } from 'react'

export const SmoothScrollHero = () => {
  return (
    <div className='bg-zinc-950'>
      <ReactLenis
        root
        options={{
          lerp: 0.05
        }}
      >
        <Hero />
      </ReactLenis>
    </div>
  )
}

const Hero = () => {
  const [sectionHeight, setSectionHeight] = useState(2100) // Default height

  const updateSectionHeight = () => {
    const width = window.innerWidth

    // Set your responsive height logic here
    switch (true) {
      case width < 375:
        setSectionHeight(2300)
        break
      case width < 700:
        setSectionHeight(2300)
        break
      case width < 768:
        setSectionHeight(1800)
        break
      case width < 769:
        setSectionHeight(1300)
        break
      case width < 900:
        setSectionHeight(1600)
        break
      case width < 950:
        setSectionHeight(1800)
        break
      case width < 1024:
        setSectionHeight(2000)
        break
      case width < 1200:
        setSectionHeight(2300)
        break
      default:
        setSectionHeight(2700)
        break
    }
  }

  useEffect(() => {
    updateSectionHeight() // Set initial height
    window.addEventListener('resize', updateSectionHeight) // Update on resize

    return () => {
      window.removeEventListener('resize', updateSectionHeight) // Cleanup on unmount
    }
  }, [])

  return (
    <div style={{ height: `calc(${sectionHeight}px + 100vh)` }} className='relative w-full'>
      <ParallaxImages />
      <div className='absolute bottom-0 left-0 right-0 h-96 bg-gradient-to-b from-zinc-950/0 to-zinc-950' />
    </div>
  )
}

export default Hero
const ParallaxImages = () => {
  return (
    <div className='mx-auto max-w-5xl px-4 pt-[200px]'>
      <ParallaxImg
        src='/assets/member/teamate1.png'
        start={-200}
        end={200}
        className='w-1/3 max-md:w-[335px] max-md:h-[420px]'
        name='Christian Lee Richardson'
        content='Co-founder'
        alt='Christian Lee Richardson'
      />
      <ParallaxImg
        src='/assets/member/teamate2.png'
        start={200}
        end={-250}
        className='mx-auto w-2/3 max-md:w-[335px] max-md:h-[420px]'
        name='Jesse Rohner'
        content='Co-founder, Full Stack & Blockchain Team Lead'
        alt='Jesse Rohner'
      />
      <ParallaxImg
        src='/assets/member/teamate5.png'
        start={-200}
        end={200}
        className='ml-auto w-1/3 max-md:w-[335px] max-md:h-[420px]'
        name='Wendell Thompson'
        content='Senior Full stack AI developer'
        alt='Wendell Thompson'
      />
      <ParallaxImg
        src='/assets/member/teamate4.png'
        start={0}
        end={-500}
        className='ml-24 w-5/12 max-md:w-[335px] max-md:h-[420px]'
        name='William Andrew Franck'
        content='Senior Full Stack Developer'
        alt='William Andrew Franck'
      />
      <ParallaxImg
        src='/assets/member/teamate3.png'
        start={-200}
        end={-500}
        className='mx-auto w-2/3 max-md:w-[335px] max-md:h-[420px]'
        name='Vladislav Plazinskiy'
        content='Technical Team Lead'
        alt='Vladislav Plazinskiy'
      />
      <ParallaxImg
        src='/assets/member/teamate6.png'
        start={-300}
        end={-500}
        className='w-1/3 max-md:w-[335px] max-md:h-[420px]'
        name='Dylan Matthess'
        content='Full Stack Developer'
        alt='Dylan Matthess'
      />
      <ParallaxImg
        src='/assets/member/teamate7.png'
        start={-800}
        end={-500}
        className='ml-auto w-1/3 max-md:w-[335px] max-md:h-[420px]'
        name='Matthew Dennis'
        content='Full Stack Developer'
        alt='Matthew Dennis'
      />
      <ParallaxImg
        src='/assets/member/teamate8.png'
        start={-500}
        end={-500}
        className='w-1/3 max-md:w-[335px] max-md:h-[420px]'
        name='Irvan Wibowo'
        content='UI/UX Designer'
        alt='Irvan Wibowo'
      />
    </div>
  )
}

const ParallaxImg = ({
  className,
  alt,
  src,
  start,
  end,
  name,
  content
}: {
  className?: string
  alt: string
  src: string
  start: number
  end: number
  name: string
  content: string
}) => {
  const ref = useRef(null)

  const { scrollYProgress } = useScroll({
    target: ref,
    // @ts-ignore
    offset: [`${start}px end`, `end ${end * -1}px`]
  })

  const opacity = useTransform(scrollYProgress, [0.75, 1], [1, 0])
  const scale = useTransform(scrollYProgress, [0.75, 1], [1, 0.85])

  const y = useTransform(scrollYProgress, [0, 1], [start, end])
  const transform = useMotionTemplate`translateY(${y}px) scale(${scale})`

  // return <motion.img src={src} alt={alt} className={className} ref={ref} style={{ transform, opacity }} />
  return (
    <motion.div
      className={`relative team-member-img-on rounded-xl cursor-pointer ${className}`}
      ref={ref}
      style={{ transform, opacity }}
    >
      <figure>
        <motion.img src={src} alt={alt} className='w-full h-auto rounded-xl' />
      </figure>
      <div className='absolute bottom-0 left-0 right-0 lg:p-4 md:p-2 text-white bg-black bg-opacity-50'>
        <h2 className='text-14 font-bold font-archivo'>{name}</h2>
        <p className='text-[12px] font-archivo'>{content}</p>
      </div>
    </motion.div>
  )
}
