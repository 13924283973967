import React, { useEffect, useRef } from 'react'

import gsap from 'gsap-trial'
import ScrollTrigger from 'gsap-trial/dist/ScrollTrigger'

import cardData from 'src/constants/cards'
import Card from 'src/components/Card'
import { ReactComponent as Vector } from 'src/assets/icon/Vector.svg'
import { ReactComponent as StarIcon } from 'src/assets/icon/star.svg'

gsap.registerPlugin(ScrollTrigger)
const ContentCard: React.FC = () => {
  const videoContainerRef = useRef<HTMLDivElement>(null)
  const videoRef = useRef<HTMLVideoElement>(null)

  const adjustVideoHeight = () => {
    if (videoContainerRef.current && videoRef.current) {
      videoRef.current.style.height = `${videoContainerRef.current.clientHeight}px`
    }
  }
  useEffect(() => {
    // Adjust height on component mount and window resize
    adjustVideoHeight()
    window.addEventListener('resize', adjustVideoHeight)

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', adjustVideoHeight)
    }
  }, [])

  useEffect(() => {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: '.content-card',
          start: 'top 30%',
          end: 'top bottom',
          scrub: 2
        }
      })
      .fromTo(
        '.content-card-title',
        {
          y: -200,
          opacity: 0,
          duration: 0.25,
          ease: 'power4.in'
        },
        {
          opacity: 1,
          duration: 1,
          y: 0,
          ease: 'bounce.out'
        }
      )
    gsap
      .timeline({
        scrollTrigger: {
          trigger: '.content-card',
          start: 'top 30%',
          end: 'top bottom',
          scrub: 2
        }
      })
      .fromTo(
        '.content-card-description',
        {
          y: -200,
          opacity: 0,
          duration: 0.25,
          ease: 'power4.in'
        },
        {
          opacity: 1,
          delay: 0.5,
          duration: 1,
          y: 0,
          ease: 'bounce.out'
        }
      )
    gsap
      .timeline({
        scrollTrigger: {
          trigger: '.content-card',
          start: 'top 30%',
          end: 'top bottom',
          scrub: true
        }
      })
      .fromTo(
        '#contentcard',
        {
          opacity: 0,
          scale: 0.01,
          x: 0,
          y: 0
        },
        { opacity: 1, scale: 1, ease: 'elastic.out', x: 0, y: 0, delay: 0.5, duration: 1 }
      )
    gsap
      .timeline({
        scrollTrigger: {
          trigger: '.content-card',
          start: 'top 30%',
          end: 'top bottom',
          scrub: 2
        }
      })
      .fromTo(
        '.content-card-animation',
        {
          y: -200,
          opacity: 0,
          duration: 0.25,
          ease: 'power4.in'
        },
        {
          opacity: 1,
          delay: 7,
          duration: 1,
          y: 0,
          ease: 'bounce.out'
        }
      )
  }, [])

  return (
    <div className='pt-40 content-card'>
      <div className='flex p-50 py-20 gap-4 items-start lg:items-end justify-between max-md:flex-col'>
        <p className='content-card-title font-normal text-50 text-black dark:text-white w-full md:w-3/5 leading-tight'>
          How do we came with favors?
        </p>
        <div className='flex flex-col w-full md:w-1/3 gap-4 items-start'>
          <div className='object6'>
            <div className='content-card-animation relative flex justify-center items-center '>
              <Vector />
              <StarIcon className='boaming-image absolute dark:bg-white bg-green blur-[3rem] lg:blur-[3rem] ' />
            </div>
          </div>
          <p className='content-card-description font-archivo text-14 font-normal text-black dark:text-white'>
            We are nimble, adaptable, and able to work closely with our clients to deliver truly awesome development.
          </p>
        </div>
      </div>
      <div
        id='contentcard'
        className='mt-20 grid grid-cols-1 contenttablet:grid-cols-3 items-start content-start flex-wrap relative z-10 transition-all ease-in-out duration-700'
      >
        <div
          className=' animatecss-zoomIn video-background-container absolute left-0 top-0 bottom-0 right-0 dark:opacity-20 opacity-40'
          ref={videoContainerRef}
        >
          <video ref={videoRef} className='video-background h-[80vh] w-full object-cover ' autoPlay loop muted>
            <source src='/assets/mp4/timeline.mp4' type='video/mp4' />
          </video>
        </div>
        {cardData.cards.map((card, index) => (
          <div
            key={index}
            className='h-full opacity-90 dark:opacity-30 dark:hover:opacity-90 transition-all ease-in-out duration-700'
          >
            <Card title={card.title} description={card.description} icon={card.icon} darkIcon={card.darkIcon} />
          </div>
        ))}
      </div>
    </div>
  )
}
export default ContentCard
