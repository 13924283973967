import React, { useState, useEffect } from 'react'
import Tilt from 'react-parallax-tilt'

import { thirdPart } from 'src/types'

interface GochaProps {
  thirdPart: thirdPart
}

const Gocha: React.FC<GochaProps> = ({ thirdPart }) => {
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth)
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <div className='w-full flex flex-col py-50 gap-50 border-b border-silverMedal dark:border-customGray'>
      <div className=' flex flex-row gap-12 w-full max-md:flex-col max-md:items-center'>
        <h3 className='flex w-40p text-black font-archivo font-normal text-18 dark:text-white max-md:w-full '>
          {thirdPart.thirdPartTitle}
        </h3>
        <div className=' flex flex-col gap-10 w-60p max-md:w-full'>
          {thirdPart.thirdPartContent.map((item, index) => (
            <p key={index} className=' text-black font-archivo text-14  dark:text-white'>
              {item}
            </p>
          ))}
        </div>
      </div>
      {windowWidth > 1024 ? (
        <div className='grid grid-rows-2 grid-flow-col gap-6 items-center'>
          {thirdPart.thirdPartImage.map((item, index) =>
            index == 0 ? (
              <img
                src={item}
                key={`item${index}`}
                className='row-span-2 h-5/6 object-container  group-hover:opacity-75  border border-whiteGrey rounded-3xl transtion ease-in-out duration-300  hover:opacity-80 cursor-pointer'
              />
            ) : (
              <img
                src={item}
                key={`item${index}`}
                className='col-span-1 w-full object-container  group-hover:opacity-75  border border-whiteGrey rounded-3xl transtion ease-in-out duration-300  hover:opacity-80 cursor-pointer'
              />
            )
          )}
        </div>
      ) : (
        <div className='fles flex-col w-full'>
          {thirdPart.thirdPartImage.map((item, index) => (
            <img
              src={item}
              key={`item${index}`}
              className='w-full mb-24 group-hover:opacity-85 object-container  border border-whiteGrey rounded-3xl transtion ease-in-out duration-300 hover:scale-100  cursor-pointer'
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default Gocha
