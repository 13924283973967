import { FC, useState } from 'react'
import gsap from 'gsap-trial'
import useIsomorphicLayoutEffect from 'src/animation/useIsomorphicLayoutEffect'

const ScrollDown: FC = () => {
  const [isHovered, setIsHovered] = useState(false)

  const handleScrollDown = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth'
    })
  }

  useIsomorphicLayoutEffect(() => {
    gsap.fromTo(
      '.scroll-down',
      {
        y: -200,
        opacity: 0,
        duration: 0.25,
        ease: 'power4.in'
      },
      {
        opacity: 1,
        delay: 1.5,
        duration: 1,
        y: 0,
        ease: 'bounce.out'
      }
    )
  }, [])

  return (
    <div className='scroll-down cursor-pointer flex justify-center mt-6 items-center gap-4 md:justify-end'>
      <p
        className='text-white font-medium text-14 uppercase whitespace-nowrap hidden md:block cursor-pointer hover:text-prime hover:dark:text-prime font-archivo'
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={handleScrollDown}
      >
        Scroll Down
      </p>
      <div
        className='flex p-3 rounded-full border border-white'
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={handleScrollDown}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='20'
          height='20'
          viewBox='0 0 20 20'
          fill='none'
          className={`${isHovered ? 'animatecss animatecss-bounce animatecss-infinite animate-down' : ''}`}
        >
          <path d='M10 3.125L10 16.875' stroke='white' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
          <path
            d='M15.625 11.25L10 16.875L4.375 11.25'
            stroke='white'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      </div>
    </div>
  )
}

export default ScrollDown
