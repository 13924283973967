import React from 'react'

interface DescriptionProps {
  descriptions: string[]
}

const Description: React.FC<DescriptionProps> = ({ descriptions }) => {
  return (
    <div className='flex gap-30 pb-50 border-b border-silverMedal dark:border-jet mb-50 max-sm:flex-col'>
      <h2 className='w-40 font-archivo text-blackhole font-normal text-18 dark:text-white'>Descriptions</h2>
      <div className='w-60 flex flex-col max-sm:w-full gap-50 '>
        {descriptions.map((item, index) => (
          <div
            key={index}
            className='font-archivo text-14 text-blackhole dark:text-white animatecss animatecss-fadeInRight animatecss-slow animatecss-delay-1s'
          >
            {item}
          </div>
        ))}
      </div>
    </div>
  )
}

export default Description
