import { ProjectDetailType } from 'src/types'
import { FaAws, FaFigma, FaReact, FaEthereum, FaRust } from 'react-icons/fa6'
import { FaUnity, FaPython, FaNodeJs, FaLaravel, FaDocker, FaAngular, FaPhp, FaAndroid } from 'react-icons/fa'
import {
  SiMysql,
  SiElectron,
  SiTypescript,
  SiReduxsaga,
  SiTensorflow,
  SiNumpy,
  SiStorybook,
  SiCypress,
  SiRedux,
  SiFlutter,
  SiIos,
  SiGooglecloud,
  SiCodecov,
  SiFirebase,
  SiPostgresql,
  SiBinance,
  SiBlockchaindotcom,
  SiNestjs,
  SiRipple
} from 'react-icons/si'
import { TbBrandReactNative } from 'react-icons/tb'
import { DiRedis, DiMongodb } from 'react-icons/di'

const Project_1 = '/assets/detail/casino/Untitled-design-29.png'
const Project_2 = '/assets/detail/casino/multiplatform-compatibility.png'
const Project_3 = '/assets/detail/casino/Untitled-design-2024-06-04T161423.226.png'
const Project_4 = '/assets/detail/casino/Untitled-design-2024-06-04T161444.791.png'
const Project_5 = '/assets/detail/casino/Untitled-design-2024-06-04T161511.399.png'
const Project_6 = '/assets/detail/casino/Subheading.png'
const Project_7 = '/assets/detail/casino/Casino-Game.jpg'
const Project_8 = '/assets/detail/casino/Untitled-design-2024-06-04T170016.841.png'
const Project_9 = '/assets/detail/workManagement/Snimok_ekrana_2023_08_02_155141_kopiya_ae38f41b70.jpg'
const Project_10 = '/assets/detail/workManagement/Smart_Suite_My_Work_30889bacce.jpg'
const Project_11 = '/assets/detail/workManagement/Smart_Suite_Automation_b9e06d3c7e.jpg'
const Project_12 = '/assets/detail/workManagement/Smart_Suite_Notifications_0451f66bf8.jpg'
const Project_13 = '/assets/detail/workManagement/Smart_Suite_Permissions_8aaa02b1ac.jpg'
const Project_14 = '/assets/detail/cryptoWallet/use-cases-of-private-blockchain-in-healthcare.jpg'
const Project_15 = '/assets/detail/cryptoWallet/ICO.jpg'
const Project_16 = '/assets/detail/cryptoWallet/image-1643-e1724222813163.png'
const Project_17 = '/assets/detail/cryptoWallet/Binance-P2P-clone-script.jpg'
const Project_18 = '/assets/detail/cryptoWallet/P2p-e1724222697792.jpg'
const Project_19 = '/assets/detail/cryptoWallet/How-To-Implement-Blockchain-e1682526392504.jpg'
const Project_20 = '/assets/detail/cryptoWallet/Staking-e1724222729133.jpg'
const Project_21 = '/assets/detail/cryptoWallet/Support-e1724222738589.jpg'
const Project_22 = '/assets/detail/Diamond/slide_22-m5FU0QLt35I-21F6.png'
const Project_23 = '/assets/detail/Diamond/SS0UWu106qkpMDu0.png'
const Project_24 = '/assets/detail/Diamond/slide_22-9gGfK1JDYOc6TVaC.png'
const Project_25 = '/assets/detail/Diamond/slide_22-6JDz17DWSUP2qygQ.png'
const Project_26 = '/assets/detail/Diamond/slide_22-FHEfbIu6f36lf6ut.png'
const Project_27 = '/assets/detail/Diamond/slide_22-FbXc-50oGgXG3JcB.png'
const Project_28 = '/assets/detail/Diamond/slide_22-GGf1SDKB5aFpaQhv.png'
const Project_29 = '/assets/detail/Diamond/slide_22-PMIZhpxf5qIgltvr.png'
const Project_30 = '/assets/detail/nebeus/Nebeus.png.jpg'
const Project_31 = '/assets/detail/nebeus/image.jpg'
const Project_32 = '/assets/detail/nebeus/image4.jpg'
const Project_33 = '/assets/detail/nebeus/Things-That-Businesses-Should-Know-Before-Trading-In-Crypto.jpg.jpg'
const Project_34 = '/assets/detail/pixpel/Pixpel.png.jpg'
const Project_35 = '/assets/detail/pixpel/How-Much-Does-It-Cost-To-Build-A-Blockchain-e1682526403876.jpg.jpg'
const Project_36 = '/assets/detail/pixpel/How-To-Launch-A-Digital-Marketing-Career-For-New-Graduates.jpg.jpg'
const Project_37 = '/assets/detail/pixpel/AI-in-Web3-e1682526414853.jpeg.jpg'
const Project_38 = '/assets/detail/workplace/Enabling-enterprise-workplace_cover-1300px.jpg'
const Project_39 = '/assets/detail/workplace/home-screen-digital-workplace.jpg'
const Project_40 = '/assets/detail/workplace/space-booking.jpg'
const Project_41 = '/assets/detail/cryptoMove/crypto-image.jpg'
const Project_42 = '/assets/detail/cryptoMove/dddd.png'
const Project_43 = '/assets/detail/cryptoMove/img1-600x400.jpg'
const Project_44 = '/assets/detail/cryptoMove/img2-400x263.jpg'
const Project_45 = '/assets/detail/cryptoMove/img3-600x450.jpg'
const Project_46 = '/assets/detail/zytrius/66a0adeea830396f57f2b770_Zytrius Img hero.jpg'
const Project_47 = '/assets/detail/zytrius/66a77df674883376bbbc2939_Zytrius-img.jpg'
const Project_48 = '/assets/detail/zytrius/669fd2e0b180c300f70f86ba_Screen 1.jpg'
const Project_49 = '/assets/detail/zytrius/669fd2e0fb645acb2ef6b81d_Screen 2.jpg'
const Project_50 = '/assets/detail/zytrius/669fd2e05ba69c972168bdb7_Screen 4.jpg'
const Project_51 = '/assets/detail/zytrius/669fd2e10eb4bf76ec8f6285_Screen 5.jpg'
const Project_52 = '/assets/detail/winzone/669e2e6781ef904f7c707698_Img hero WINZONE-p-2000.png'
const Project_53 = '/assets/detail/winzone/669e3434fe58a02d64d72f26_Screen04-p-1080.png'
const Project_54 = '/assets/detail/winzone/669e3434e547a118b6f40ff4_Screen02-p-1080.jpg'
const Project_55 = '/assets/detail/winzone/669e3434ea1251a2c63dcc08_Screen01-p-1080.jpg'
const Project_56 = '/assets/detail/winzone/669e3434f7b826876216ebdc_Screen03-p-1080.jpg'
const Project_57 = '/assets/detail/health/Blockchain-powered-framework-for-Electronic-Medical-Records-BG-1024x496.jpg'
const Project_58 = '/assets/detail/health/Blockchain-powered-framework-for-Electronic-Medical-Records-Overview.jpg'
const Project_60 = '/assets/detail/health/Blockchain-powered-framework-for-Electronic-Medical-Records-About1.png'
const Project_61 = '/assets/detail/health/Blockchain-powered-framework-for-Electronic-Medical-Records-About2.png'
const Project_62 = '/assets/detail/health/Blockchain-powered-framework-for-Electronic-Medical-Records-About3.png'
const Project_59 = '/assets/detail/health/Blockchain-powered-framework-for-Electronic-Medical-Records-Challenge.jpg'
const Project_63 = '/assets/detail/dlcc/header-800x412.png'
const Project_64 = '/assets/detail/dlcc/Business-Overview-600x497.png'
const Project_65 = '/assets/detail/dlcc/The-Challenges-600x500.png'
const Project_66 = '/assets/detail/dlcc/outcome-600x352.png'
const Project_67 = '/assets/detail/dlcc/portfolio-6.png'
const Project_68 = '/assets/detail/dlcc/slider-3.png'
const Project_69 = '/assets/detail/liquior/660289facf9d83a421c5e571_paladin-case-study.png'
const Project_70 = '/assets/detail/liquior/6690b24f6e48ea43b0d2ab4b_paladin-mockup.png'
const Project_71 = '/assets/detail/liquior/668686e28e364aee2e49ebfd_taxcanary-challenges.png'
const Project_72 = '/assets/detail/liquior/6690b246d193e5de57ada2a9_paladin-final-result.png'
const Project_73 = '/assets/detail/liquior/6690b25dadbf232f6e607533_paladin-process.png'
const Project_74 = '/assets/detail/servisus/img-SERVISUS.png'
const Project_75 = '/assets/detail/servisus/mix1-1024x819.png'
const Project_76 = '/assets/detail/servisus/mix3-1024x819.png'
const Project_77 = '/assets/detail/quote/Quotes-UPWORK-e1726136510219.png'
const Project_78 = '/assets/detail/quote/Quotes-mix1-1-1024x819.jpg'
const Project_79 = '/assets/detail/quote/Quotes-mix1-1024x819.jpg'
const Project_80 = '/assets/detail/appManagement/1-main-img.png'
const Project_81 = '/assets/detail/appManagement/1-more-img.png'
const Project_82 = '/assets/detail/appManagement/adm-1.jpg'
const Project_83 = '/assets/detail/appManagement/adm-4.jpg'
const Project_84 = '/assets/detail/appManagement/adm-5.jpg'
const Project_85 = '/assets/detail/appManagement/1-about-us.png'
const Project_86 = '/assets/detail/appManagement/adm-6.jpg'
const Project_87 = '/assets/detail/appManagement/adm-8.jpg'
const Project_88 = '/assets/detail/visitor/newdevrev-collage.png.jpg'
const Project_89 = '/assets/detail/visitor/newdevrev-stacked.png.jpg'
const Project_90 = '/assets/detail/visitor/newdevrev-trio.png.jpg'
const Project_91 = '/assets/detail/telehealth/Frame-32990.jpg'
const Project_92 = '/assets/detail/telehealth/Group-33507.jpg'
const Project_93 = '/assets/detail/telehealth/Ресурс-10@3x-1-e1678964286375.jpg'
const Project_94 = '/assets/detail/telehealth/Client.jpg'
const Project_95 = '/assets/detail/afffirmations/iphone-2d5125e1224060ed7db5ab69b90f6732.png'
const Project_96 = '/assets/detail/afffirmations/threePhonesChallengeSection-f4cee55c6e6d109f61ec63fc2af032c4.png'
const Project_97 = '/assets/detail/afffirmations/threePhonesSolutionSection-8505c5c41996ef18659fc7c7a01295f5.png'
const Project_98 = '/assets/detail/afffirmations/twoIphones-caccc51636cc4f5c8a27d15696edc17b.png'
const Project_99 = '/assets/detail/verifiID/verifi_Id_CS_Banner_a0b3e3bedf.png'
const Project_100 = '/assets/detail/verifiID/verifi_Idgrid1_1f88d4d2d9.png'
const Project_101 = '/assets/detail/verifiID/verifi_Idgrid2_055da75867.png'
const Project_102 = '/assets/detail/revenue/case-edmond-hero.jpg'
const Project_103 = '/assets/detail/revenue/case-edmond-4.png'
const Project_104 = '/assets/detail/revenue/case-edmond-8.png'
const Project_105 = '/assets/detail/revenue/case-edmond-9.png'
const Project_106 = '/assets/detail/revenue/edmond-lottie-new.jpg'
const Project_107 = '/assets/detail/revenue/img-occupancypredictions.jpg'

const ProjectDetail: ProjectDetailType = {
  detailData: [
    {
      id: 0,
      projectIntro: {
        projectTitle: 'Casino Game',
        title: 'Casino Game Development',
        date: 'Sep-Dec 2022',
        introduction:
          'Uncover the success story behind a casino game’s journey from concept to market dominance, showcasing the strategic blend of captivating gameplay, stunning graphics, and lucrative monetization strategies.',
        projectImageSrc: Project_1
      },
      descriptions: [
        'Revolutionize your gaming experience with our cutting-edge casino game develpment services.',
        'Our team crafts immersive, high-performance games designed for seamless navigation and lightning-fast gameplay. With our user-friendly interfaces and optimized performance, players can dive into a world of excitement and thrill with just a few taps.',
        'our casino games guarantee an unmatched experience that will keep you coming back for more. Elevate your gaming journey with us today!'
      ],
      services: ['design', 'develpment', 'deployment'],
      techstacks: {
        lang: ['1100 PROJECT HOURS', 'REACT.JS', 'ELECTRON', 'DATABASES', 'Unity', 'FIGMA'],
        icons: [FaReact, FaReact, FaEthereum, SiMysql, FaUnity, FaFigma],
        description:
          'Despite being a small team, we believe that our size gives us an advantage, allowing us to be nimble, adaptable, and able to work closely with our clients to deliver truly awesome development.'
      },
      firstPart: {
        firstPartTitle: 'Challenges',
        firstPartContent: [
          'Navigating regulatory frameworks for online gambling presents a formidable challenge, requiring meticulous adherence to licensing requirements, age verification protocols, and responsible gaming features to ensure compliance while maintaining player trust and legal integrity.',
          'Ensuring game fairness and security demands rigorous implementation of random number generators (RNGs), robust encryption measures, and regular audits. Upholding player trust and data protection while preventing cheating and unauthorized access are paramount priorities in ',
          'Creating immersive casino game experiences with high-quality graphics and animations is crucial. However, optimizing performance across different devices presents a challenge. Developers must employ efficient rendering techniques and scalable graphics to ensure smooth gameplay without sacrificing visual quality.'
        ],
        firstPartImage: [Project_2]
      },
      secondPart: {
        secondPartTitle: 'Solutions',
        secondPartContent: [
          'Implement advanced RNGs and encryption measures, conducting regular audits to uphold fairness and safeguard player data, fostering trust and confidence in the game’s integrity.',
          'Employ efficient rendering techniques and scalable graphics to optimize performance across devices while maintaining high visual quality, enhancing the immersive gaming experience for players.',
          'Prioritize user-centric design principles, conducting extensive usability testing, and refining UI/UX iteratively to create an intuitive and engaging player experience, enhancing satisfaction and retention.'
        ],
        secondPartImage: [Project_3, Project_4, Project_5]
      },
      thirdPart: {
        thirdPartTitle: 'Gochas',
        thirdPartContent: [
          'The outcome: A 62% decrease in the Game bounce rate has been achieved.',
          'Recognizing the critical significance of speed and simplicity in gaming experiences, our UI design approach mirrored these principles. Through thoughtful selection of visual components and intuitive game mechanics, we streamlined the user experience to ensure seamless and immersive gameplay. Furthermore, we introduced an array of engaging features within the game, such as customizable avatars, interactive tutorials, multiplayer modes, and in-game rewards—enhancements aimed at delivering an enjoyable and rewarding gaming experience for all players.'
        ],
        thirdPartImage: [Project_6, Project_8, Project_7]
      }
    },
    {
      id: 1,
      projectIntro: {
        projectTitle: 'SmartSuite',
        title: 'Work Management Platform',
        date: 'Fri-May 2022',
        introduction:
          'SmartSuite is a web and mobile no-code platform that serves as a tool for managing everything needed to get work done: spreadsheets, documents, collaboration tools, databases, file management products and automation capabilities — all in one place.',
        projectImageSrc: Project_9
      },
      descriptions: [
        'SmartSuite work management system is transforming how organizations get work done by providing a collaborative Work Management platform that enables teams to plan, track and manage workflows - whether it be a project, an ongoing process or routine everyday tasks.'
      ],
      services: ['research', 'ux/ui design', 'development'],
      techstacks: {
        lang: ['700 PROJECT HOURS', 'REACT.JS', 'Python', 'DATABASES', 'AWS DATABASE', 'Cypress'],
        icons: [FaReact, FaReact, FaPython, SiMysql, FaAws, SiCypress],
        description:
          'Despite being a small team, we believe that our size gives us an advantage, allowing us to be nimble, adaptable, and able to work closely with our clients to deliver truly awesome development.'
      },
      firstPart: {
        firstPartTitle: 'Features',
        firstPartContent: [
          'Put your data to work with powerful reports, dashboards, charts and views. Interact with those records, tracking your progress, interactions with customers and more.',
          "Plan, manage and assign tasks to make sure that you're on time, every time. Tasks are integrated into every part of SmartSuite, allowing you to assign work items, specify due dates and ensure that everyone is accountable."
        ],
        firstPartImage: [Project_10]
      },
      secondPart: {
        secondPartTitle: 'Solutions',
        secondPartContent: [
          'Bring important details to your attention through online notifications, email or Slack. Users can configure how they are notified, and notifications can be configured as SmartSuite automations actions to bring attention to important events.',
          'Over 200 industry best-practices solution templates get you up and running fast. These pre-configured sets of SmartSuite applications allow you to create the structure to track all of your work and processes in minutes. From there you can tweak and adjust any component of those templates to make it a perfect fit for your organization.'
        ],
        secondPartImage: [Project_11, Project_12, Project_13]
      },
      thirdPart: {
        thirdPartTitle: 'Results',
        thirdPartContent: [
          'After the successful release of the product, our team continues to work on the project, provide support and work with feedback to improve the functionality of SmartSuite.',
          'Watch the video where John Darbyshire, CEO of SmartSuite, talks about how we met and how they work on the product was arranged. John also shares details on how SmartSuite has grown since its launch to reach 1.2M ARR in 4 weeks.'
        ],
        thirdPartImage: []
      }
    },
    {
      id: 2,
      projectIntro: {
        projectTitle: 'White Label Crypto Wallet',
        title: 'White Label Crypto Wallet Solution for Your Business Grow',
        date: 'Aug-Sep 2021',
        introduction:
          'White label crypto wallets are turnkey solutions that enable ventures to launch their branded wallets without significant time and resource investment in a lengthy creation process, significantly enhancing your branding strategy.',
        projectImageSrc: Project_14
      },
      descriptions: [
        'Accelerate your market entry by offering your customers a secure and seamless cryptocurrency experience.',
        'White label crypto wallets are turnkey solutions that enable ventures to launch their branded wallets without significant time and resource investment in a lengthy creation process, significantly enhancing your branding strategy. Whether you are a startup or a large enterprise, our service can provide you with a strong competitive edge.'
      ],
      services: ['development', 'research', 'ux/ui design'],
      techstacks: {
        lang: [
          '500 PROJECT HOURS',
          'REACT.JS',
          'Redux',
          'PostgreSQL',
          'Ethereum Virtual Machine',
          'Binance Smart Chain'
        ],
        icons: [FaReact, FaReact, SiRedux, SiPostgresql, FaEthereum, SiBinance],
        description:
          'Despite being a small team, we believe that our size gives us an advantage, allowing us to be nimble, adaptable, and able to work closely with our clients to deliver truly awesome development.'
      },
      firstPart: {
        firstPartTitle: 'Process Overview',
        firstPartContent: [
          'This level of control is crucial for maintaining the integrity and security of the holder and inspiring trust from users. A white label cryptocurrency wallet provides comprehensive management features to facilitate the easiest ways to work with digital currency.',
          'It offers complete management of transactions and activities within the business, helping to comply with regulatory standards.'
        ],
        firstPartImage: [Project_15]
      },
      secondPart: {
        secondPartTitle: 'Benefits',
        secondPartContent: [
          'Consumers can search and filter their transaction records to find what they are looking for. This feature is very important and useful for those who intend to control their money and organize everything before the tax period.',
          'Multiple network support covers leading blockchain protocols such as Bitcoin, Ethereum, and Binance Smart Chain. ',
          'When you enable the swap service, you greatly increase the efficiency of your wallet by providing users with a nearly hassle-free path to trade.',
          'Our wallet can support both cold and hot storage to improve asset management.',
          "In our wallet, staking is a feature that allows users to earn rewards by actively participating in the network's proof-of-stake mechanism. "
        ],
        secondPartImage: [Project_16, Project_17, Project_18]
      },
      thirdPart: {
        thirdPartTitle: 'Reasons',
        thirdPartContent: [
          'We have an open pricing model with no hidden fees so that you can plan your budget wisely. ',
          "Our white label crypto wallet solutions have proven reliable and resourceful, and that's why our leading customers consider us trustworthy.",
          ' We offer functionality for integrated APIs, so you can be sure that your wallet will work seamlessly with your application.'
        ],
        thirdPartImage: [Project_19, Project_20, Project_21]
      }
    },
    {
      id: 3,
      projectIntro: {
        projectTitle: 'Gs Diamonds',
        title: 'Ecommerce project for Australia and China jewelry market',
        date: 'May-Sep 2020',
        introduction:
          'Gs Diamonds is an Australian owned family business. The company was founded by a married couple with 30 years of experience working with jewelry. They decided to make an online store with additional features for customers.',
        projectImageSrc: Project_22
      },
      descriptions: [
        'Gs Diamonds is an Australian owned family business. The company was founded by a married couple with 30 years of experience working with jewelry.',
        'They decided to make an online store with additional features for customers.'
      ],
      services: ['ux/ui design', 'development', 'research', 'workshop'],
      techstacks: {
        lang: ['800 PROJECT HOURS', 'REACT.JS', 'Node JS', 'MySQL', 'Laravel', 'FIGMA'],
        icons: [FaReact, FaReact, FaNodeJs, SiMysql, FaLaravel, FaFigma],
        description:
          'Despite being a small team, we believe that our size gives us an advantage, allowing us to be nimble, adaptable, and able to work closely with our clients to deliver truly awesome development.'
      },
      firstPart: {
        firstPartTitle: 'Process Overview',
        firstPartContent: [
          'This projects’ structure turned out to be unique in comparison with other sites in e-commerce.',
          'The most difficult task was to gather information on stones from all possible suppliers. The nuance is that each supplier has its approach to structuring and uploading/sharing information.',
          'The specialization of the platform with its peculiarities required a special approach to UI/UX. Besides, it was necessary to develop special ring designs customization interface that could allow customers to create a unique product according to the individual parameters and taste.'
        ],
        firstPartImage: [Project_23]
      },
      secondPart: {
        secondPartTitle: 'Solution',
        secondPartContent: [
          'We worked with React Js, Node Js, and Laravel on this project. As the base for the administrative panel, Laravel Nova was used, making the development of CRM easier.',
          'A lot of effort has been invested in UI/UX, optimization, and download speed since organic search is a priority for the site.'
        ],
        secondPartImage: [Project_24, Project_25, Project_26]
      },
      thirdPart: {
        thirdPartTitle: 'Results',
        thirdPartContent: [
          'According to statistics, online store sales are rapidly reaching the offline sales level. Clients have access to all services provided by experienced jewelers, appraisers, certified gemologists, as well as the best jewelry designers.',
          'The next stage of the project is to develop a new feature for earrings and bracelets customization.'
        ],
        thirdPartImage: [Project_27, Project_28, Project_29]
      }
    },
    {
      id: 4,
      projectIntro: {
        projectTitle: 'Nebeus',
        title: 'Nebeus',
        date: 'June-Sep 2019',
        introduction:
          "Nebeus is the first financial services platform that uses blockchain technology to ensure the complete security and transparency of users' assets. It allows you to monitor your bitcoin accounts, pay, trade, and request loans easily.",
        projectImageSrc: Project_30
      },
      descriptions: [
        'Nebeus is a platform to keep track of your bitcoin accounts where you can pay, trade, request and offer bitcoin loans with ease.',
        'The team managed to meet all our requirements and deliver a powerful, fast, and reliable mobile application for both Android and iOS.'
      ],
      services: ['research', 'Business Analyst', 'ux/ui design', 'development', 'QA Automation'],
      techstacks: {
        lang: ['450 PROJECT HOURS', 'REACT NATIVE', 'REDUX', 'BLOCKCHAIN SOLUTION', 'NODE JS'],
        icons: [FaReact, TbBrandReactNative, SiRedux, SiBlockchaindotcom, FaNodeJs],
        description:
          'Despite being a small team, we believe that our size gives us an advantage, allowing us to be nimble, adaptable, and able to work closely with our clients to deliver truly awesome development.'
      },
      firstPart: {
        firstPartTitle: 'Challenge',
        firstPartContent: [
          'A major challenge was to develop a cross-platform mobile application compatible with both Android and iOS. Also, it was important to create a scalable solution capable of withstanding high loads while ensuring optimal stability on multiprocessor systems.',
          'Overall, the goal was to develop a user-friendly application that would allow users to track and monitor their FIAT assets, make payments, invest in cryptocurrency, and store assets (HODLing). Major challenges were to create a fast, responsive application that worked seamlessly across multiple platforms, and to build a reliable, scalable solution that could withstand significant user demand.'
        ],
        firstPartImage: [Project_30]
      },
      secondPart: {
        secondPartTitle: 'Solution',
        secondPartContent: [
          'We successfully developed a cross-platform mobile app for Android and iOS with scalability and high performance.',
          'Our optimized architecture, led by a Senior Architect, ensures fast processing and efficient handling of heavy loads. Utilizing ReactNative and Redux, we created a user-friendly app that facilitates tracking, managing FIAT, making payments, and investing in crypto.'
        ],
        secondPartImage: [Project_31, Project_32, Project_33]
      },
      thirdPart: {
        thirdPartTitle: 'Gochas',
        thirdPartContent: [
          'The application features a scalable architecture built to handle heavy loads with exceptional speed and efficiency. ',
          'Developed using React Native and Redux, it underwent four stages of optimization and rigorous pentesting, resulting in a robust, secure, and high-performance solution.'
        ],
        thirdPartImage: []
      }
    },
    {
      id: 5,
      projectIntro: {
        projectTitle: 'Pixpel',
        title: 'Pixpel',
        date: 'Fri-Aug 2019',
        introduction:
          'Pixpel is a full-fledged P2E ecosystem where game developers can independently create game tokens and NFTs, and players can buy and sell them within the integrated NFT Marketplace.',
        projectImageSrc: Project_34
      },
      descriptions: [
        'Pixpel is an innovative project, aimed at creating a dynamic platform for investment in game development.',
        ' It enables users to invest and profit from gaming projects using advanced vesting and staking mechanisms, with support for fiat transactions and seamless token conversion across multiple blockchain networks.'
      ],
      services: ['research', 'workshop', 'ux/ui design', 'development'],
      techstacks: {
        lang: ['1100 PROJECT HOURS', 'POSTGRE SQL', 'REACT.JS', 'REDIS', 'BLOCKCHAIN', 'RUST'],
        icons: [FaReact, SiPostgresql, FaReact, DiRedis, SiBlockchaindotcom, FaRust],
        description:
          'Despite being a small team, we believe that our size gives us an advantage, allowing us to be nimble, adaptable, and able to work closely with our clients to deliver truly awesome development.'
      },
      firstPart: {
        firstPartTitle: 'Challenge',
        firstPartContent: [
          'Creating a platform that enables investment in game development, combined with vesting and staking mechanisms, opens opportunities for sustainable, community-driven funding. Integrating tools for seamless fiat transactions, along with transparent token conversion across multiple blockchain networks, enhances accessibility and transparency for investors.',
          'Prioritizing safety and an enjoyable user experience ensures that both players and developers can fully engage with crypto games, fostering trust and long-term involvement.'
        ],
        firstPartImage: [Project_34]
      },
      secondPart: {
        secondPartTitle: 'Solution',
        secondPartContent: [
          'Our team successfully built a cutting-edge platform enabling secure investment in game development with robust vesting and staking options',
          ' By integrating smart contracts on the Concordium Blockchain and implementing tools for fiat transactions and cross-chain token conversion, we created a seamless experience for users.Through the integration of smart contracts and DevOps infrastructure, the team ensures efficient and secure operations, ultimately enhancing the user experience and fostering the growth of the crypto gaming ecosystem.'
        ],
        secondPartImage: [Project_35, Project_36, Project_37]
      },
      thirdPart: {
        thirdPartTitle: 'Detail Project',
        thirdPartContent: [
          'The project integrates Rust-based smart contracts on the Concordium Blockchain to enable key decentralized exchange (DEX) functions. A robust DevOps setup is established with GitLab CI/CD to streamline development and deployment, while a REST API allows smooth communication between services.',
          'User metadata structures are enhanced by utilizing SDK structures, with code refactoring and metadata hash integration for improved data integrity. Comprehensive functionality testing, along with regression tests, ensures the platform’s stability and reliability.'
        ],
        thirdPartImage: []
      }
    },
    {
      id: 6,
      projectIntro: {
        projectTitle: 'Workplace Transformation ',
        title: 'Enabling Workplace Transformation with Advanced Space Management and Booking',
        date: 'May-Nov 2022',
        introduction:
          'Our Fortune 500 client is a leading professional services provider that embraces around 400K employees working in 700 office locations worldwide.',
        projectImageSrc: Project_38
      },
      descriptions: [
        'Sustainable workplace utilization that would allow for efficient office space management and decrease maintenance expenses',
        'Employee experience transformation aimed at improving enterprise productivity, streamlining hybrid collaboration, and reducing the admin burden on office workers',
        'Developing an agile workplace management methodology that would become the company’s competitive advantage in the market '
      ],
      services: ['research', 'workshop', 'ux/ui design', 'development'],
      techstacks: {
        lang: ['1300 PROJECT HOURS', 'REACT.JS', 'DATABASES', 'FIGMA'],
        icons: [FaReact, FaReact, SiMysql, FaFigma],
        description:
          'Despite being a small team, we believe that our size gives us an advantage, allowing us to be nimble, adaptable, and able to work closely with our clients to deliver truly awesome development.'
      },
      firstPart: {
        firstPartTitle: 'Solution',
        firstPartContent: [
          'Implementing a holistic workplace modernization strategy that marks a new era of product evolution',
          'The project scope and the company’s ambitious business goals have brought us to the development of a state-of-the-art digital workplace concept that required a strategic omni-solution approach to enterprise transformation.'
        ],
        firstPartImage: []
      },
      secondPart: {
        secondPartTitle: 'Workpalce Mordernization',
        secondPartContent: [
          'Establishing a secure cloud-based digital workplace infrastructure for enhanced employee collaboration, Setting up integrations with a dedicated email client and other third-party services',
          'Introducing comprehensive information management assets, Flexible integrations with catering and concierge service'
        ],
        secondPartImage: [Project_39, Project_38, Project_40]
      },
      thirdPart: {
        thirdPartTitle: 'Productivity Improvement',
        thirdPartContent: [
          'Developing personalized working week plans for hybrid workers, Automating the booking of meeting rooms and individual workstations ',
          'Introducing smart availability notifications and updates, Optimizing interactions with a facility’s back office  '
        ],
        thirdPartImage: []
      }
    },
    {
      id: 7,
      projectIntro: {
        projectTitle: 'Crypto Moves ',
        title: 'Crypto Development',
        date: 'Fri-May 2020',
        introduction: 'we build Blockchain web and backend systems that bring NFTs & Cryptocurrencies to life.',
        projectImageSrc: Project_41
      },
      descriptions: [
        'We want to cater to both novice and professional traders and we build any type of cryptocurrency exchange that you can dream up, that’s why we’ve tailored user-friendly and flawlessly secure cryptocurrency exchange apps for both web and mobile use. ',
        'They are underpinned by multiple trading modules, along with swift and intuitive crypto wallets. In short, they do what you need them to do, and they won’t compromise security for performance.'
      ],
      services: ['research', 'ux/ui design', 'development'],
      techstacks: {
        lang: ['950 PROJECT HOURS', 'REACT.JS', 'DATABASES', 'Ethereum', 'Rust'],
        icons: [FaReact, FaReact, SiMysql, FaEthereum, FaRust],
        description:
          'Despite being a small team, we believe that our size gives us an advantage, allowing us to be nimble, adaptable, and able to work closely with our clients to deliver truly awesome development.'
      },
      firstPart: {
        firstPartTitle: 'Process overview',
        firstPartContent: [
          'Platforms for Bitcoin trading, Decentralized exchange platforms similar to Binance, Business logic based on blockchain and smart contracts',
          'Advanced customizability and scalability, Margin trading and short-selling services are available'
        ],
        firstPartImage: [Project_42]
      },
      secondPart: {
        secondPartTitle: 'Mordernization',
        secondPartContent: [
          'Wallets for mobile, cross-platform, online, desktop, and hardware, Management of multiple currencies and credit cards, Support for multiple signatures',
          'Transactions using NFC are supported, Interfacing with the Automated Clearing House (ACH) Social networking and messaging functionality, Consistency in user experience across all OS platforms and screens'
        ],
        secondPartImage: [Project_43, Project_44, Project_45]
      },
      thirdPart: {
        thirdPartTitle: 'Productivity Improvement',
        thirdPartContent: [
          'Wealth and portfolio management over the lifecycle, Arbitrage functionality built-in, Having access to a large liquidity pool ',
          'Automated order fulfillment, Trading techniques for cryptocurrencies that are unique, API/UI-based direct access'
        ],
        thirdPartImage: []
      }
    },
    {
      id: 8,
      projectIntro: {
        projectTitle: 'Zytrius',
        title: 'Zytrius',
        date: 'Apr-Oct 2022',
        introduction:
          'Zytrius is a fully integrated solution for all CGC Games’ gaming and entertainment needs: slots, dynamic sportsbook, promotions, and interactive auctions',
        projectImageSrc: Project_46
      },
      descriptions: [
        'Zytrius is an iGaming solution where users can play a wide variety of slots and bet on sports, take part in promotions and auctions. As a software development company and with the vision of offering something new to the market, Idealogic led the project.',
        'This particular case describes the issues Idealogic encountered during the game’s development, creative ideas we used at some point to cope with the issues, and amazing accomplishment reflected in the final Zytrius app. '
      ],
      services: ['research', 'ux/ui design', 'development', 'igaming'],
      techstacks: {
        lang: ['1150 PROJECT HOURS', 'REACT.JS', 'DATABASES', 'Ethereum', 'Rust'],
        icons: [FaReact, FaReact, SiMysql, FaEthereum, FaRust],
        description:
          'Despite being a small team, we believe that our size gives us an advantage, allowing us to be nimble, adaptable, and able to work closely with our clients to deliver truly awesome development.'
      },
      firstPart: {
        firstPartTitle: 'Process overview',
        firstPartContent: [
          'The purpose of integrating multiple aspects of the gaming world – slots, sportsbooks, bonuses and promotions, and auctions – into a single site and application without causing bad customer-experience. ',
          'Creating elements that will appeal to the user and enable them visit the site many times even if the market is highly saturated. ',
          'Verification that the platform is scalable to process real-time data – this is especially essential for the sport’s book and auction services.',
          'Making the transition between various aspects of the game easily executable as well as entertaining for the player. ',
          'The app required high-level security measures for the users’ information to be safe and gaming-activities to be non-fraudulent.'
        ],
        firstPartImage: [Project_47, Project_48]
      },
      secondPart: {
        secondPartTitle: 'Solution',
        secondPartContent: [
          'The idea was to create a platform that would be as engaging as possible to the intended users. For example, in order to attract users’ attention to the specific aspect of the game or a game, images with great contrast, interactive advertisements, and exciting style were used. ',
          'Special attention was paid to the real-time processing in order to maintain a smooth and fast response of the sportsbook and auctions. We made sure the data flow is efficient and the time dat takes to arrive to the user in realtime is minimal. ',
          'To connect between various gaming sections, Idealogic came up with an easy-to-use interface to enable users to switch from one section to another. Dedicated web application, use of interactive dashboards and customizable features make it possible for everyone from neophytes to professional gamers to feel comfortable.',
          "Idealogic used the series of layered security features such as data encryption and secure user authentication for customers’ information security. There was also fairness algorithms incorporated to guarantee fair and open activities in gaming thereby increasing the users' trust to the system. Since the gaming as an industry is very much regulated with many rules laid down, we have ensured that the company Zytrius is all legal. "
        ],
        secondPartImage: []
      },
      thirdPart: {
        thirdPartTitle: 'Gochas',
        thirdPartContent: [
          'With smart features to facilitate interaction with the system, boosting the player’s retention, Zytrius is unique in the gaming industry. ',
          'As for real time data processing Zytrius proved to be rather effective to provide smooth and fast execution of the sport book application and the auction part. ',
          'It has received a favorable appraisal from the users due to optimised, user-centric design, research done through user experience and design thinking principles.'
        ],
        thirdPartImage: [Project_49, Project_50, Project_51]
      }
    },
    {
      id: 9,
      projectIntro: {
        projectTitle: 'WINZONE',
        title: 'WINZONE',
        date: 'May-Oct 2021',
        introduction:
          'WINZONE is a sports betting mobile application that has been developed to support the next generation of sports betting.',
        projectImageSrc: Project_52
      },
      descriptions: [
        'The application known as WINZONE Sportsbook is a progressive product created for those users who could be interested in an advanced approach to betting.',
        'The vision was to develop an application that would allow users to build web pages in a simple way, this challenge was accepted by our software development team. In this case we describe the problems encountered and the solutions offered.'
      ],
      services: ['research', 'ux/ui design', 'development', 'igaming'],
      techstacks: {
        lang: ['1150 PROJECT HOURS', 'REACT.JS', 'NODE.JS', 'NEST.JS', 'ANGULAR', 'PHP'],
        icons: [FaReact, FaReact, FaNodeJs, SiNestjs, FaAngular, FaPhp],
        description:
          'Despite being a small team, we believe that our size gives us an advantage, allowing us to be nimble, adaptable, and able to work closely with our clients to deliver truly awesome development.'
      },
      firstPart: {
        firstPartTitle: 'Process overview',
        firstPartContent: [
          'Betters would need accurate and up-to-date information of events, particularly sporting events in the bet market. ',
          'We had to envisage the elements that would occupy customers’ attention, like the list of favorite leagues, the statistics of the matches in real time, and the recommendations on bets. ',
          'Most of the sportsbook apps need security measures to guard the information of the users and meet the regulatory requirements.',
          'Designing an application architecture which would be able to cope with number of users in peak times, including the increased attention to sports during International sports events.',
          'Designing neat and easy to understand interface to make the customer experience easy and smooth.'
        ],
        firstPartImage: [Project_53]
      },
      secondPart: {
        secondPartTitle: 'Solution',
        secondPartContent: [
          'To promote the secure handling of a large number of transactions, Idealogic introduced a secure backend system. To this end, we adopted effective encryption methods in order to prevent possible security breaches of user information and all transactions.',
          'Idealogic incorporated the ability to employ high-end algorithms aiming at enhancing the accuracy of real-time data that is processed inside WINZONE app. Interaction with the data suppliers was critical to ensure the accuracy of the app and to offer the users the most current events and betting options. ',
          'Just like any other software application, there were various phases of testing to ensure that the app has no problems which could manifest on production. Feedback from the users was also considered to improve the functionality of the app during the development phase.',
          "To ensure the app's users’ privacy and data security, Idealogic made provisions of layered security features within WINZONE app."
        ],
        secondPartImage: [Project_54, Project_55, Project_56]
      },
      thirdPart: {
        thirdPartTitle: 'Gochas',
        thirdPartContent: [
          'Idealogic successfully designed WINZONE for its client, and in some cases, the result was beyond expectations. The app provided smooth the user experience, high security measures were strong enough to protect user-information, thus, the users seemed to trust the app. Due to friendly graphical user interface and interesting features the users spend as much time on the application as was planned.',
          'It had good capabilities in handling the real-time data and offered a way by which betters could bet conveniently.'
        ],
        thirdPartImage: []
      }
    },
    {
      id: 10,
      projectIntro: {
        projectTitle: 'Blockchain for EMR ',
        title: 'Success story of Blockchain Healthcare',
        date: 'Mar-Oct 2020',
        introduction: 'Blockchain-powered framework for Electronic Medical Records (EMR)',
        projectImageSrc: Project_57
      },
      descriptions: [
        'Healthcare has got tremendous potential for improvement worldwide. According to some estimations, around $800+ Billion are spent on duplicating services due to poor communications between doctors and hospitals.',
        'Blockchain technology holds the potential to provide economical alternative to humongous costs that are involved in Electronic Health Record management and transparent medical billing.'
      ],
      services: ['research', 'ux/ui design', 'development'],
      techstacks: {
        lang: ['1200 PROJECT HOURS', 'REACT.JS', 'ETHEREUM', 'RIPPLE'],
        icons: [FaReact, FaReact, FaEthereum, SiRipple],
        description:
          'Despite being a small team, we believe that our size gives us an advantage, allowing us to be nimble, adaptable, and able to work closely with our clients to deliver truly awesome development.'
      },
      firstPart: {
        firstPartTitle: 'Challenge',
        firstPartContent: [
          'Electronic Health Records (EHRs) were designed to make record keeping more seamless and standardised but it has opened pandora’s box when it comes to managing multiple providers and maintaining patient records for life.',
          'Current system does not provide patients with kind of portability they enjoy in other aspects of life like communication, non medical insurance and business in general.',
          'Providers — not patients are enjoying greater control over EHRs and patients face major bottlenecks while viewing their reports, correcting erroneous data, and distributing the information.'
        ],
        firstPartImage: [Project_58, Project_59]
      },
      secondPart: {
        secondPartTitle: 'Solution',
        secondPartContent: [
          'Enables changes to an individual’s EHR (new imaging, procedures, labs) to be updated in real time on an EHR blockchain',
          'Patient data can only be accessed by the patient’s private key, even if the database is hacked, the data will be unreadable',
          'Patients have full control over accessing their healthcare data. The patient will be in total control when it comes to how much of his data is being accessed',
          'Transfer of medical data will become more seamless and instant. All the stakeholders in distributed network of EHR blockchain will have the same set of patient’s data'
        ],
        secondPartImage: [Project_60, Project_61, Project_62]
      },
      thirdPart: {
        thirdPartTitle: 'Gochas',
        thirdPartContent: [
          'The platform is capable to highlight / prove that medical records are unmodified and complete.',
          'Parties seeking to exchange medical records and other health data regarding a patient can refer to the blockchain for permission.',
          'The patient possess the ability to grant access to their EHR to other users and anytime can revoke access by setting up a time limited gateway, thereby improving data security'
        ],
        thirdPartImage: []
      }
    },
    {
      id: 11,
      projectIntro: {
        projectTitle: 'DLCC',
        title: 'DLCC Leading the movement from TradFi to DeFiPrime Product and Tokenet',
        date: 'Apr-Sep 2022',
        introduction:
          'DLCC is a revolution in the finance sector. The platform is transforming the traditional finance landscape with decentralized finance to facilitate automated crypto trading, lending, and borrowing without geographic restrictions at negotiable fees and interest rates with enhanced security and transparency.',
        projectImageSrc: Project_63
      },
      descriptions: [
        'The first DeFi-enabled platform is a cutting-edge solution to make crypto trading easier and transparent with more reports by charging fees based on USD notional of trades.',
        'The second platform is a quick lending and borrowing destination to secure crypto assets from credible lenders.',
        'The second platform is a quick lending and borrowing destination to secure crypto assets from credible lenders.'
      ],
      services: ['research', 'workshop', 'ux/ui design', 'development'],
      techstacks: {
        lang: ['900 PROJECT HOURS', 'REACT.JS', 'NODE.JS', 'PYTHON'],
        icons: [FaReact, FaReact, FaNodeJs, FaPython],
        description:
          'Despite being a small team, we believe that our size gives us an advantage, allowing us to be nimble, adaptable, and able to work closely with our clients to deliver truly awesome development.'
      },
      firstPart: {
        firstPartTitle: 'ChallengeS',
        firstPartContent: [
          'Introduce a solution to onboard users with two crypto custody, manage/authorize their wallets/vaults, and check it for every trade.',
          'Develop a sophisticated solution to manage real-time collateral through marks based on asset price fluctuation.',
          'Introduce unique features to facilitate ease of trading and enhance customer experiences.'
        ],
        firstPartImage: [Project_64, Project_65]
      },
      secondPart: {
        secondPartTitle: 'Solution',
        secondPartContent: [
          'This functionality onboards users with two custody and manages and authorizes their crypto wallets for a seamless trading experience.',
          'The marks functionality manages real-time collateral based on asset price fluctuation to ensure the lender and borrower benefit from their exchange.',
          'The security protocols implemented in the platform verify every trade/transaction made by every onboarded user.',
          'The real-time chat lets parties discuss one-to-one or in groups with two or more parties to get on a deal, get more info on assets, or negotiate the price.',
          'The live charts help users and other parties involved in the platform get live insights on selected crypto markets to make an informed decision on trading, lending, and borrowing.'
        ],
        secondPartImage: [Project_66, Project_67, Project_68]
      },
      thirdPart: {
        thirdPartTitle: 'Gochas',
        thirdPartContent: [
          'The project has features that allow its platform users to experience the ease of trading, lending, and borrowing.',
          'Chat module for users to chat with multiple parties and manage different alerts and notifications with different tabs.',
          'Various options for collateral posting for users to get the convenience of posting collateral in their favored way.'
        ],
        thirdPartImage: []
      }
    },
    {
      id: 12,
      projectIntro: {
        projectTitle: 'Luxury Liquor Marketplace',
        title: 'Enhancing spirit investment and NFT trading with AWS',
        date: 'Mar-June 2021',
        introduction:
          'This case study explores Paladin’s vision of transforming the landscape of high-end spirit investment and NFT trading with a seamless cloud-to-cloud migration leveraging AWS. ',
        projectImageSrc: Project_69
      },
      descriptions: [
        'This case study explores Paladin’s vision of transforming the landscape of high-end spirit investment and NFT trading with a seamless cloud-to-cloud migration leveraging AWS.',
        'By migrating their existing platform from GoDaddy to AWS infrastructure, we empowered their users to invest in exclusive spirits and purchase NFTs directly from brands.'
      ],
      services: ['Cloud-to-Cloud Migration', 'Spirit Investment Enhancement', 'NFT Trading', 'Development'],
      techstacks: {
        lang: ['850 PROJECT HOURS', 'REACT.JS', 'NODE.JS', 'ANGULAR'],
        icons: [FaReact, FaReact, FaNodeJs, FaAngular],
        description:
          'Despite being a small team, we believe that our size gives us an advantage, allowing us to be nimble, adaptable, and able to work closely with our clients to deliver truly awesome development.'
      },
      firstPart: {
        firstPartTitle: 'Process Overview',
        firstPartContent: [
          "The SilverDigitalBus team sought to leverage our team's expertise in AWS for a seamless migration from GoDaddy to AWS infrastructure, aiming to enhance scalability and security. a solution to onboard users with two crypto custody, manage/authorize their wallets/vaults, and check it for every trade.",
          'By utilizing AWS services such as EC2 for hosting monolithic APIs, S3 for extensive file storage, and CloudFront for content delivery, their goal was to streamline high-end spirit investment and NFT trading.',
          'Additionally, the project focused on developing a responsive web-based admin panel for efficient platform management.'
        ],
        firstPartImage: [Project_70]
      },
      secondPart: {
        secondPartTitle: 'Challenges',
        secondPartContent: [
          'The team faced challenges in seamlessly migrating from GoDaddy to AWS, ensuring minimal downtime and data integrity. F',
          'Further, integrating AWS services like EC2, S3, and CloudFront posed complexities due to managing monolithic APIs and extensive file storage needs, and developing a responsive web based admin panel also required iterative testing for optimal performance and usability.'
        ],
        secondPartImage: [Project_71, Project_72, Project_73]
      },
      thirdPart: {
        thirdPartTitle: 'Solutions',
        thirdPartContent: [
          "We suggested a Monolithic Approach on AWS for Paiverse's heavy-duty demands, ensuring seamless updates, an integrated environment, and enhanced operational efficiency.",
          'We implemented REST APIs for seamless communication, enabling scalability and reliable performance, enhancing PAIverse as a dependable NFT platform.',
          'We utilized Amazon EC2 for scalable compute capacity, while load balancers optimized performance, ensuring secure, responsive operations.',
          'We chose Amazon S3 for storage, ensuring scalability, security, and performance, seamlessly accommodating diverse use cases like websites and analytics.',
          "Angular and ReactJS powered PAIverse's dynamic front end. Angular's MVC streamlined development; ReactJS's virtual DOM optimized performance."
        ],
        thirdPartImage: []
      }
    },
    {
      id: 13,
      projectIntro: {
        projectTitle: 'Servisus: Simplifying Local Help for Everyday Tasks',
        title: 'Servisus: Simplifying Local Help for Everyday Tasks',
        date: 'June-Dec 2020',
        introduction: 'Servisus is a mobile app designed to make everyday tasks easier for people in cities. ',
        projectImageSrc: Project_74
      },
      descriptions: [
        'The project was undertaken with the goal of simplifying the process of finding local help for everyday tasks.',
        ' With its intuitive UI/UX, fast checkout system, and categorized and localized system of services, Servisus makes it easy and efficient for both customers and service providers to connect and get things done.'
      ],
      services: ['research', 'ui/ux design', 'Development'],
      techstacks: {
        lang: ['850 PROJECT HOURS', 'FLUTTER', 'NODE.JS', 'GOOGLE CLOUD', 'FIREBASE', 'CODECOV'],
        icons: [FaReact, SiFlutter, FaNodeJs, SiGooglecloud, SiFirebase, SiCodecov],
        description:
          'Despite being a small team, we believe that our size gives us an advantage, allowing us to be nimble, adaptable, and able to work closely with our clients to deliver truly awesome development.'
      },
      firstPart: {
        firstPartTitle: 'Challenges',
        firstPartContent: [
          'The development team faced several challenges during the development process of Servisus, such as creating a user-friendly UI/UX that works quickly and efficiently and implementing a fast and simple checkout system for both customers and service providers.',
          ' In addition, the team needed to design a structured system of services that would make it easy for customers to post tasks and for service providers to create a portfolio of their skills and expertise.'
        ],
        firstPartImage: [Project_75, Project_76]
      },
      secondPart: {
        secondPartTitle: 'Solutions',
        secondPartContent: [
          'To meet the specific needs of customers and service providers, we decided to create two separate apps. The system has been optimized to deliver a personalized and streamlined experience, so users see only the relevant features, resulting in faster and easier connections between customers and service providers. ',
          'To further enhance the experience of both customers and service providers, the development team implemented a structured system of services that divides the available tasks into categories and localizes them based on the user’s location.'
        ],
        secondPartImage: []
      },
      thirdPart: {
        thirdPartTitle: 'Gochas',
        thirdPartContent: [
          ' Through the development of two separate applications, the team produced a user-friendly interface that performs with remarkable speed and efficacy tailored to the unique requirements of both clients and vendors.',
          ' The structured system of services, categorized and localized, makes it easy for customers to post tasks and for service providers to create a portfolio of their skills and expertise.'
        ],
        thirdPartImage: []
      }
    },
    {
      id: 14,
      projectIntro: {
        projectTitle: 'Quotes+ – Your Pets’ Lifelong Protector',
        title: 'Quotes+ – Your Pets’ Lifelong Protector',
        date: 'July-Oct 2019',
        introduction: 'Quotes+ is a comprehensive app that connects pet owners with essential services.',
        projectImageSrc: Project_77
      },
      descriptions: [
        'Quotes+ is a comprehensive app that connects pet owners with essential services. From vet appointments to grooming, training, and insurance, Quotes+ offers personalized quotes and recommendations, ensuring your pets receive the care they deserve throughout their lives.'
      ],
      services: ['research', 'ui/ux design', 'Development'],
      techstacks: {
        lang: ['850 PROJECT HOURS', 'FLUTTER', 'NODE.JS', 'GOOGLE CLOUD', 'FIREBASE'],
        icons: [FaReact, SiFlutter, FaNodeJs, SiGooglecloud, SiFirebase],
        description:
          'Despite being a small team, we believe that our size gives us an advantage, allowing us to be nimble, adaptable, and able to work closely with our clients to deliver truly awesome development.'
      },
      firstPart: {
        firstPartTitle: 'Features',
        firstPartContent: [
          'The platform analyzes your pet’s needs and preferences to deliver tailored quotes, ensuring that your furry friend gets the best possible care.',
          'Our comprehensive comparison tool helps you review policies and prices, so you can choose the most suitable insurance plan for your pet’s health and your budget.',
          'The health tracker provides easy access to medical records and sends timely reminders for all your pet’s healthcare needs.',
          'Quotes+ platform connects you to 24/7 emergency pet care services, ensuring you can quickly reach a vet or urgent care provider when your pet needs it most.'
        ],
        firstPartImage: [Project_78, Project_79]
      },
      secondPart: {
        secondPartTitle: 'Solutions',
        secondPartContent: [
          'Quotes+ is meticulously crafted to revolutionize pet care by delivering an all-encompassing platform tailored for every pet’s needs. From routine vet check-ups and specialized training to comprehensive pet insurance comparisons, our app not only provides personalized recommendations but also competitive quotes that align with your pet’s unique profile. This ensures that your pet always receives the best possible care throughout their life.',
          'Our dedicated development team supports this vision by employing cutting-edge technology and continuous innovation to streamline your pet care management. We incorporate built-in health tracking features, enabling you to keep tabs on important medical appointments and vaccination schedules. In addition, our app offers instant access to emergency contacts and employs location-based technology to recommend trusted pet service providers nearby.'
        ],
        secondPartImage: []
      },
      thirdPart: {
        thirdPartTitle: 'Gochas',
        thirdPartContent: [
          'By integrating feedback loops and agile development methodologies, our team is committed to evolving the platform to meet the changing needs of pet owners and their beloved companions. With Quotes+, managing your pet’s health and wellness becomes more intuitive, ensuring that help is always just a tap away whenever you need it.'
        ],
        thirdPartImage: []
      }
    },
    {
      id: 15,
      projectIntro: {
        projectTitle: '****** App',
        title: 'On-Demand Food Delivery App - Get Your Food Delivered Faster!',
        date: 'May-Sep 2021',
        introduction:
          "****** is a food ordering app, and it is designed to deliver the foods as per the customer's requirement from the app.",
        projectImageSrc: Project_80
      },
      descriptions: [
        'This app offers services like delivery, pick up, and reservations, browse your favorite local restaurants and beverage stores. The customer can choose various cuisines from the app and place the order to their respective home, work, dinner-party, etc.',
        'There is no limit for delivery places. Customers can track the preparation time and order on maps in real-time. This app accepts all major credit cards by the customer, and currently, the services are available in New Providence, Bahamas. Moreover, this app is also designed for drivers who want to earn as drivers by delivering food and creating extra income. The driver just needs to sign in with necessary proofs and follow the guideline of the ****** app.'
      ],
      services: ['research', 'ui/ux design', 'development', 'workshop'],
      techstacks: {
        lang: ['900 HOURS', 'ANDROID', 'IOS', 'PHP', 'MYSQL'],
        icons: [FaReact, FaAndroid, SiIos, FaPhp, SiMysql],
        description:
          'Despite being a small team, we believe that our size gives us an advantage, allowing us to be nimble, adaptable, and able to work closely with our clients to deliver truly awesome development.'
      },
      firstPart: {
        firstPartTitle: 'Process Overview',
        firstPartContent: [
          "The client wanted to develop another app for the driver called ****** driver app who wishes to deliver the foods and earn the ****** app's guideline. Drivers can register their accounts for food delivery in the ****** app by submitting the proofs of identification and accepts the terms and conditions such as safe drive, punctuality, etc. Three panels must prepare such for the customer, driver, and admin of ******. Hence the client approaches the Hyperlink Infosystem to develop such an app with his requirements."
        ],
        firstPartImage: [Project_81]
      },
      secondPart: {
        secondPartTitle: 'Solutions',
        secondPartContent: [
          'Super Admin can view all sub-admin list, edit, active/inactive sub-admin, view sub-admin profiles. Super Admin can assign access privileges to sub-admin, search, and export and also delete sub-admin details.',
          'Super Admin can view all subscribers list, edit active/inactive subscribers, view subscriber profiles, search subscribers, export subscribers, mark as the flag, wallet history. And also can add wallet on specific subscribers and also delete subscriber details.',
          'Super Admin can view all restaurant list, add, edit, active/inactive restaurant,view restaurant details(restaurant Opening/Closing, order history, Bank information, Send SMS, restaurant timestamp history), search restaurant, export restaurant, and can view restaurant ratings and even delete restaurant details.',
          'The vendor can add, view, edit, delete active/inactive, search sub-vendor, and allocate them for permission to access the vendor panel.'
        ],
        secondPartImage: [Project_82, Project_83, Project_84]
      },
      thirdPart: {
        thirdPartTitle: 'Gochas',
        thirdPartContent: [
          'The Admin panel has a lot of handy functionalities. As per the client requirement, we have developed a dashboard for the admin where he can manage the users, posts, categories, content violations and restrictions along with detailed app analytics about application usage to user engagement.'
        ],
        thirdPartImage: [Project_85, Project_86, Project_87]
      }
    },
    {
      id: 16,
      projectIntro: {
        projectTitle: 'Securing 669K Visitors With A One-Of-A-Kind Site',
        title: 'Securing 669K Visitors With A One-Of-A-Kind Site',
        date: 'Jan-Apr 2022',
        introduction:
          'NewDevRev needed a website that allows visitors to search early-access new development properties in NYC, attracts the appropriate high-end clientele and adheres to legal guidelines.',
        projectImageSrc: Project_88
      },
      descriptions: [
        'NewDevRev needed a website that allows visitors to search early-access new development properties in NYC, attracts the appropriate high-end clientele and adheres to legal guidelines.',
        'We developed a database-powered, API-integrated website with dynamic content for enhanced UX, applied SEO strategies and initiated campaigns to educate buyers.',
        'This resulted in 96k users just from organic search.'
      ],
      services: ['research', 'ui/ux design', 'development'],
      techstacks: {
        lang: ['900 HOURS', 'REACT.JS', 'NODE.JS', 'Database'],
        icons: [FaReact, FaReact, FaNodeJs, SiMysql],
        description:
          'Despite being a small team, we believe that our size gives us an advantage, allowing us to be nimble, adaptable, and able to work closely with our clients to deliver truly awesome development.'
      },
      firstPart: {
        firstPartTitle: 'Challenge',
        firstPartContent: [
          'NewDevRev needed a public-facing website that allows the user to search for only new development properties in New York City and see all inventory – even before it hits the market.',
          'As a brand-new, premier development property source, NewDevRev had no digital presence in a very competitive market.',
          'They needed help in reaching the right audiences for the high-end condos – while staying within the fair housing guidelines and advertising restrictions.',
          'The public may not be aware that builders hold back listings to avoid flooding the market, so they needed to be educated on Shadow Inventory.'
        ],
        firstPartImage: [Project_89, Project_90]
      },
      secondPart: {
        secondPartTitle: 'Solution',
        secondPartContent: [
          'Our team successfully identified and integrated a suitable database through an API, ensuring a top-tier, efficient, and user-friendly search functionality. This was a significant step in developing a high-quality user interface and overall user experience.',
          'Our SEO strategy focused on the creation and distribution of content via RSS feeds, which automatically updated with new, popular and in-demand property listings, ensuring that the website remains relevant and up-to-date.',
          'We also shared content that provides valuable insights into the real estate market, highlighting the availability of properties not yet listed publicly, and positions NewDevRev as a knowledgeable and reliable source in the real estate sector.',
          'We implemented a targeted advertising strategy by focusing on specific keywords in Google Ads and catering to particular interests on Meta platforms.'
        ],
        secondPartImage: []
      },
      thirdPart: {
        thirdPartTitle: 'Gochas',
        thirdPartContent: [
          'We implemented a targeted advertising strategy by focusing on specific keywords in Google Ads and catering to particular interests on Meta platforms.'
        ],
        thirdPartImage: []
      }
    },
    {
      id: 17,
      projectIntro: {
        projectTitle: 'Telehealth',
        title: 'Medical app for making appointments with doctors',
        date: 'Fri-June 2023',
        introduction:
          "Healthcare App for making appointments with doctors, both offline and online, with video-consultation. Also, the system is able to manage doctors' schedules and make relations between clinics and doctors. The app can help improve access to healthcare and make the process of scheduling appointments more efficient and convenient for patients.",
        projectImageSrc: Project_91
      },
      descriptions: [
        'When the pandemic hit the world in 2019, the online consultation with a doctor has become increasingly popular as it allows patients to receive medical attention while minimizing the risk of exposure to the virus. So our client’s goal was to provide the possibility to schedule appointments with doctors online to save time, to reduce the waiting time and optimize doctor’s occupation. ',
        ' Users should be able to view detailed profiles of the doctors they are considering, their experience and qualifications. Also, the app should allow users to leave reviews for the doctors they have seen, which can help other patients make informed decisions.'
      ],
      services: ['research', 'ui/ux design', 'development'],
      techstacks: {
        lang: ['900 HOURS', 'ANGULAR', 'MONGO DB', 'DOCKER'],
        icons: [FaReact, FaAngular, DiMongodb, FaDocker],
        description:
          'Despite being a small team, we believe that our size gives us an advantage, allowing us to be nimble, adaptable, and able to work closely with our clients to deliver truly awesome development.'
      },
      firstPart: {
        firstPartTitle: 'Challenge',
        firstPartContent: [
          'As public may not be aware that builders hold back listings to avoid flooding the market, so they needed to be educated on Shadow Inventory.'
        ],
        firstPartImage: []
      },
      secondPart: {
        secondPartTitle: 'Goal',
        secondPartContent: [
          'Possibility to easily schedule an appointment with preferred doctor, choose a date and time of the appointment.',
          'Video chat for consultation. Calendar with booked appointments.',
          'App should send notifications to users to remind them of their upcoming appointments with booked appointments.',
          'App should beeasy to navigate and understand for users of all ages. Possibility to leave reviews and ratings for the doctors'
        ],
        secondPartImage: [Project_92, Project_93, Project_94]
      },
      thirdPart: {
        thirdPartTitle: 'Results',
        thirdPartContent: [
          'No long lines in the hospital. Push notifications. User-friendly interface. Improved health care system.',
          'Convenient process of scheduling appointments. Enhanced Communication.'
        ],
        thirdPartImage: []
      }
    },
    {
      id: 18,
      projectIntro: {
        projectTitle: 'arrow to the right',
        title: 'Developing a daily affirmation mobile app in React Native',
        date: 'Mar-July 2021',
        introduction: 'A mobile app focused on delivering a daily dose of self-endorsement',
        projectImageSrc: Project_95
      },
      descriptions: [
        'Afffirmations is a popular Instagram account with over 1 million followers. It boasts a vibrant and lively community focused on self-growth, infused with humor and well-being memes.',
        'Our customer approached us with a vision to create a mobile app that would provide a space for their followers to contribute to the Instagram page and create their own content in a unique Afffirmations branding style.'
      ],
      services: ['research', 'ui/ux design', 'development'],
      techstacks: {
        lang: ['900 HOURS', 'REACT', 'TYPESCRIPT', 'STORYBOOK', 'REACT NATIVE', 'REDUX'],
        icons: [FaReact, FaReact, SiTypescript, SiStorybook, TbBrandReactNative, SiRedux],
        description:
          'Despite being a small team, we believe that our size gives us an advantage, allowing us to be nimble, adaptable, and able to work closely with our clients to deliver truly awesome development.'
      },
      firstPart: {
        firstPartTitle: 'Challenge',
        firstPartContent: [
          'The main job of our team, comprised of a tech lead and one software developer, was to create apps for both Android and iOS systems. The app’s main features included a meme creator, daily affirmations sent via push notifications at user-set times, and a social section showcasing images created by other users',
          'Additionally, the app offered a premium version, providing users with the ability to edit images created by others and remove watermarks.',
          ''
        ],
        firstPartImage: [Project_96]
      },
      secondPart: {
        secondPartTitle: 'Solution',
        secondPartContent: [
          'To address the challenges associated with the meme creator, we decided to leverage Storybook, an excellent tool for UI improvement. ',
          'Storybook enabled us to test the app on various screen sizes, making it easier to develop and fine-tune hard-to-reach states and edge cases without running the entire app. ',
          'In the context of the meme creator, these edge cases involved handling untypically long sentences entered by users. By avoiding hardcoded sizes and utilizing Flexbox Container when applicable, we ensured scalability and flexibility in accommodating diverse user inputs.'
        ],
        secondPartImage: []
      },
      thirdPart: {
        thirdPartTitle: 'Gochas',
        thirdPartContent: [
          "To ensure a smooth user experience, we conducted rigorous testing and performance optimization. We identified and resolved bottlenecks and memory leaks, resulting in improved app responsiveness and reduced lags on users' devices. By prioritizing seamless functionality, we aimed to provide an enjoyable experience for Afffirmations app users."
        ],
        thirdPartImage: [Project_97, Project_96, Project_98]
      }
    },
    {
      id: 19,
      projectIntro: {
        projectTitle: 'VerifiID',
        title: 'VerifiID – an identity verification MVP',
        date: 'May-Sep 2023',
        introduction: 'Developing a system to replace personal IDs for age verification',
        projectImageSrc: Project_99
      },
      descriptions: [
        'VerifiID’s goal was to develop a mobile app that could be used for age verification instead of government-issued identification such as a driving license or passport.',
        'The app doesn’t store the users’ personal data, but rather encrypts the information for easy access whenever the user needs to provide proof of age.'
      ],
      services: ['research', 'ui/ux design', 'development'],
      techstacks: {
        lang: ['900 HOURS', 'REACT', 'NEST', 'REACT NATIVE', 'REDUX-SAGA'],
        icons: [FaReact, FaReact, SiTypescript, TbBrandReactNative, SiReduxsaga],
        description:
          'Despite being a small team, we believe that our size gives us an advantage, allowing us to be nimble, adaptable, and able to work closely with our clients to deliver truly awesome development.'
      },
      firstPart: {
        firstPartTitle: 'Challenge',
        firstPartContent: [
          'One of the requirements of the app was to be able to accurately compare the image from the user’s physical ID document with the uploaded selfie. The VerifiID mobile app needed to be integrated with the chosen service providers which would ensure the accurate reading of facial biometric data.',
          'The challenge was how to register the biometric data of the users within the system without actually storing sensitive data. The goal was to only require users to register once without having to confirm their age within the app multiple times.'
        ],
        firstPartImage: [Project_100, Project_101]
      },
      secondPart: {
        secondPartTitle: 'Solution',
        secondPartContent: [
          "The security concerns were addressed by ensuring that there was less data transferred within the network. The users’ personal information is encoded into a PKI certificate that is saved directly to the user’s phone. Any time a user needs to provide proof of age, the app generates a short-lived code that is cryptographically derived from the user's certificate.",
          'Since the user’s data is stored on their phone, not in the network, the user loses access to their account if their phone is lost or stolen. No one else has access to your personal data, since it’s fully encrypted. The key isn’t saved anywhere else, so the only thing that could be done is to reset the hash of the document within the database. ',
          'The solution for a user in this situation is to send an email request to reset the personal document, so it can be used again with a new device and registration.'
        ],
        secondPartImage: []
      },
      thirdPart: {
        thirdPartTitle: 'Gochas',
        thirdPartContent: [
          'React native was chosen due to its multiplatform nature and its ability to support both systems. React admin served as a framework and proved to be a great solution for the admin panel. It was a standardized solution to problems with creating and modifying data within the app. ',
          'AWS cloud and cognito service were used to manage the users in the web version that the verifiers, such as bar owners, can use. This simplified the user authentication and verification processes. AWS SNS was used to send SMS codes and AWS Cloudwatch was used for collecting logs and metrics.'
        ],
        thirdPartImage: []
      }
    },
    {
      id: 20,
      projectIntro: {
        projectTitle: 'Revenue optimization system ',
        title: 'Revenue optimization system that leaves no room available',
        date: 'Apr-Sep 2020',
        introduction:
          'If you ever planned to vacation on the Adriatic, you’ve probably come across Maistra Hospitality Group. With 18 hotels, 11 tourist villages, and 6 campsites in internationally sought-after destinations like Dubrovnik and Rovinj, it is the most prestigious hospitality group in Croatia.',
        projectImageSrc: Project_102
      },
      descriptions: [
        'We helped Maistra build a robust solution for revenue management that streamlines their business operations.',
        'Powered by AI, Edmond is a unique revenue optimization tool that integrates numerous internal and external data sources, predicting occupancy and autonomously adjusting accommodation rates.'
      ],
      services: ['research', 'ui/ux design', 'branding', 'engineering', 'quality assurance'],
      techstacks: {
        lang: ['PYTHON', 'TENSORFLOW', 'NUMPY', 'AWS'],
        icons: [FaPython, SiTensorflow, SiNumpy, FaAws],
        description:
          'Despite being a small team, we believe that our size gives us an advantage, allowing us to be nimble, adaptable, and able to work closely with our clients to deliver truly awesome development.'
      },
      firstPart: {
        firstPartTitle: 'Challenge',
        firstPartContent: [
          'Our team find patterns from internal data, but also uses publicly available information to analyze competitor rates. It compares room prices and suggests corrections.',
          'When on autopilot, the system has the autonomy to set room prices and adjust availability. Driven by data, Edmond provides an accurate and real-time insight into the bigger picture of the hotel’s performance.'
        ],
        firstPartImage: [Project_103, Project_104]
      },
      secondPart: {
        secondPartTitle: 'Feature',
        secondPartContent: [
          'Revenue managers need no-fuss information visibility at all times. The interface was designed around displaying multiple data layers in one.',
          'Chart creation tool enables users to filter and deliver tailor-made charts for specific purposes. Simpler, yet more powerful than Excel.'
        ],
        secondPartImage: [Project_105, Project_106, Project_107]
      },
      thirdPart: {
        thirdPartTitle: 'Gochas',
        thirdPartContent: [
          'It has proven its worth in one of the most demanding tourist markets, and Maistra is now working to create a commercial equivalent to the system.'
        ],
        thirdPartImage: []
      }
    }
  ]
}
export default ProjectDetail
