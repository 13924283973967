import useColorMode from 'src/context/useColorMode'

const DarkModeSwitcher = () => {
  const [colorMode, setColorMode] = useColorMode()
  return (
    <button
      className={`flex p-3 rounded-full border items-center justify-center hover:opacity-20  ${
        colorMode === 'dark' ? 'border-white' : 'border-black'
      }`}
      onClick={() => {
        if (typeof setColorMode === 'function') {
          setColorMode(colorMode === 'light' ? 'dark' : 'light')
        }
      }}
    >
      <span className='dark:block hidden'>
        <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' id='dark'>
          <path
            d='M12.75 1C12.75 0.585786 12.4142 0.25 12 0.25C11.5858 0.25 11.25 0.585786 11.25 1V3C11.25 3.41421 11.5858 3.75 12 3.75C12.4142 3.75 12.75 3.41421 12.75 3V1Z'
            fill='white'
          />
          <path
            d='M4.75216 3.69149C4.45926 3.3986 3.98439 3.3986 3.6915 3.69149C3.3986 3.98439 3.3986 4.45926 3.6915 4.75216L5.10571 6.16637C5.3986 6.45926 5.87348 6.45926 6.16637 6.16637C6.45926 5.87348 6.45926 5.3986 6.16637 5.10571L4.75216 3.69149Z'
            fill='white'
          />
          <path
            d='M20.3085 4.75216C20.6014 4.45926 20.6014 3.98439 20.3085 3.6915C20.0156 3.3986 19.5407 3.3986 19.2478 3.69149L17.8336 5.10571C17.5407 5.3986 17.5407 5.87348 17.8336 6.16637C18.1265 6.45926 18.6014 6.45926 18.8943 6.16637L20.3085 4.75216Z'
            fill='white'
          />
          <path
            d='M12 5.25C8.27208 5.25 5.25 8.27208 5.25 12C5.25 15.7279 8.27208 18.75 12 18.75C15.7279 18.75 18.75 15.7279 18.75 12C18.75 8.27208 15.7279 5.25 12 5.25Z'
            fill='white'
          />
          <path
            d='M1 11.25C0.585786 11.25 0.25 11.5858 0.25 12C0.25 12.4142 0.585786 12.75 1 12.75H3C3.41421 12.75 3.75 12.4142 3.75 12C3.75 11.5858 3.41421 11.25 3 11.25H1Z'
            fill='white'
          />
          <path
            d='M21 11.25C20.5858 11.25 20.25 11.5858 20.25 12C20.25 12.4142 20.5858 12.75 21 12.75H23C23.4142 12.75 23.75 12.4142 23.75 12C23.75 11.5858 23.4142 11.25 23 11.25H21Z'
            fill='white'
          />
          <path
            d='M6.16637 18.8943C6.45926 18.6014 6.45926 18.1265 6.16637 17.8336C5.87348 17.5407 5.3986 17.5407 5.10571 17.8336L3.6915 19.2478C3.3986 19.5407 3.3986 20.0156 3.6915 20.3085C3.98439 20.6014 4.45926 20.6014 4.75216 20.3085L6.16637 18.8943Z'
            fill='white'
          />
          <path
            d='M18.8943 17.8336C18.6014 17.5407 18.1265 17.5407 17.8336 17.8336C17.5407 18.1265 17.5407 18.6014 17.8336 18.8943L19.2478 20.3085C19.5407 20.6014 20.0156 20.6014 20.3085 20.3085C20.6014 20.0156 20.6014 19.5407 20.3085 19.2478L18.8943 17.8336Z'
            fill='white'
          />
          <path
            d='M12.75 21C12.75 20.5858 12.4142 20.25 12 20.25C11.5858 20.25 11.25 20.5858 11.25 21V23C11.25 23.4142 11.5858 23.75 12 23.75C12.4142 23.75 12.75 23.4142 12.75 23V21Z'
            fill='white'
          />
        </svg>
      </span>
      <span className='block dark:hidden'>
        <svg xmlns='http://www.w3.org/2000/svg' width='19' height='22' viewBox='0 0 19 22' fill='none' id='light'>
          <path
            d='M9.91902 11.0134C8.05089 7.77773 8.39618 4.88815 8.67101 3.0347L8.69962 2.84335C8.75715 2.46024 8.81252 2.09144 8.83128 1.7912C8.8424 1.61306 8.84408 1.41736 8.8135 1.22926C8.78343 1.04435 8.7112 0.79715 8.51717 0.588105C8.07635 0.113182 7.4573 0.245027 7.12294 0.351822C6.7256 0.478732 6.22904 0.730803 5.62655 1.07865C0.484905 4.04718 -1.27675 10.6218 1.69178 15.7634C4.66031 20.9051 11.2349 22.6667 16.3766 19.6982C16.979 19.3503 17.4456 19.0464 17.7542 18.7657C18.0139 18.5295 18.4376 18.0593 18.2467 17.4401C18.1627 17.1676 17.9847 16.9814 17.8396 16.8629C17.692 16.7424 17.5217 16.646 17.3618 16.5665C17.0925 16.4327 16.7454 16.2962 16.3849 16.1545L16.2048 16.0836C14.4622 15.3949 11.7871 14.2491 9.91902 11.0134Z'
            fill='black'
          />
        </svg>
      </span>
    </button>
  )
}

export default DarkModeSwitcher
