import { ReactNode } from 'react'
import AnimateInOut from './AnimateInOut'

interface Props {
  children: ReactNode
  delay: number
  ease?: string
  x?: number
  skipOutro?: boolean
  scaleTo?: number
  y?: number
  xTo?: number
  yTo?: number
  scale?: number
  duration?: number
  className?: string
}

const ScaleInOut = ({
  children,
  delay,
  scale,
  ease,
  duration,
  x,
  y,
  skipOutro,
  scaleTo,
  xTo,
  yTo,
  className
}: Props) => {
  const s = scale || 0.01
  const e = ease || 'elastic.out'
  const d = duration || 1
  const sTo = scaleTo || 1

  return (
    <AnimateInOut
      className={className}
      durationIn={d}
      durationOut={d / 4}
      delay={delay}
      skipOutro={skipOutro}
      set={{
        opacity: 0,
        scale: s,
        x: x || 0,
        y: y || 0
      }}
      from={{
        opacity: 0,
        scale: s,
        x: x || 0,
        y: y || 0
      }}
      to={{
        opacity: 1,
        scale: sTo,
        ease: e,
        x: xTo || 0,
        y: yTo || 0
      }}
    >
      {children}
    </AnimateInOut>
  )
}

export default ScaleInOut
