const links = [
  { title: 'Home', link: '/' },
  { title: 'Skill/Services', link: '/skill' },
  { title: 'Projects', link: '/projects' },
  { title: 'Team', link: '/team' },
  { title: 'Feedback', link: '/feedback' },
  { title: 'Contact', link: '/contact' }
]

export default links
