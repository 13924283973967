import { useRef, useContext, ReactNode, memo } from 'react'
import { gsap } from 'gsap-trial'
import useIsomorphicLayoutEffect from './useIsomorphicLayoutEffect'
import { TransitionContext } from '../context/TransitionContext'
import { randomNumber } from './util'

interface Props {
  children: ReactNode
  target: gsap.DOMTarget
}

const ImplodeExplodeInOut = ({ children, target }: Props) => {
  const { timeline } = useContext(TransitionContext)
  const el = useRef<HTMLDivElement>(null)

  useIsomorphicLayoutEffect(() => {
    const chars = document.querySelector(target as string)?.childNodes || []

    chars.forEach(char => {
      gsap.fromTo(
        char,
        {
          x: randomNumber(-2000, 2000),
          y: randomNumber(-1000, 1000),
          z: randomNumber(100, 100),
          opacity: 0,
          rotation: randomNumber(360, 720),
          rotationX: randomNumber(-360, 360),
          rotationY: randomNumber(-360, 360),
          ease: 'power4.out'
        },
        {
          x: 0,
          y: 0,
          z: 0,
          rotation: 0,
          rotationX: 0,
          rotationY: 0,
          opacity: 1,
          duration: 1,
          delay: 1 + Math.random() * 0.5,
          ease: 'power4.out'
        }
      )

      // outro animation
      timeline.add(
        gsap.to(char, 1, {
          x: randomNumber(-2000, 2000),
          y: randomNumber(-1000, 1000),
          z: randomNumber(100, 100),
          opacity: 0,
          rotation: randomNumber(360, 720),
          rotationX: randomNumber(-360, 360),
          rotationY: randomNumber(-360, 360),
          ease: 'power4.in',
          duration: 0.1
        }),
        0
      )
    })

    gsap.set(el.current, {
      opacity: 1
    })
  }, [])

  return (
    <div style={{ opacity: 0 }} ref={el}>
      {children}
    </div>
  )
}

export default memo(ImplodeExplodeInOut)
