import React, { useEffect, useRef } from 'react'
import { gsap } from 'gsap-trial'
import ScrollTrigger from 'gsap-trial/dist/ScrollTrigger'

import stepperCards from 'src/constants/steppercards'
import Stepper from 'src/components/Stepper'
import StepCard from 'src/components/Stepper/StepCard'

import { ReactComponent as StarIcon } from 'src/assets/icon/star.svg'
import { ReactComponent as BlueStarIcon } from 'src/assets/icon/star1.svg'
import { ReactComponent as ArrowRightIcon } from 'src/assets/icon/arrowRight.svg'
import { ReactComponent as ArrowRightIconDark } from 'src/assets/icon/arrowRightDark.svg'

gsap.registerPlugin(ScrollTrigger)

const Process: React.FC = () => {
  const stepRefs = useRef<(HTMLDivElement | null)[]>([])
  const btnRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: '#process',
          start: 'top 30%',
          end: 'top bottom',
          scrub: 2
        }
      })
      .fromTo(
        '.process-title-1',
        {
          y: -200,
          opacity: 0,
          duration: 0.25,
          ease: 'power4.in'
        },
        {
          opacity: 1,
          delay: 0,
          duration: 1,
          y: 0,
          ease: 'bounce.out'
        }
      )

    gsap
      .timeline({
        scrollTrigger: {
          trigger: '#process',
          start: 'top 30%',
          end: 'top bottom',
          scrub: 2
        }
      })
      .fromTo(
        '.process-title-2',
        {
          y: -200,
          opacity: 0,
          duration: 0.25,
          ease: 'power4.in'
        },
        {
          opacity: 1,
          delay: 1,
          duration: 1,
          y: 0,
          ease: 'bounce.out'
        }
      )

    gsap
      .timeline({
        scrollTrigger: {
          trigger: '#process',
          start: 'top 30%',
          end: 'top bottom',
          scrub: 2
        }
      })
      .fromTo(
        '.process-title-3',
        {
          y: -200,
          opacity: 0,
          duration: 0.25,
          ease: 'power4.in'
        },
        {
          opacity: 1,
          delay: 2,
          duration: 1,
          y: 0,
          ease: 'bounce.out'
        }
      )

    gsap
      .timeline({
        scrollTrigger: {
          trigger: '#process',
          start: 'top 30%',
          end: 'top bottom',
          scrub: 2
        }
      })
      .fromTo(
        '.process-title-4',
        {
          y: -200,
          opacity: 0,
          duration: 0.25,
          ease: 'power4.in'
        },
        {
          opacity: 1,
          delay: 3,
          duration: 1,
          y: 0,
          ease: 'bounce.out'
        }
      )

    gsap
      .timeline({
        scrollTrigger: {
          trigger: '#process',
          start: 'top 30%',
          end: 'top bottom',
          scrub: 2
        }
      })
      .fromTo(
        '.process-icon',
        {
          y: -200,
          opacity: 0,
          duration: 0.25,
          ease: 'power4.in'
        },
        {
          opacity: 1,
          delay: 4,
          duration: 1,
          y: 0,
          ease: 'bounce.out'
        }
      )

    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: '#steper',
        start: 'top 80%',
        end: 'bottom 20%',
        scrub: 4
      }
    })

    stepRefs.current.forEach(stepRef => {
      if (stepRef) {
        gsap.set(stepRef, { opacity: 0, x: 300 }) // Start off-screen to the right
      }
    })

    stepRefs.current.forEach((stepRef, index) => {
      if (stepRef) {
        timeline.to(
          stepRef,
          {
            opacity: 1,
            x: 0,
            duration: 0.5,
            ease: 'power2.out',
            onComplete: () => {
              // Style change after fading in
              if (stepRef) {
                stepRef.style.transform = 'scale(1.01)'
                stepRef.style.transition = '0.5s' // Example scale effect
              }
            }
          },
          index * 0.4
        )
      }
    })

    const getYValue = () => {
      if (window.innerWidth < 768) {
        return 2000 // For mobile devices
      } else if (window.innerWidth < 1024) {
        return 2000 // For tablets
      } else {
        return 1700 // For desktops
      }
    }

    // Animate the button
    if (btnRef.current) {
      gsap.to(btnRef.current, {
        y: getYValue(), // Use the responsive y value
        scrollTrigger: {
          trigger: '#steper',
          start: 'top top',
          end: 'bottom top',
          scrub: 5
        }
      })
    }
  }, [])

  return (
    <div
      id='process'
      className='overflow-x-hidden detail-container px-5 md:px-12 md:pt-40 grid grid-cols-1 md:grid-cols-2'
    >
      <div id='title' className='py-12 flex flex-col justify-start items-start relative z-10'>
        <img
          src='/assets/gif/Loop 2 Dark.gif'
          className='absolute hidden dark:block h-full top-0 left-0 -z-20 object-cover'
        />
        <img
          src='/assets/gif/Loop 2 Light.gif'
          className='absolute block dark:hidden top-1/4 left-0 -z-30 object-cover'
        />
        <p className='text-50 font-normal md:tracking-wider w-full dark:text-white text-black font-bruno'>
          <span className='block image-container justify-center items-center object5'>
            <div className='relative flex items-center '>
              <BlueStarIcon />
              <StarIcon className='absolute dark:bg-white bg-green blur-[3rem] lg:blur-[3rem] boaming-image ' />
            </div>
          </span>
          <span className='process-title-1 block break-all font-bruno'>Our</span>
          <span className='process-title-2 block break-all font-bruno'>Software</span>
          <span className='process-title-3 block break-all font-bruno'>Development</span>
          <span className='process-title-4 block break-all font-bruno'>Process</span>
        </p>

        <span className='process-icon block mt-8 md:mt-10'>
          <span className='hidden dark:block'>
            <ArrowRightIcon />
          </span>
          <span className='block dark:hidden'>
            <ArrowRightIconDark />
          </span>
        </span>
      </div>
      <div
        id='steper'
        className='flex flex-col mt-20 md:mt-50 border-l-black border-l-[1px] dark:border-l-white border-dashed relative'
      >
        <p
          id='btn'
          ref={btnRef}
          className='absolute md:z-10 top-10 translate-x-[-50%] translate-y-0 w-6 h-10 rounded-lg bg-success -z-10 '
        />
        <Stepper>
          {stepperCards.map((step, i) => (
            <div key={i} ref={el => (stepRefs.current[i] = el)}>
              <StepCard order={step.order} name={step.name} detail={step.detail} active={true} />
            </div>
          ))}
        </Stepper>
      </div>
    </div>
  )
}

export default Process
