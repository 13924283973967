import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'

import gsap from 'gsap-trial'
import ScrollTrigger from 'gsap-trial/dist/ScrollTrigger'

import newProjects from 'src/constants/newProjects'
import CustomProgressDots from 'src/components/CustomProgressDots'

import { ReactComponent as StarIcon } from 'src/assets/icon/star.svg'

gsap.registerPlugin(ScrollTrigger)
const Newest: React.FC = () => {
  const containerRef = useRef<HTMLDivElement | null>(null)
  const [currentSlide, setCurrentSlide] = useState(0)

  useEffect(() => {
    if (!containerRef?.current) return
    const sections = gsap.utils.toArray<HTMLDivElement>('.panel-item', containerRef.current)
    gsap.to(sections, {
      xPercent: -100 * (sections.length - 1),
      duration: 0.5,
      ease: 'none',
      scrollTrigger: {
        trigger: containerRef.current,
        pin: true,
        scrub: 1,
        snap: 1 / (sections.length - 1),
        end: '+=3500',
        onUpdate: scrollTrigger => {
          const progress = scrollTrigger.progress
          const currentIndex = Math.round(progress * (sections.length - 1))
          setCurrentSlide(currentIndex)
        }
      }
    })

    gsap
      .timeline({
        scrollTrigger: {
          trigger: '.tracking-wider',
          start: 'top 30%',
          end: 'top bottom',
          scrub: 3
        }
      })
      .fromTo(
        `.newest-title`,
        {
          opacity: 0,
          scale: 0.01,
          x: 0,
          y: 0
        },
        { opacity: 1, scale: 1, ease: 'elastic.out', x: 0, y: 0, delay: 4, duration: 1 }
      )

    gsap
      .timeline({
        scrollTrigger: {
          trigger: '.tracking-wider',
          start: 'top 30%',
          end: 'top bottom',
          scrub: 3
        }
      })
      .fromTo(
        `.custom-progress`,
        {
          opacity: 0,
          scale: 0.01,
          x: 0,
          y: 0
        },
        { opacity: 1, scale: 1, ease: 'elastic.out', x: 0, y: 0, delay: 4, duration: 1 }
      )

    gsap
      .timeline({
        scrollTrigger: {
          trigger: '.tracking-wider',
          start: 'top 30%',
          end: 'top bottom',
          scrub: 3
        }
      })
      .fromTo(
        `.newest-icon`,
        {
          opacity: 0,
          scale: 0.01,
          x: 0,
          y: 0
        },
        { opacity: 1, scale: 1, ease: 'elastic.out', x: 0, y: 0, delay: 7, duration: 1 }
      )

    gsap
      .timeline({
        scrollTrigger: {
          trigger: '.tracking-wider',
          start: 'top 30%',
          end: 'top bottom',
          scrub: 3
        }
      })
      .fromTo(
        `.newest-project`,
        {
          opacity: 0,
          scale: 0.01
        },
        { opacity: 1, scale: 1, delay: 7, duration: 1 }
      )
    return () => {
      ScrollTrigger.getAll().forEach(trigger => trigger.kill(true))
    }
  }, [])

  return (
    <div>
      <div ref={containerRef}>
        <div className='tracking-wider p-10 flex lg:flex-row flex-col lg:gap-0 gap-10 justify-between items-center'>
          <span className='relative'>
            <p className='newest-title font-bruno text-18 lg:text-50 dark:text-white text-black font-normal'>
              Newest Projects
            </p>
            <div
              className='absolute object5'
              style={{
                bottom: '-40px',
                left: '100%',
                transform: 'translateX(-50%)'
              }}
            >
              <span className='newest-icon relative flex justify-center items-center'>
                <StarIcon />
                <StarIcon className='absolute dark:bg-white bg-prime blur-[3rem] boaming-image' />
              </span>
            </div>
          </span>
          <CustomProgressDots slideCount={newProjects.newProjects.length} currentSlide={currentSlide} />
        </div>
        <div className='horizon-container newest-project'>
          {newProjects.newProjects.map((project, i) => (
            <div
              key={i}
              className='panel-item relative w-screen xl:h-cal-full-155 lg:h-cal-full-125 md:h-cal-full-172 sm:h-cal-full-144'
            >
              <div className='absolute inset-0 overlay-card' />
              <img
                src={project.image}
                alt='image1'
                className='w-full h-full object-cover hue-rotate-90'
                style={{ filter: 'none' }}
              />
              <div className='absolute inset-0 grid grid-cols-2 md:items-center md:justify-center z-10'>
                <div className='col-span-2 flex justify-end'>
                  <Link to='/projects'>
                    <button className='relative flex w-28 h-28 p-2 mr-40 mt-28 justify-center items-center view-more'>
                      <p className='title font-archivo target'>View Details</p>
                    </button>
                  </Link>
                </div>

                <div className='col-span-2 md:col-span-1 text-white flex flex-col md:items-start md:text-start px-6 pt-64 gap-4 md:px-12 md:gap-6 md:mb-12 self-end about--content'>
                  <p className='text-18 font-archivo'>{project.title}</p>
                  <p className='text-14 font-archivo'>{project.description}</p>
                </div>
                <div className='col-span-2 md:col-span-1 flex justify-start md:justify-end items-start content-start gap-2 flex-wrap px-6 md:px-12 mb-12 self-end about--icon'>
                  {project.tags.map((tagName, index) => (
                    <div key={index} className='flex px-3 py-1 md:p-4 justify-center items-center tag'>
                      <p className='text-white text-14 font-archivo'>{tagName}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Newest
