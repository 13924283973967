import React, { useState } from 'react'

interface CardProps {
  title: string
  description: string
  icon: React.FC<React.SVGProps<SVGSVGElement>> // Ensure this is a functional component type
  darkIcon: React.FC<React.SVGProps<SVGSVGElement>>
}
const Card: React.FC<CardProps> = ({ title, description, icon: Icon, darkIcon: DarkIcon }) => {
  const [isHovered, setIsHovered] = useState(false)
  return (
    <div
      className='p-8 max-lg:px-4 gap-4 flex flex-col justify-between dark:bg-custom-black bg-customWhiteGray bg-opacity-30 border dark:border-customGray border-customWhiteGray h-full hover:bg-white hover:border-white hover:dark:bg-black hover:dark:border-black'
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className='flex justify-start items-center'>
        <div className='hidden dark:block'>
          <DarkIcon
            fontSize='4rem'
            color={`${isHovered ? '#5AC4FF' : 'white'}`}
            className={`transition-transform duration-500 ${isHovered ? 'rotating-svg' : ''}`}
          />
        </div>
        <div className='block dark:hidden'>
          <Icon
            fontSize='4rem'
            color={`${isHovered ? '#5AC4FF' : 'black'}`}
            className={`transition-transform duration-500 ${isHovered ? 'rotating-svg' : ''}`}
          />
        </div>
      </div>

      <div className='flex flex-col items-start gap-4 flex-grow w-full'>
        <p className='card-title dark:text-white text-black font-semibold w-full font-archivo'>{title}</p>
        <p className='card-description dark:text-white text-black w-full font-archivo'>{description}</p>
      </div>
    </div>
  )
}

export default Card
