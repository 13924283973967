import React from 'react'
interface StepCardProps {
  order: number
  name: string
  detail: string
  active: boolean
}

const StepCard: React.FC<StepCardProps> = ({ order, name, detail, active = false }) => {
  return (
    <div className={`flex items-start gap-6 md:gap-12 self-stretch relative py-10 transition-all ml-16`}>
      <div className={`flex flex-col pb-10 md:pb-20 self-stretch cursor-pointer `}>
        <div className='flex gap-5 pb-5'>
          <p
            className={`text-18 font-extralight uppercase flex items-end font-archivo pr-8 ${
              active ? 'font-medium text-prime dark:text-illicitGreen scale-110' : 'dark:text-white text-black scale-95'
            }`}
          >
            {order}. {name}
          </p>
        </div>
        <p
          className={`mt-4 text-14 font-normal text-gray font-archivo text-left ${
            active ? 'scale-110 transition-transform duration-100' : 'scale-90 transition-opacity duration-100'
          } `}
        >
          {detail}
        </p>
      </div>
    </div>
  )
}

export default StepCard
