import React, { useRef } from 'react'

import gsap from 'gsap-trial'
import ScrollTrigger from 'gsap-trial/dist/ScrollTrigger'

import data from 'src/constants/data'
import Counter from 'src/components/Counter'
import useIsomorphicLayoutEffect from 'src/animation/useIsomorphicLayoutEffect'
import { randomNumber } from 'src/animation/util'
import Lettering from 'src/animation/Lettering'

gsap.registerPlugin(ScrollTrigger)

const AboutUs: React.FC = () => {
  const text1Ref = useRef<HTMLDivElement>(null)
  const text2Ref = useRef<HTMLDivElement>(null)

  useIsomorphicLayoutEffect(() => {
    const timeine1 = gsap.timeline({
      scrollTrigger: {
        trigger: '#about',
        start: 'top 30%',
        end: 'top bottom',
        scrub: 3
      }
    })

    const chars1 = document.querySelector('#aboutus-decription1')?.childNodes || []

    chars1.forEach(char => {
      timeine1.fromTo(
        char,
        {
          x: randomNumber(-2000, 2000),
          y: randomNumber(-1000, 1000),
          z: randomNumber(100, 100),
          opacity: 0,
          rotation: randomNumber(360, 720),
          rotationX: randomNumber(-360, 360),
          rotationY: randomNumber(-360, 360),
          ease: 'power4.out'
        },
        {
          x: 0,
          y: 0,
          z: 0,
          rotation: 0,
          rotationX: 0,
          rotationY: 0,
          opacity: 1,
          duration: 1,
          delay: 1 + Math.random() * 0.5,
          ease: 'power4.out'
        }
      )
    })

    gsap.set(text1Ref.current, {
      opacity: 1
    })

    const timeine2 = gsap.timeline({
      scrollTrigger: {
        trigger: '#about',
        start: 'top 30%',
        end: 'top bottom',
        scrub: 3
      }
    })

    const chars = document.querySelector('#aboutus-decription2')?.childNodes || []

    chars.forEach(char => {
      timeine2.fromTo(
        char,
        {
          x: randomNumber(-2000, 2000),
          y: randomNumber(-1000, 1000),
          z: randomNumber(100, 100),
          opacity: 0,
          rotation: randomNumber(360, 720),
          rotationX: randomNumber(-360, 360),
          rotationY: randomNumber(-360, 360),
          ease: 'power4.out'
        },
        {
          x: 0,
          y: 0,
          z: 0,
          rotation: 0,
          rotationX: 0,
          rotationY: 0,
          opacity: 1,
          duration: 1,
          delay: 1 + Math.random() * 0.5,
          ease: 'power4.out'
        }
      )
    })

    gsap.set(text2Ref.current, {
      opacity: 1
    })

    Array.from({ length: 4 }, (_, index) => {
      gsap
        .timeline({
          scrollTrigger: {
            trigger: '#about',
            start: 'top 30%',
            end: 'top bottom',
            scrub: 3
          }
        })
        .fromTo(
          `.about-count-${index}`,
          { y: 100, opacity: 0, ease: 'sine.inOut' },
          { y: 0, opacity: 1, ease: 'sine.inOut', delay: index }
        )
    })
    gsap
      .timeline({
        scrollTrigger: {
          trigger: '#about',
          start: 'top 30%',
          end: 'top bottom',
          scrub: 1
        }
      })
      .fromTo(`.about-line`, { x: -100, opacity: 0, ease: 'power4.out' }, { x: 0, opacity: 1, ease: 'power4.out' })
  }, [])

  return (
    <div id='about' className='mt-40 px-5 md:px-12 detail-container'>
      <div ref={text1Ref}>
        <div id='aboutus-decription1' className='text-40 dark:text-white text-black font-normal font-archivo'>
          <Lettering
            text='‘ Despite being a small team, we believe that our size gives us an advantage, allowing us to be nimble,
            adaptable, and able to work closely with our clients to deliver truly awesome development.’'
          />
        </div>
      </div>

      <div ref={text2Ref} className='flex flex-col items-start gap-8 mt-8 md:items-center md:flex-row'>
        <div className='w-[141.432px] h-[2px] bg-prime dark:bg-aqua about-line' />
        <div id='aboutus-decription2' className='dark:text-aqua text-prime text-20 leading-10 font-normal font-archivo'>
          <Lettering text='Digital thinkers who build awesome Software.' />
        </div>
      </div>

      <div className='grid grid-cols-2 mt-12 pb-12 xl:grid-cols-4 md:mt-24 md:pb-36 items-center gap-3'>
        {data.map((item, index) => (
          <div key={index} className={`flex flex-col items-center gap-3 about-count-${index}`}>
            <Counter title={item.title} value={item.value} symbol={item.symbol} duration={item.duration} />
          </div>
        ))}
      </div>
    </div>
  )
}

export default AboutUs
