/**
 * Lettering Component
 * Splits the text into spans based on mode: 'letters', 'words', or 'lines'.
 */

interface Props {
  text: string
  fontStyle?: string
}

const Lettering = ({ text, fontStyle = 'font-archivo' }: Props) => {
  const splitText = () => {
    return text.split('').map((char, index) => (
      <div key={index} className={`${fontStyle} inline-block char${index + 1} ${char === ' ' ? 'char-blink' : ''}`}>
        {char}
      </div>
    ))
  }

  return <>{splitText()}</>
}

export default Lettering
