import RadiusButton from 'src/components/RadiusButton'

interface ServiceProps {
  services: string[]
}

const Service: React.FC<ServiceProps> = ({ services }) => {
  return (
    <>
      <div className='flex gap-30 pb-50 border-b border-silverMedal dark:border-jet mb-50 max-sm:flex-col relative'>
        <img
          src='/assets/gif/Loop 4 Dark.gif'
          className='absolute hidden dark:block -top-52 -right-1/4  max-sm:-top-20 -z-20 rotate-135'
        />
        <img
          src='/assets/gif/Loop 4 Light.gif'
          className='absolute block dark:hidden -top-52 -right-1/4  max-sm:-top-20 -z-20 rotate-135'
        />
        <h2 className='w-40 font-archivo text-blackhole font-normal text-18 dark:text-white'>Services</h2>
        <div className='w-53 max-sm:w-full max-sm:gap-2 flex flex-wrap gap-16'>
          {services.map((item, index) => (
            <RadiusButton
              key={index}
              title={item}
              className='blueRadiusButton font-archivo uppercase group-hover:opacity-75 scale-95 transtion ease-in-out duration-300 hover:scale-100 hover:border-buttonBorderColor'
            />
          ))}
        </div>
      </div>
    </>
  )
}

export default Service
