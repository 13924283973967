import React, { useEffect, useState } from 'react'

// Define the interface for the props
interface CounterProps {
  title: string
  value: number
  symbol: string
  duration: number
}

// Define the interface for the countUp function parameters
interface CountUpParams {
  target: number
  setState: React.Dispatch<React.SetStateAction<number>>
  duration: number
}

const Counter: React.FC<CounterProps> = ({ title, value, symbol, duration }) => {
  const [currentValue, setCurrentValue] = useState<number>(0)

  const countUp = ({ target, setState, duration }: CountUpParams) => {
    let start = 0
    const increment = target / (duration / 100) // Calculate increment per interval
    const interval = setInterval(() => {
      start += increment
      if (start >= target) {
        clearInterval(interval)
        start = target // Ensure we end exactly at the target
      }
      setState(Math.round(start))
    }, 100)
  }

  useEffect(() => {
    countUp({ target: value, setState: setCurrentValue, duration })
  }, [value, duration])

  return (
    <div className='flex flex-col gap-3 items-center'>
      <span className='dark:text-whiteGrey text-14 font-medium font-archivo'>{title}</span>
      <span className='dark:text-aqua text-prime text-50 font-normal font-archivo'>
        {currentValue}
        {symbol}
      </span>
    </div>
  )
}

export default Counter
