import React, { useState, useEffect, ReactNode } from 'react'
import Carousel from 'react-spring-3d-carousel'
import { config } from 'react-spring'

interface Card {
  key: number
  content: ReactNode
}

interface CarrousselProps {
  cards: Card[]
  offset: number
  showArrows: boolean
}

const Carroussel: React.FC<CarrousselProps> = props => {
  const [offsetRadius, setOffsetRadius] = useState<number>(2)
  const [showArrows, setShowArrows] = useState<boolean>(false)
  const [goToSlide, setGoToSlide] = useState<number | undefined>(undefined) // Change to number | undefined

  const cards = props.cards.map((element, index) => ({
    ...element,
    onClick: () => setGoToSlide(index)
  }))

  useEffect(() => {
    setOffsetRadius(props.offset)
    setShowArrows(props.showArrows)
  }, [props.offset, props.showArrows])

  return (
    <div className='m-0 w-full lg:h-600 md:h-500 sm:h-400 h-300'>
      <Carousel
        slides={cards}
        goToSlide={goToSlide}
        offsetRadius={offsetRadius}
        showNavigation={showArrows}
        animationConfig={config.gentle}
      />
    </div>
  )
}

export default Carroussel
