import React, { useEffect, useRef } from 'react'
import gsap from 'gsap-trial'

import './style.css'

const awards = [
  { src: '/assets/client/award3.jpg', alt: 'Award 1', key: 3 },
  { src: '/assets/client/award2.png', alt: 'Award 2', key: 2 },
  { src: '/assets/client/award1.png', alt: 'Award 3', key: 1 }
]

const ImageCarousel: React.FC = () => {
  const imagesRef = useRef<HTMLDivElement[]>([]) // Ref to hold image elements

  useEffect(() => {
    const images = gsap.utils.toArray<HTMLDivElement>('.img-wrapper') // Get all images

    // Set initial transforms
    gsap.set(images, { perspective: 1000, rotationY: -25 })

    images.forEach((image, i) => {
      const siblingsLeft = images.slice(i + 1)
      const siblingsRight = images.slice(0, i)

      const handleMouseEnter = () => {
        // Animate the hovered image
        gsap.to(image, { rotationY: 0, scale: 1.15, x: 0, duration: 0.3, overwrite: true })

        // Animate siblings to the left
        if (siblingsLeft.length) {
          gsap.to(siblingsLeft, { x: -300, rotationY: -25, duration: 0.3, scale: 1, overwrite: true })
        }

        // Animate siblings to the right
        if (siblingsRight.length) {
          gsap.to(siblingsRight, { x: 50, rotationY: -25, duration: 0.3, scale: 1, overwrite: true })
        }
      }

      const handleMouseLeave = () => {
        // Reset all images to normal
        gsap.to(images, { rotationY: -25, scale: 1, duration: 0.3, x: 0, overwrite: true })
      }

      // Add event listeners
      image.addEventListener('mouseenter', handleMouseEnter)
      image.addEventListener('mouseleave', handleMouseLeave)

      // Cleanup listeners on unmount
      return () => {
        image.removeEventListener('mouseenter', handleMouseEnter)
        image.removeEventListener('mouseleave', handleMouseLeave)
      }
    })
  }, [])

  return (
    <div className='image-carousel flex flex-row w-full relative'>
      {awards.map(item => (
        <div
          key={item.key}
          ref={el => (imagesRef.current[item.key] = el as HTMLDivElement)} // Type assertion for the ref
          id={`img${item.key}`}
          className='img-wrapper'
        >
          <img src={item.src}></img>
        </div>
      ))}
    </div>
  )
}

export default ImageCarousel
