import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import DarkModeSwitcher from './DarkModeSwitcher'
import RadiusButtonArrow from 'src/components/RadiusButtonArrow'
import { ReactComponent as LogoIcon } from 'src/assets/logo/logo.svg'

const Header = (props: { sidebarOpen: string | boolean | undefined; setSidebarOpen: (arg0: boolean) => void }) => {
  const [isHovered, setIsHovered] = useState(false)
  const [isScrolled, setIsScrolled] = useState(false)
  const [lastScrollY, setLastScrollY] = useState(0) // Track last scroll position

  const onNavigate = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY

      if (currentScrollY > lastScrollY && currentScrollY > 50) {
        setIsScrolled(false)
      } else if (currentScrollY < lastScrollY) {
        setIsScrolled(true)
      }

      setLastScrollY(currentScrollY)
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [lastScrollY])

  return (
    <div
      className={` w-full z-20 transition-all duration-300 ${
        isScrolled ? 'fixed dark:bg-black bg-white shadow-custom' : 'absolute bg-transparent'
      }`}
    >
      <div
        id='header'
        className='flex flex-grow detail-container items-center justify-between px-5 py-4 md:px-6 2xl:px-11'
      >
        <Link className='flex items-center gap-2 sm:gap-4' to='/' onClick={onNavigate}>
          <LogoIcon />
          <span className='hidden lg:flex items-center gap-1 group hover:dark:text-prime hover:text-prime'>
            <p className='text-3xl dark:text-white text-black group-hover:text-prime font-bodbug'>SilverDigitalBus</p>
          </span>
        </Link>
        <div className='flex items-center md:gap-3 lg:gap-3'>
          <ul className='flex items-center gap-6 md:gap-8 lg:gap-12'>
            <DarkModeSwitcher />
            <RadiusButtonArrow title='Get In Touch' className='hidden md:flex font-archivo' to='/contact' />
            <button
              aria-controls='sidebar'
              onClick={e => {
                e.stopPropagation()
                props.setSidebarOpen(!props.sidebarOpen)
              }}
              className='z-99999 block'
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <svg
                width='63'
                height='44'
                viewBox='0 0 63 44'
                xmlns='http://www.w3.org/2000/svg'
                className={`fill-current dark:text-white ${
                  isHovered ? 'animatecss animatecss-jello animatecss-infinite' : ''
                }`}
              >
                <line y1='15.25' x2='63' y2='15.25' stroke='currentColor' strokeWidth='1.5' />
                <line y1='27.25' x2='41' y2='27.25' stroke='currentColor' strokeWidth='1.5' />
              </svg>
            </button>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Header
