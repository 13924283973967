import { createBrowserRouter } from 'react-router-dom'

import Layout from 'src/layout'

import path from 'src/constants/path'
import Home from 'src/pages/Home'
import Project from 'src/pages/Projects'
import Skill from 'src/pages/Skill'
import FeedBack from 'src/pages/FeedBack'
import Team from 'src/pages/Team'
import Contact from 'src/pages/Contact'
import Details from 'src/pages/Details'

const routes = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        path: path.HOME,
        element: <Home />
      },
      {
        path: path.PORJECTS,
        element: <Project />
      },
      {
        path: path.Skill,
        element: <Skill />
      },
      {
        path: path.FEEDBACK,
        element: <FeedBack />
      },
      {
        path: path.TEAM,
        element: <Team />
      },
      {
        path: path.CONTACT,
        element: <Contact />
      },
      {
        path: path.DETAILS,
        element: <Details />
      }
    ]
  }
])

export default routes
