import React from 'react'
import Tilt from 'react-parallax-tilt'
import { firstPart } from 'src/types'

interface Overview {
  firstPart: firstPart
}

const ProcessOverView: React.FC<Overview> = ({ firstPart }) => {
  return (
    <div className='overflow-x-hidden relative w-full flex flex-row py-50 border-b gap-50 border-silverMedal dark:border-customGray max-md:items-center max-md:flex-col '>
      <img src='/assets/gif/Loop 3 Dark.gif' className='absolute hidden dark:block top-0 -left-1/3  -z-20' />
      <img src='/assets/gif/Loop 3 Light.gif' className='absolute block dark:hidden top-0 -left-1/3  -z-20' />
      <h3 className='flex w-40p text-black font-archivo text-18 dark:text-white font-normal max-md:w-full '>
        {firstPart.firstPartTitle}
      </h3>
      <div className='w-60p flex flex-col gap-12 max-md:w-full'>
        <div className='flex flex-col gap-10'>
          {firstPart.firstPartContent.map((item, index) => (
            <p key={index} className=' text-black font-archivo text-14 dark:text-white'>
              {item}
            </p>
          ))}
        </div>
        {firstPart.firstPartImage.map((item, index) => (
          <Tilt key={index} tiltMaxAngleX={2} tiltMaxAngleY={2} perspective={300} className='flex justify-center'>
            <img
              src={item}
              className='group-hover:opacity-75 h-415 object-cover border border-whiteGrey rounded-3xl  transtion ease-in-out duration-500 cursor-pointer hover:opacity-80'
            />
          </Tilt>
        ))}
      </div>
    </div>
  )
}

export default ProcessOverView
