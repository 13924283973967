import React from 'react'
import { RouterProvider } from 'react-router-dom'
import { Toaster } from 'sonner'

import routes from './routes'
import { TransitionProvider } from './context/TransitionContext'
import TransitionLayout from './animation/TransitionLayout'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './styles/index.scss'

const App: React.FC = () => {
  return (
    <TransitionProvider>
      <TransitionLayout>
        <RouterProvider router={routes} />
        <Toaster position='top-right' expand={false} />
      </TransitionLayout>
    </TransitionProvider>
  )
}

export default App
