import React, { useEffect } from 'react'

import ScaleInOut from 'src/animation/ScaleInOut'
import Lettering from 'src/animation/Lettering'
import RotateInOut3D from 'src/animation/RotateInOut3D'
import ImplodeExplodeInOut from 'src/animation/ImplodeExplodeInOut'

import CardItem from 'src/components/CardItem'
import ScrollToTop from 'src/components/ScrollToTop'
import Carroussel from 'src/components/CarouselSlider'
import { SmoothScrollHero } from 'src/components/SmoothScrollHero'
import ScrollDown from 'src/components/ScrollDown'

const cards = [
  {
    key: 0,
    content: <CardItem imagen='/assets/resource/squad1.png' />
  },
  {
    key: 1,
    content: <CardItem imagen='/assets/resource/squad2.png' />
  },
  {
    key: 2,
    content: <CardItem imagen='/assets/resource/squad3.png' />
  }
]

const Team: React.FC = () => {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 500)
  }, [])

  return (
    <>
      <div className='overflow-x-hidden px-5 py-4 md:px-12 md:pt-8 md:pb-40 relative overflow-hidden'>
        <ScaleInOut delay={0.5}>
          <img
            src='/assets/gif/Loop 3 Dark.gif'
            className='absolute hidden dark:block -top-52 left-1/4 max-md:left-0 max-sm:-top-20 -z-20'
          />
          <img
            src='/assets/gif/Loop 3 Light.gif'
            className='absolute block dark:hidden -top-52 left-1/4 max-md:left-0 max-sm:-top-20 -z-20'
          />
        </ScaleInOut>
        <div className='flex flex-col items-start gap-10 mt-48 detail-container'>
          <ScaleInOut delay={1}>
            <p className='text-50 text-start dark:text-white text-black font-normal md:tracking-wider font-bruno'>
              About The Squad.
            </p>
          </ScaleInOut>
          <div className='gap-6 mt-4 md:mt-10 md:grid md:grid-cols-2 md:items-center md:justify-between'>
            <ImplodeExplodeInOut target='#team-decription'>
              <div id='team-decription' className='dark:text-white text-black text-14 font-archivo'>
                <Lettering
                  text={`Based in the vibrant heart of Creative Persona. Silver Digital Bus is not just a digital agency; we're your partners in creativity.`}
                />
              </div>
            </ImplodeExplodeInOut>
            <ScrollDown />
          </div>
        </div>
        <RotateInOut3D duration={1} delay={1} offsetY={100}>
          <Carroussel cards={cards} offset={2} showArrows={false} />
          <div className='py-12 md:py-24 detail-container'>
            <p className='text-18 dark:text-white text-black font-archivo'>
              We thrive on turning your wildest design aspirations into stunning realities. At Silver Digital Bus, every
              project is a collaboration, every client is a friend, and every design and development is a work of art
              crafted with care and enthusiasm.
            </p>
          </div>

          <div className='py-8 md:py-12 flex flex-col gap-8 detail-container'>
            <div className='flex flex-col gap-8 relative'>
              <img
                src='/assets/gif/Loop 1 Green Dark.gif'
                className='absolute hidden dark:block -top-96 left-1/2  -z-20'
              />
              <img
                src='/assets/gif/Loop 1 Green Light.gif'
                className='absolute block dark:hidden -top-96 left-1/2  -z-20'
              />
              <div className='flex flex-col gap-6 md:gap-8'>
                <p className='text-40 dark:text-white text-black font-bruno'>Our Squad</p>
                <div className='md:grid md:grid-cols-6'>
                  <div className='md:col-span-5 md:w-full lg:w-2/3 xl:w-2/3'>
                    <p className='text-14 dark:text-white text-black font-archivo'>
                      Our team is a tight-knit family of developers, designers, artists, and visionaries, all bound by
                      the same creative and software enthusiasm.
                    </p>
                  </div>
                  <div className='flex items-end mt-10 md:mt-0 md:col-span-1 md:justify-end font-archivo'>
                    <span className='hidden dark:flex font-archivo'>
                      <img
                        src='/assets/icon/arrowRightUp.png'
                        alt='Arrow Right Up'
                        className='w-4/5 h-4/5 md:w-full md:h-full object-cover '
                      />
                    </span>
                    <span className='flex dark:hidden font-archivo'>
                      <img
                        src='/assets/icon/arrowRightUpDark.png'
                        alt='Arrow Right Up Dark'
                        className='w-4/5 h-4/5 md:w-full md:h-full object-cover'
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <SmoothScrollHero />
        </RotateInOut3D>
      </div>
      <ScrollToTop />
    </>
  )
}

export default Team
