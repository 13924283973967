import React from 'react'
import classnames from 'classnames'
import { usePagination, DOTS } from 'src/context/usePagination' // Make sure to create/use this hook

interface PaginationProps {
  onPageChange: (page: number) => void
  totalCount: number
  pageSize: number
  siblingCount?: number
  currentPage: number
  className?: string
}

const Pagination: React.FC<PaginationProps> = ({
  onPageChange,
  totalCount,
  pageSize,
  siblingCount = 1,
  currentPage,
  className
}) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  })

  if (currentPage === 0 || paginationRange.length < 2) {
    return null
  }
  return (
    <ul
      className={classnames('pagination-container justify-center', {
        [className!]: className
      })}
    >
      {paginationRange.map((pageNumber, index) => {
        if (pageNumber === DOTS) {
          return (
            <li key={index} className='pagination-item dots dark:text-white dark:border-white'>
              &#8230;
            </li>
          )
        }

        return (
          <li
            key={pageNumber}
            className={classnames('pagination-item font-archivo dark:text-white dark:border-white transition-shadow ', {
              selected: pageNumber === currentPage
            })}
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </li>
        )
      })}
    </ul>
  )
}

export default Pagination
