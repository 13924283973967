import React from 'react'
import { Link } from 'react-router-dom'
import Tilt from 'react-parallax-tilt'

import { projectIntro } from 'src/types'

import FadeIn from 'src/animation/FadeIn'
import Lettering from 'src/animation/Lettering'
import ScaleInOut from 'src/animation/ScaleInOut'
import ImplodeExplodeInOut from 'src/animation/ImplodeExplodeInOut'

interface DetailLandingProps {
  projectIntro: projectIntro
  projectId: number
}

const DetailLanding: React.FC<DetailLandingProps> = ({ projectIntro, projectId }) => {
  return (
    <>
      <ScaleInOut delay={0.3}>
        <div className='pt-50 mt-20'>
          <Link className='font-archivo text-center text-14 text-whiteGrey dark:text-whiteGrey' to={'/projects'}>
            Projects /
          </Link>
          <span className='font-archivo text-center text-14 font-bold dark:text-white '>
            {' '}
            {` `}
            {projectIntro.projectTitle}
          </span>
        </div>
      </ScaleInOut>
      <div className='mt-8'>
        <ScaleInOut delay={0.6}>
          <h2 className='font-bruno text-50 text-blackhole font-normal  dark:text-white'>{projectIntro.title}</h2>
        </ScaleInOut>
        <ScaleInOut delay={0.9}>
          <p className='font-archivo text-14 text-prime font-normal'>{projectIntro.date}</p>
        </ScaleInOut>
      </div>
      <ImplodeExplodeInOut target={`.project-introduction-${projectId}`}>
        <div
          className={`project-introduction-${projectId} font-archivo text-14 text--blackhole font-normal dark:text-white`}
        >
          <Lettering text={projectIntro.introduction} />
        </div>
      </ImplodeExplodeInOut>

      <FadeIn duration={0.5} y={1200} delay={1.5}>
        <Tilt tiltMaxAngleX={1} tiltMaxAngleY={1} perspective={300}>
          <img
            src={projectIntro.projectImageSrc}
            alt='Detail Image'
            className='my-50 h-800 object-contain border border-darkRiver rounded-3xl cursor-pointer border-solid  imgage-back  w-full group-hover:opacity-85 transtion ease-in-out duration-500 hover:opacity-80'
          />
        </Tilt>
      </FadeIn>
    </>
  )
}

export default DetailLanding
