import React from 'react'
import { Link } from 'react-router-dom'

import { ReactComponent as LogoIcon } from 'src/assets/logo/logo.svg'

const Footer: React.FC = () => {
  const onNavigate = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
  return (
    <div id='footer' className='detail-container px-3 py-8 md:px-12 md:pt-24 md:pb-16 md:gap-3 relative z-10'>
      <div className='flex flex-col items-center md:grid md:grid-cols-4'>
        <div className='flex flex-col text-center gap-8 md:text-start md:col-span-2'>
          <Link className='flex justify-center md:justify-start items-center gap-2' to='/' onClick={onNavigate}>
            <LogoIcon />
            <span className='flex items-center gap-1 group hover:dark:text-prime hover:text-prime'>
              <p className='text-3xl dark:text-white text-black group-hover:text-prime font-bodbug'>SilverDigitalBus</p>
            </span>
          </Link>
          <span className='text-gray text-14 font-normal uppercase font-archivo'>
            Registered on
            <p className='dark:text-white text-black text-14 font-normal md:font-medium font-archivo'>
              2875 S Orange Ave STE 500 #6779, Orlando, FL, 31806-5471
            </p>
          </span>
          <Link
            to='mailto: contact@silverdigitalbus.io'
            className='dark:text-white text-black text-14 font-normal md:font-medium underline hover:text-prime hover:dark:text-prime font-archivo'
          >
            contact@silverdigitalbus.io
          </Link>
        </div>

        <div className='flex flex-col text-center mt-8 gap-6 md:text-start md:col-span-1 md:mt-0 md:gap-8 md:items-start'>
          <p className='text-gray text-18 font-normal uppercase font-archivo'>pages</p>
          <Link
            onClick={onNavigate}
            to='/'
            className='dark:text-white text-black text-14 font-medium uppercase hover:text-prime hover:dark:text-prime font-archivo'
          >
            Home
          </Link>
          <Link
            onClick={onNavigate}
            to='/projects'
            className='dark:text-white text-black text-14 font-medium uppercase hover:text-prime hover:dark:text-prime font-archivo'
          >
            Projects
          </Link>
          <Link
            onClick={onNavigate}
            to='/team'
            className='dark:text-white text-black text-14 font-medium uppercase hover:text-prime hover:dark:text-prime font-archivo'
          >
            About
          </Link>
        </div>

        <div className='flex flex-col text-center items-center mt-8 gap-6 md:text-start md:col-span-1 md:mt-0 md:gap-8 md:items-start'>
          <p className='text-gray text-18 font-normal uppercase font-archivo'>Utility Pages</p>
          <Link
            onClick={onNavigate}
            to='/contact'
            className='dark:text-white text-black text-14 font-medium uppercase hover:text-prime hover:dark:text-prime font-archivo'
          >
            Contact
          </Link>
          <Link
            onClick={onNavigate}
            to='/skill'
            className='dark:text-white text-black text-14 font-medium uppercase hover:text-prime hover:dark:text-prime font-archivo'
          >
            Skills
          </Link>
          <Link
            onClick={onNavigate}
            to='/feedback'
            className='dark:text-white text-black text-14 font-medium uppercase hover:text-prime hover:dark:text-prime font-archivo'
          >
            Feedback
          </Link>
        </div>
      </div>
      <div className='flex flex-col gap-12 mt-8 md:grid md:grid-cols-3 md:mt-24'>
        <span className='flex justify-center md:justify-start text-gray text-14 font-medium uppercase order-3 md:order-none font-archivo'>
          © 2024 Silver Digital Bus
        </span>
        <span className='flex justify-center gap-10 order-1 md:order-none'>
          <Link
            to='https://www.linkedin.com/company/silverdigitalbus'
            className='dark:text-white text-black text-14 font-normal uppercase hover:text-prime hover:dark:text-prime font-archivo'
          >
            LinkedIn
          </Link>
          <Link
            to='https://telegram.org/'
            className='dark:text-white text-black text-14 font-normal uppercase hover:text-prime hover:dark:text-prime font-archivo'
          >
            Telegram
          </Link>
          <Link
            to='https://discord.com/'
            className='dark:text-white text-black text-14 font-normal uppercase hover:text-prime hover:dark:text-prime font-archivo'
          >
            Discord
          </Link>
        </span>
        <span className='flex justify-center md:justify-end text-gray text-14 font-medium uppercase order-2 md:order-none'>
          <span className='flex flex-col text-center md:text-start font-archivo'>
            For assistance you may call us at:
            <span className='dark:text-white text-black font-archivo'>ORLANDO, FL 32806</span>
          </span>
        </span>
      </div>
    </div>
  )
}

export default Footer
