import React, { useRef, useContext, ReactNode } from 'react'
import { gsap } from 'gsap-trial'
import useIsomorphicLayoutEffect from './useIsomorphicLayoutEffect'
import { TransitionContext } from '../context/TransitionContext'

interface Props {
  children: ReactNode
  from: any
  to: any
  durationIn: number
  durationOut: number
  delay: number
  delayOut?: number
  set?: any
  skipOutro?: boolean
  className?: string
}

const AnimateInOut = ({
  children,
  from,
  to,
  durationIn,
  durationOut,
  delay,
  delayOut,
  set,
  skipOutro,
  className
}: Props) => {
  const { timeline } = useContext(TransitionContext)
  const el = useRef<HTMLDivElement>(null)

  useIsomorphicLayoutEffect(() => {
    // intro animation
    if (set) {
      gsap.set(el.current, { ...set })
    }
    gsap.to(el.current, {
      ...to,
      delay: delay || 0,
      duration: durationIn
    })

    // outro animation
    if (!skipOutro) {
      timeline.add(
        gsap.to(el.current, {
          ...from,
          delay: delayOut || 0,
          duration: durationOut
        }),
        0
      )
    }
  }, [])

  return (
    <div style={from} ref={el} className={className}>
      {children}
    </div>
  )
}

export default React.memo(AnimateInOut)
