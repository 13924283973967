import React from 'react'
import Tilt from 'react-parallax-tilt'
import { secondPart } from 'src/types'

interface DesignProps {
  secondPart: secondPart
}

const Design: React.FC<DesignProps> = ({ secondPart }) => {
  return (
    <div className='w-full flex flex-col py-50 gap-50 border-b border-silverMedal dark:border-customGray '>
      <div className=' flex flex-row gap-12 w-full max-md:flex-col max-md:items-center'>
        <h3 className='flex w-40p text-black font-archivo font-normal text-18 dark:text-white max-md:w-full'>
          {secondPart.secondPartTitle}
        </h3>
        <div className='flex flex-col gap-10 w-60p max-md:w-full'>
          {secondPart.secondPartContent.map((item, index) => (
            <p key={index} className=' text-black font-archivo text-14 dark:text-white'>
              {item}
            </p>
          ))}
        </div>
      </div>
      <div className='flex flex-wrap justify-center lg:justify-between '>
        {secondPart.secondPartImage.map((item, index) => (
          <Tilt key={index} tiltMaxAngleX={7} tiltMaxAngleY={7} perspective={600}>
            <img
              src={item}
              className=' mb-4 h-250 w-[430px] object-cover border border-whiteGrey rounded-3xl max-sm:justify-center group-hover:opacity-85 transtion ease-in-out duration-300  hover:opacity-80 cursor-pointer'
            />
          </Tilt>
        ))}
      </div>
    </div>
  )
}

export default Design
