import React, { useEffect, useRef } from 'react'
import Slider from 'react-slick'

import gsap from 'gsap-trial'
import ScrollTrigger from 'gsap-trial/dist/ScrollTrigger'

import Lettering from 'src/animation/Lettering'
import { randomNumber } from 'src/animation/util'

import feedbacks from 'src/constants/feedback'
import RadiusButton from 'src/components/RadiusButton'

import { ReactComponent as QuoteUpIcon } from 'src/assets/icon/quoteup.svg'
import { ReactComponent as SmallStarIcon } from 'src/assets/icon/smallStar.svg'

const feedbackSettings = {
  centerMode: true,
  centerPadding: '20%',
  infinite: true,
  slidesToShow: 1
}

gsap.registerPlugin(ScrollTrigger)

const LandingFeedBack: React.FC = () => {
  const textRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: '#landingFeedback',
          start: 'top 30%',
          end: 'top bottom',
          scrub: 3
        }
      })
      .fromTo(
        '.feedback-title',
        {
          y: -200,
          opacity: 0,
          duration: 0.25,
          ease: 'power4.in'
        },
        {
          opacity: 1,
          delay: 0,
          duration: 1,
          y: 0,
          ease: 'bounce.out'
        }
      )
    gsap
      .timeline({
        scrollTrigger: {
          trigger: '#landingFeedback',
          start: 'top 30%',
          end: 'top bottom',
          scrub: 3
        }
      })
      .fromTo(
        '.feedback-button',
        {
          y: -200,
          opacity: 0,
          duration: 0.25,
          ease: 'power4.in'
        },
        {
          opacity: 1,
          delay: 2,
          duration: 1,
          y: 0,
          ease: 'bounce.out'
        }
      )
    gsap
      .timeline({
        scrollTrigger: {
          trigger: '#landingFeedback',
          start: 'top 30%',
          end: 'top bottom',
          scrub: 3
        }
      })
      .fromTo(
        '#leftstar',
        {
          y: -200,
          opacity: 0,
          duration: 0.25,
          ease: 'power4.in'
        },
        {
          opacity: 1,
          delay: 3,
          duration: 1,
          y: 0,
          ease: 'bounce.out'
        }
      )
    gsap
      .timeline({
        scrollTrigger: {
          trigger: '#landingFeedback',
          start: 'top 30%',
          end: 'top bottom',
          scrub: 3
        }
      })
      .fromTo(
        '#rightstar',
        {
          y: -200,
          opacity: 0,
          duration: 0.25,
          ease: 'power4.in'
        },
        {
          opacity: 1,
          delay: 4,
          duration: 1,
          y: 0,
          ease: 'bounce.out'
        }
      )
    gsap
      .timeline({
        scrollTrigger: {
          trigger: '#landingFeedback',
          start: 'top 30%',
          end: 'top bottom',
          scrub: 3
        }
      })
      .fromTo(
        '#feedback',
        {
          y: -200,
          opacity: 0,
          duration: 0.25,
          ease: 'power4.in'
        },
        {
          opacity: 1,
          delay: 6,
          duration: 2,
          y: 0,
          ease: 'bounce.out'
        }
      )

    const timeine1 = gsap.timeline({
      scrollTrigger: {
        trigger: '#landingFeedback',
        start: 'top 30%',
        end: 'top bottom',
        scrub: 1
      }
    })

    const chars1 = document.querySelector('.feedback-description')?.childNodes || []

    chars1.forEach(char => {
      timeine1.fromTo(
        char,
        {
          x: randomNumber(-2000, 2000),
          y: randomNumber(-1000, 1000),
          z: randomNumber(100, 100),
          opacity: 0,
          rotation: randomNumber(360, 720),
          rotationX: randomNumber(-360, 360),
          rotationY: randomNumber(-360, 360),
          ease: 'power4.out'
        },
        {
          x: 0,
          y: 0,
          z: 0,
          rotation: 0,
          rotationX: 0,
          rotationY: 0,
          opacity: 1,
          duration: 1,
          delay: Math.random() * 0.5,
          ease: 'power4.out'
        }
      )
    })

    gsap.set(textRef.current, {
      opacity: 1
    })
  }, [])

  return (
    <div id='landingFeedback'>
      <div className='mt-40 relative dark:bg-custom-black bg-customWhiteGray bg-opacity-30'>
        <div>
          <div className='px-5 py-12 md:px-12 md:py-40 md:gap-28 relative grid grid-cols-3 detail-container'>
            <span ref={textRef} id='title' className='w-full col-span-3 md:col-span-2'>
              <p className='feedback-title text-50 dark:text-white text-black font-normal md:tracking-wider font-bruno'>
                FeedBack
              </p>
              <p className='feedback-description text-14 mt-6 dark:text-white text-black font-normal font-archivo'>
                <Lettering
                  text='Read through our testimonials to see why our clients love working with us and how we can help you
                achieve your business goals through creative and effective design.'
                />
              </p>
            </span>
            <span className='feedback-button relative col-span-3 md:col-span-1 flex justify-end items-start'>
              <RadiusButton
                title='SEE ALL FEEDBACK'
                className='w-full mt-6 md:w-auto md:mt-10 font-archivo font-semibold transition-all duration-500 ease-in-out filter hover:grayscale-0 lg:grayscale-[70%] bg-illicitGreen text-black dark:border-black px-6 py-4'
                to='/feedback'
              />
            </span>
          </div>

          <div id='feedback' className='slider-container pb-12 mb:pb-52'>
            <Slider {...feedbackSettings}>
              {feedbacks.slice(0, 3).map((item, index) => (
                <div
                  key={index}
                  className='!flex flex-col items-start p-5 gap-5 md:gap-10 md:p-10 dark:bg-custom-gray bg-white feedback-card'
                >
                  <div className='flex justify-between items-start self-stretch'>
                    <div className='flex items-end gap-4'>
                      <div className='w-16 h-16 rounded-full overflow-hidden'>
                        <img src={item.img} />
                      </div>
                      <div className='flex flex-col items-start gap-1'>
                        <p className='text-18 font-medium dark:text-white text-black font-archivo'>{item.name}</p>
                        <p className='text-14 font-normal text-gray font-archivo'>{item.title}</p>
                      </div>
                    </div>
                    <QuoteUpIcon />
                  </div>
                  <p className='text-14 italic font-normal dark:text-white text-black font-archivo'>
                    {item.description}
                  </p>
                </div>
              ))}
            </Slider>
          </div>
        </div>

        <span
          id='leftstar'
          className='absolute col-span-3 md:col-span-1'
          style={{
            left: '10%',
            top: '38%'
          }}
        >
          <SmallStarIcon className='boaming-image' />
        </span>
        <span
          id='rightstar'
          className='hidden absolute md:block'
          style={{
            top: '18%',
            right: '0%'
          }}
        >
          <SmallStarIcon className='boaming-image' />
        </span>
      </div>
    </div>
  )
}
export default LandingFeedBack
