import React from 'react'
import Tilt from 'react-parallax-tilt'

import RadiusButtonArrow from 'src/components/RadiusButtonArrow'
import RadiusButton from 'src/components/RadiusButtonArrow'

import { Project } from 'src/types'

interface ViewMoreProps {
  projects: Project[]
}

const ViewMore: React.FC<ViewMoreProps> = ({ projects }) => {
  return (
    <div className='w-full flex flex-col py-50 gap-50 '>
      <div className=' flex flex-row gap-12 w-full justify-between'>
        <h3 className='flex w-40p text-black font-archivo font-normal text-24 dark:text-white max-md:w-full max-md:justify-center '>
          More of our projects
        </h3>
        <RadiusButton
          title='See more'
          className='w-45 h-14 hover:cursor-pointer max-lg:hidden font-archivo group-hover:opacity-75 scale-95 transtion ease-in-out duration-300 hover:scale-100 '
          to={`/projects`}
        />
      </div>
      <div className='flex flex-row items-center justify-between gap-50 max-lg:flex-col'>
        {projects.slice(0, 2).map((project, index) => (
          <div key={index}>
            <div className='relative w-full h-100 md:h-100 lg:h-110 xl:h-120 overflow-hidden rounded-xl'>
              <Tilt tiltMaxAngleX={5} tiltMaxAngleY={5} perspective={600}>
                <img
                  src={project.imageSrc}
                  className='h-full w-full object-cover object-center rounded-xl group-hover:opacity-75 transtion ease-in-out duration-300 cursor-pointer hover:opacity-80'
                  alt={project.name}
                />
              </Tilt>
              <div className='absolute top-5 left-5 flex flex-wrap gap-2'>
                {project.tags.map((tagName, index) => (
                  <div key={index} className='flex px-3 py-1 justify-center items-center tag'>
                    <p className='text-white text-14 font-archivo'>{tagName}</p>
                  </div>
                ))}
              </div>
            </div>
            <div className='flex flex-col flex-grow'>
              <h3 className='mt-6 text-18 dark:text-white text-black font-archivo'>{project.name}</h3>
              <p className='mt-2 text-14 text-contactTitle font-archivo'>{project.description}</p>
              <div className='mt-auto'>
                <RadiusButtonArrow
                  title='VIEW'
                  className='mt-6 w-24 font-archivo group-hover:opacity-75 scale-95 transtion ease-in-out duration-300 hover:scale-100'
                />
              </div>
            </div>
          </div>
        ))}
      </div>
      <RadiusButton title='See more' className='viewDetails w-full h-14 lg:hidden font-archivo' to={`/projects`} />
    </div>
  )
}

export default ViewMore
