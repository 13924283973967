import React, { useEffect } from 'react'
import Hero from './Hero'
import AboutUs from './AboutUs'
import Newest from './Newest'
import PrimaryDevSkill from './PrimaryDevSkill'
import ContentCard from './ContentCard'
import Process from './Process'
import LandingFeedBack from './LandingFeedBack'

import ScrollToTop from 'src/components/ScrollToTop'
import ReactLenis from 'lenis/dist/lenis-react'

const Home: React.FC = () => {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 500)
  }, [])

  return (
    <div className='overflow-hidden relative'>
      <ReactLenis
        root
        options={{
          lerp: 0.2
        }}
      >
        <Hero />
        <AboutUs />
        <Newest />
        <PrimaryDevSkill />
        <ContentCard />
        <Process />
        <LandingFeedBack />
        <ScrollToTop />
      </ReactLenis>
    </div>
  )
}

export default Home
