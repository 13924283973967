import React from 'react'

interface CustomProgressDotsProps {
  currentSlide: number
  slideCount: number
}

const CustomProgressDots: React.FC<CustomProgressDotsProps> = ({ currentSlide, slideCount }) => {
  return (
    <div className='flex justify-center w-360 h-1 custom-progress'>
      {Array.from({ length: slideCount }).map((_, index) => (
        <div
          key={index}
          className={`w-97 h-1 transition-all duration-300 dark:bg-gray `}
          style={{
            left: currentSlide === index ? `${90 * (currentSlide + 1)}px` : '0px',
            backgroundColor: currentSlide === index ? '#00FFFF' : '#242525'
          }}
        />
      ))}
    </div>
  )
}

export default CustomProgressDots
