import { FC, useEffect, useState } from 'react'

const ScrollToTop: FC = () => {
  const [isVisible, setIsVisible] = useState(false)
  const [isHovered, setIsHovered] = useState(false)

  const handleScroll = () => {
    if (window.scrollY > 860) {
      setIsVisible(true)
    } else {
      setIsVisible(false)
    }
  }

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  if (!isVisible) return <></>

  return (
    <button
      onClick={scrollToTop}
      className='fixed z-30 bottom-5 right-3 border-none rounded-lg p-2 cursor-pointer text-2xl flex items-center'
    >
      <div
        className='flex p-3 rounded-full border dark:border-white border-black mt-3 md:mt-3'
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={scrollToTop}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='20'
          height='20'
          viewBox='0 0 20 20'
          fill='currentColor'
          className={`fill-current dark:text-white ${
            isHovered ? 'animatecss animatecss-bounce animatecss-infinite animate-down' : ''
          }`}
        >
          <path
            d='M4.375 8.75L10 3.125L15.625 8.75'
            stroke='currentColor'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M10 16.875L10 3.125'
            stroke='currentColor'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      </div>
    </button>
  )
}

export default ScrollToTop
