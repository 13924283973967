import IconButton from 'src/components/IconButton'

interface techStacks {
  lang: string[]
  icons: React.FC<React.SVGProps<SVGSVGElement>>[]
  description: string
}

interface TechStackProps {
  techstacks: techStacks
}

const TechStack: React.FC<TechStackProps> = ({ techstacks }) => {
  return (
    <div className='flex max-md:flex-col gap-30 pb-50 border-b border-silverMedal  dark:border-jet mb-50 '>
      <h2 className='w-40 max-md:w-full font-archivo text-blackhole font-normal text-18 dark:text-white'>
        Tech Stacks
      </h2>
      <div className='w-53 max-md:w-full flex flex-col gap-30'>
        <div className='flex flex-wrap gap-16 w-96'>
          {techstacks.icons.map((IconComponent, index) => (
            <div key={index} className='text-[20px]'>
              <IconButton
                title={techstacks.lang[index]}
                icon={<IconComponent />}
                darkIcon={<IconComponent />}
                className='iconButton font-archivo'
              />
            </div>
          ))}
        </div>
        <div className='font-archivo text-black text-14 dark:text-whiteGrey'>{techstacks.description}</div>
      </div>
    </div>
  )
}

export default TechStack
