import { useMemo } from 'react'

export const DOTS = '...'

type PaginationItem = number | typeof DOTS // Define a union type for pagination items

interface UsePaginationProps {
  currentPage: number
  totalCount: number
  pageSize: number
  siblingCount?: number
}

const range = (start: number, end: number) => {
  return [...Array(end - start + 1)].map((_, i) => start + i)
}

export const usePagination = ({
  currentPage,
  totalCount,
  pageSize,
  siblingCount = 1
}: UsePaginationProps): PaginationItem[] => {
  // Specify the return type
  const totalPages = Math.ceil(totalCount / pageSize)

  const paginationRange = useMemo(() => {
    if (totalPages <= 1) return [1]

    const firstPage = 1
    const lastPage = totalPages

    // If the number of pages is less than 2 * siblingCount + 3, return all pages
    if (totalPages < 2 * siblingCount + 3) {
      return range(1, totalPages)
    }

    const startPage = Math.max(currentPage - siblingCount, 2)
    const endPage = Math.min(currentPage + siblingCount, totalPages - 1)

    const hasLeftDots = startPage > 2
    const hasRightDots = totalPages - endPage > 1

    const leftSibling = hasLeftDots ? [DOTS] : ([] as PaginationItem[]) // Ensure it's treated as PaginationItem[]
    const rightSibling = hasRightDots ? [DOTS] : ([] as PaginationItem[]) // Ensure it's treated as PaginationItem[]

    const middlePages = range(startPage, endPage)

    return [firstPage, ...leftSibling, ...middlePages, ...rightSibling, lastPage] as PaginationItem[]
  }, [currentPage, totalPages, siblingCount])

  return paginationRange
}
