import { ReactNode } from 'react'
import AnimateInOut from './AnimateInOut'

interface Props {
  children: ReactNode
  delay?: number
  y?: number
  durationIn?: number
  durationOut?: number
  from?: any
}
const FadeInOutUp = ({ children, delay, y, durationIn, durationOut, from }: Props) => (
  <AnimateInOut
    durationIn={durationIn || 2}
    durationOut={durationOut || 0.25}
    delay={delay || 1}
    from={{
      ...from,
      transform: 'translate(0px, ' + (y || 40) + 'px)',
      opacity: 0,
      duration: 0.25,
      ease: 'power4.out'
    }}
    to={{
      opacity: 1,
      y: 0,
      ease: 'power4.out',
      delay: 0.5
    }}
  >
    {children}
  </AnimateInOut>
)

export default FadeInOutUp
