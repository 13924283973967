import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import RotateInOut3D from 'src/animation/RotateInOut3D'
import ScaleInOut from 'src/animation/ScaleInOut'

const Contact: React.FC = () => {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 500)
  }, [])
  return (
    <div className='px-5 py-4 md:px-12 md:pt-8 md:pb-40 detail-container overflow-x-hidden w-full relative'>
      <ScaleInOut delay={0.3} className='absolute -top-72 left-1/4 max-md:left-0 max-sm:-top-20 -z-20'>
        <img src='/assets/gif/Loop 3 Dark.gif' className='dark:block hidden' />
        <img src='/assets/gif/Loop 3 Light.gif' className='dark:hidden block' />
      </ScaleInOut>
      <ScaleInOut delay={0.6} className='absolute top-40 max-md:top-1/2 left-1/2 -z-30 -rotate-90'>
        <img src='/assets/gif/Loop 2 Dark.gif' className='dark:block hidden' />
        <img src='/assets/gif/Loop 2 Light.gif' className='dark:hidden block' />
      </ScaleInOut>

      <ScaleInOut delay={0.9}>
        <div className='flex flex-col items-start gap-10 md:flex-row md:items-center md:self-stretch '>
          <p className='text-50 text-center dark:text-white text-black font-normal md:tracking-wider font-bruno mt-28'>
            Contact
          </p>
        </div>
      </ScaleInOut>

      <RotateInOut3D duration={1} delay={1} offsetY={100}>
        <div className='md:mt-8 md:grid md:grid-cols-9 md:gap-10 xl:gap-24'>
          <div className='md:col-span-5 flex flex-col justify-between'>
            <form action='#' method='POST' className='flex flex-col gap-6'>
              <div className='flex flex-col items-start gap-4'>
                <label htmlFor='name' className='block text-14 dark:text-white text-black font-archivo'>
                  Name
                </label>
                <input
                  id='name'
                  name='name'
                  type='text'
                  autoComplete='organization'
                  placeholder='Your Name'
                  className='block w-full px-5 py-3 text-14 tracking-widest rounded-100 border dark:border-radiusButtonBorderColorWhite border-radiusButtonBorderColorBlack dark:text-white text-black hover:border-black hover:dark:border-white focus:dark:border-white focus:border-black  dark:placeholder:text-white placeholder:text-black dark:bg-black bg-white focus:outline-none font-archivo'
                />
              </div>
              <div className='flex flex-col items-start gap-4'>
                <label htmlFor='email' className='block text-14 dark:text-white text-black font-archivo'>
                  Email
                </label>
                <input
                  id='email'
                  name='email'
                  type='text'
                  autoComplete='organization'
                  placeholder='Your Email'
                  className='block w-full px-5 py-3 text-14 tracking-widest rounded-100 border dark:border-radiusButtonBorderColorWhite border-radiusButtonBorderColorBlack dark:text-white text-black hover:border-black hover:dark:border-white focus:dark:border-white focus:border-black  dark:placeholder:text-white placeholder:text-black dark:bg-black bg-white focus:outline-none font-archivo'
                />
              </div>
              <div className='flex flex-col items-start gap-4'>
                <label htmlFor='message' className='block text-14 dark:text-white text-black font-archivo'>
                  Message
                </label>
                <textarea
                  id='message'
                  name='message'
                  rows={15}
                  placeholder='Tell us about your brilliant ideas here...'
                  className='block w-full px-5 py-5 text-14 tracking-widest rounded-25 border dark:border-radiusButtonBorderColorWhite border-radiusButtonBorderColorBlack dark:text-white text-black hover:border-black hover:dark:border-white focus:dark:border-white focus:border-black  dark:placeholder:text-white placeholder:text-black dark:bg-black bg-white focus:outline-none font-archivo'
                />
              </div>
              <button
                type='submit'
                className='flex w-2/5 px-4 py-3 justify-center items-center gap-2 rounded-100 bg-darkblue text-white text-14 font-semibold mt-auto font-archivo'
              >
                SUBMIT
              </button>
            </form>
          </div>

          <div className='md:col-span-4 flex flex-col y-12 md:my-0 md:h-full'>
            <div className='flex flex-col gap-5 md:h-full'>
              <p className='text-18 dark:text-white text-black font-archivo'>
                Send us more about your brilliant projects:
              </p>
              <div className='flex flex-col gap-5 p-6 bg-contactBgLight dark:bg-contactBgDark rounded-2xl'>
                <p className='text-14 text-contactTitle font-archivo'>Email:</p>
                <Link
                  to='mailto: contact@silverdigitalbus.io'
                  className='text-14 dark:text-white text-black underline break-all hover:dark:text-prime hover:text-prime cursor-pointer font-archivo'
                >
                  contact@silverdigitalbus.io
                </Link>
              </div>

              {/* Automatically adjusting gap between these two elements */}
              <div className='flex flex-col gap-5 p-6 bg-contactBgLight dark:bg-contactBgDark rounded-2xl'>
                <p className='text-14 text-contactTitle font-archivo'>Address:</p>
                <p className='text-14 dark:text-white text-black font-archivo'>ORLANDO, FL 32806</p>
              </div>

              <div className='flex flex-col gap-5 p-6 bg-contactBgLight dark:bg-contactBgDark rounded-2xl'>
                <p className='text-14 text-contactTitle font-archivo'>Follow our socials:</p>
                <Link
                  to='https://www.linkedin.com/company/silverdigitalbus'
                  className='text-14 dark:text-white text-black underline hover:dark:text-prime hover:text-prime cursor-pointer font-archivo'
                >
                  LinkedIn
                </Link>
                <Link
                  to='https://telegram.org/'
                  className='text-14 dark:text-white text-black underline hover:dark:text-prime hover:text-prime cursor-pointer font-archivo'
                >
                  Telegram
                </Link>
                <Link
                  to='https://discord.com/'
                  className='text-14 dark:text-white text-black underline hover:dark:text-prime hover:text-prime cursor-pointer font-archivo'
                >
                  Discord
                </Link>
              </div>
            </div>
          </div>
        </div>
      </RotateInOut3D>
    </div>
  )
}

export default Contact
