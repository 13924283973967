import { useState, createContext, ReactNode } from 'react'
import gsap from 'gsap-trial'

export type LoadinValueType = {
  background: string
  timeline: gsap.core.Timeline
  completed: boolean
  setBackground: (value: string) => void
  setTimeline: (value: gsap.core.Timeline) => void
  toggleCompleted: (value: boolean) => void
}

const defaultProvider: LoadinValueType = {
  background: 'transparent',
  timeline: gsap.timeline({ paused: true }),
  setBackground: () => String,
  setTimeline: () => gsap.core.Timeline,
  completed: false,
  toggleCompleted: () => Boolean
}

interface Props {
  children: ReactNode
}

const TransitionContext = createContext(defaultProvider)

const TransitionProvider = ({ children }: Props) => {
  const [completed, setCompleted] = useState(false)
  const [timeline, setTimeline] = useState<gsap.core.Timeline>(() => gsap.timeline({ paused: true }))

  const [background, setBackground] = useState('transparent')

  const toggleCompleted = (value: boolean) => {
    setCompleted(value)
  }

  return (
    <TransitionContext.Provider
      value={{
        timeline,
        setTimeline,
        background,
        setBackground,
        completed,
        toggleCompleted
      }}
    >
      {children}
    </TransitionContext.Provider>
  )
}

export { TransitionContext, TransitionProvider }
