import { useState, useEffect } from 'react'
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react'

import feedbacks from 'src/constants/feedback'

import FadeIn from 'src/animation/FadeIn'
import ScaleInOut from 'src/animation/ScaleInOut'

import Pagination from 'src/components/Pagination'
import ImageCarousel from 'src/components/ImageCarousel'

const FeedBack = () => {
  const [search, setSearch] = useState<string>('')
  const [filteredFeedbacks, setFilteredFeedbacks] = useState(feedbacks)

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1)
  const pageSize = 3 // Number of feedbacks per page

  const onSearch = (value: string) => {
    setSearch(value)
    const filtered = feedbacks.filter(feedback => feedback.stack.toLowerCase().indexOf(value.toLowerCase()) !== -1)
    setFilteredFeedbacks(filtered)
  }

  const filterFeedbacks = (value: string) => {
    if (value === 'NEWEST' || value === 'Newest' || !value) {
      setFilteredFeedbacks(feedbacks)
    } else {
      const filtered = feedbacks.filter(feedback => feedback.stack.toLowerCase() === value.toLowerCase())
      setFilteredFeedbacks(filtered)
    }
  }

  const handleFilter = (stack: string) => {
    if (stack) {
      filterFeedbacks(stack)
    } else {
      setFilteredFeedbacks(feedbacks)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 500)
  }, [])

  const paginatedFeedbacks = filteredFeedbacks.slice((currentPage - 1) * pageSize, currentPage * pageSize)

  return (
    <div className='overflow-hidden w-full relative'>
      <ScaleInOut delay={0.3}>
        <img
          src='/assets/gif/Loop 3 Dark.gif'
          className='absolute hidden dark:block -top-52 left-1/4 max-md:left-0 max-sm:-top-20 -z-20'
        />
        <img
          src='/assets/gif/Loop 3 Light.gif'
          className='absolute block dark:hidden -top-52 left-1/4 max-md:left-0 max-sm:-top-20 -z-20'
        />
      </ScaleInOut>
      <img
        src='/assets/gif/Loop 2 Dark.gif'
        className='absolute hidden dark:block top-60 max-sm:top-80 left-0 w-full -z-30 -rotate-90'
      />
      <img
        src='/assets/gif/Loop 2 Light.gif'
        className='absolute block dark:hidden top-60 max-sm:top-80 left-1/3 -z-30 -rotate-90'
      />

      <div className='px-5 py-4 md:px-12 detail-container'>
        <div className='flex flex-col items-start gap-10 md:flex-row md:justify-center md:items-center md:self-stretch xl:px-20 my-20'>
          <ScaleInOut delay={0.6}>
            <p className='text-50 dark:text-white text-black font-normal md:tracking-wider font-bruno'>
              What Our Client Think of Us
            </p>
          </ScaleInOut>
        </div>
        <FadeIn duration={0.5} y={1200} delay={1.5}>
          <div className='h-fit mt-10 pb-300'>
            <ImageCarousel />
          </div>
          <TabGroup>
            <TabList className='flex space-x-1 bg-gray-200 p-1 rounded-xl justify-between items-center'>
              <div className=' flex w-1/2 gap-2'>
                {['NEWEST', 'WEB', 'MOBILE', 'WEB3', 'AI'].map((stack: string, index) => (
                  <Tab
                    key={index}
                    className={({ selected }) =>
                      `text-14 w-1/4 sm:w-1/4 md:w-1/3 lg:w-1/5 h-12 font-archivo flex justify-center items-center gap-2 rounded-100 dark:text-white text-black ${
                        selected
                          ? 'border dark:border-radiusButtonBorderColorWhite border-radiusButtonBorderColorBlack hover:border-black hover:dark:border-white focus:dark:border-white focus:border-black'
                          : ''
                      }`
                    }
                    onClick={() => handleFilter(stack)}
                  >
                    {stack}
                  </Tab>
                ))}
              </div>
              <div className=' w-1/3 flex flex-col gap-8 my-8 md:mt-16 md:mb-12'>
                <input
                  type='text'
                  name='searchTerm'
                  placeholder='Ex: Web'
                  value={search}
                  onChange={e => onSearch(e.target.value)}
                  className='block w-full pl-14 py-3 text-14 tracking-widest rounded-100 border dark:border-radiusButtonBorderColorWhite border-radiusButtonBorderColorBlack dark:text-white text-black hover:border-black hover:dark:border-white focus:dark:border-white focus:border-black dark:placeholder:text-white placeholder:text-black dark:bg-black bg-white focus:outline-none font-archivo'
                />
              </div>
            </TabList>
            <TabPanels>
              {['NEWEST', 'WEB', 'MOBILE', 'WEB3', 'AI'].map((stack: string, index) => (
                <TabPanel key={index}>
                  <div className='flex flex-col'>
                    {paginatedFeedbacks.map((feedback, feedIndex) => (
                      <div key={feedIndex}>
                        <div className='py-6 md:py-12 flex flex-col items-start border-b border-solid border-gray'>
                          <p className='text-18 dark:text-white text-black font-archivo'>{feedback.title}</p>
                          <p className='text-contactTitle text-18 font-archivo'>{feedback.description}</p>
                          <div className='mt-5 md:mt-6 flex flex-row items-center gap-7'>
                            <img
                              src={feedback.img}
                              alt={feedback.img}
                              className='w-9 h-9 md:w-16 md:h-16 rounded-full'
                            />
                            <div>
                              <p className='text-18 font-medium dark:text-white text-black font-archivo'>
                                {feedback.name}
                              </p>
                              <p className='text-14 font-normal text-contactTitle font-archivo'>{feedback.position}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </TabPanel>
              ))}
              {/* Add other Tab.Panels for each stack type if needed */}
            </TabPanels>
          </TabGroup>
          <Pagination
            currentPage={currentPage}
            totalCount={filteredFeedbacks.length}
            pageSize={pageSize}
            onPageChange={page => setCurrentPage(page)}
            siblingCount={1}
            className='mt-8'
          />
        </FadeIn>
      </div>
    </div>
  )
}

export default FeedBack
