import React, { useState, useEffect, useLayoutEffect } from 'react'
import { Link } from 'react-router-dom'
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react'

import SearchableDropdown from 'src/components/Search/SearchableDropdown'
import RadiusButtonArrow from 'src/components/RadiusButtonArrow'
import stacks from 'src/constants/stacks'
import projectData from 'src/constants/projects'
import ScaleInOut from 'src/animation/ScaleInOut'
import RotateInOut3D from 'src/animation/RotateInOut3D'
import useIsomorphicLayoutEffect from 'src/animation/useIsomorphicLayoutEffect'
const Project: React.FC = () => {
  const projects = projectData.projects
  const [filteredProjects, setFilteredProjects] = useState(projects)
  const [value, setValue] = useState<string | null>(null)
  const [flag, setFlag] = useState<boolean>(false)

  const filterProjects = (value: string) => {
    if (value === 'All Stacks' || !value) {
      setFilteredProjects(projects)
    } else {
      const filtered = projects.filter(project => project.stack.toLowerCase() === value.toLowerCase())
      setFilteredProjects(filtered)
    }
  }
  const handleFilter = (stack: string) => {
    if (stack) {
      filterProjects(stack)
    } else {
      setFilteredProjects(projects)
    }
  }
  useEffect(() => {
    if (value) {
      handleFilter(value)
    } else {
      setFilteredProjects(projects)
    }
  }, [value])

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 500)
  }, [])

  return (
    <div className='overflow-x-hidden detail-container px-5 py-14 md:px-6 relative'>
      <ScaleInOut delay={0.5}>
        <img
          src='/assets/gif/Loop 3 Dark.gif'
          className='absolute hidden dark:block -top-52 left-1/4 max-md:left-0 max-sm:-top-20 -z-20'
        />
        <img
          src='/assets/gif/Loop 3 Light.gif'
          className='absolute block dark:hidden -top-52 left-1/4 max-md:left-0 max-sm:-top-20 -z-20'
        />
      </ScaleInOut>
      <ScaleInOut delay={1}>
        <div className='flex flex-col items-start gap-10 md:flex-row md:justify-center md:items-center md:self-stretch mt-10'>
          <p className='text-50 dark:text-white text-black font-normal md:tracking-wider font-bruno'>Projects</p>
        </div>
      </ScaleInOut>

      <RotateInOut3D duration={1} delay={1.1} offsetY={100}>
        <TabGroup>
          <TabList className='flex space-x-1 bg-gray-200 p-1 rounded-xl justify-between items-center'>
            <div className=' flex w-1/2 gap-2'>
              {['All Stacks', 'WEB', 'MOBILE', 'WEB3', 'AI'].map((stack: string, index: number) => (
                <Tab
                  key={index}
                  className={({ selected }) =>
                    `text-14 relative w-1/5 sm:w-1/4 md:w-1/3 lg:w-1/5 h-12 font-archivo flex justify-center items-center gap-2 rounded-100  dark:text-white hover:bg-customWhiteGray dark:hover:bg-gray transition-all duration-500 ease-in-out  ${
                      selected
                        ? 'border dark:border-radiusButtonBorderColorWhite border-radiusButtonBorderColorBlack dark:text-white text-black hover:border-black hover:dark:border-white focus:dark:border-white focus:border-black'
                        : ''
                    }`
                  }
                  onClick={() => handleFilter(stack)}
                >
                  {stack}
                </Tab>
              ))}
            </div>
            <div className=' w-1/3 flex flex-col gap-8 my-8 md:mt-16 md:mb-12'>
              <SearchableDropdown
                options={stacks}
                label='name'
                id='id'
                selectedVal={value}
                placeholder='SEARCH BY TECH STACK'
                handleChange={val => setValue(val)}
                className='border-customGray relative font-archivo'
              />
            </div>
          </TabList>
          <TabPanels>
            {['All Stacks', 'WEB', 'MOBILE', 'WEB3', 'AI'].map((stack, index) => (
              <TabPanel key={index}>
                <div className='grid grid-cols-1 gap-x-6 gap-y-12 sm:grid-cols-2 lg:grid-cols-3 md:gap-12 animatecss animatecss-zoomIn animatecss-slow'>
                  {filteredProjects.map(project => (
                    <div key={project.id}>
                      <Link to={`/projects/${project.id}`} className='group flex flex-col h-full'>
                        <div className='relative w-full h-60 lg:h-60 xl:h-100 overflow-hidden rounded-xl border border-imageBorderColor'>
                          <img
                            src={project.imageSrc}
                            className='h-full w-full object-cover object-center group-hover:opacity-85 scale-125 transition ease-in-out duration-500 hover:scale-100'
                            alt={project.name}
                          />
                          <div className='absolute top-5 left-5 flex flex-wrap gap-2'>
                            {project.tags.map((tagName, index) => (
                              <div key={index} className='flex px-3 py-1 justify-center items-center tag'>
                                <p className='text-white text-14 font-archivo'>{tagName}</p>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className='flex flex-col flex-grow'>
                          <h3 className='mt-6 text-18 dark:text-white text-black font-archivo'>{project.name}</h3>
                          <p className='mt-2 text-14 text-contactTitle font-archivo'>{project.description}</p>
                          <div className='mt-auto'>
                            <RadiusButtonArrow title='VIEW' className='mt-6 w-24 font-archivo' />
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
              </TabPanel>
            ))}
          </TabPanels>
        </TabGroup>
      </RotateInOut3D>
    </div>
  )
}
export default Project
