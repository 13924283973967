import React, { useEffect, useState } from 'react'

import gsap from 'gsap-trial'
import ScrollTrigger from 'gsap-trial/dist/ScrollTrigger'

import primarySkillsData from 'src/constants/primarySkills'

import SkillRow from 'src/components/SkillRow'
import IconButton from 'src/components/IconButton'
import RadiusButton from 'src/components/RadiusButton'

import { ReactComponent as StarIcon } from 'src/assets/icon/star.svg'
import { ReactComponent as BlueStarIcon } from 'src/assets/icon/star1.svg'

gsap.registerPlugin(ScrollTrigger)
const PrimaryDevSkill: React.FC = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(0)

  const handleRowClick = (index: number) => {
    setOpenIndex(openIndex === index ? null : index)
  }

  const Line = () => {
    return <div style={{ height: '1px', background: '#353535' }} />
  }

  useEffect(() => {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: '#devskill',
          start: 'top 30%',
          end: 'top bottom',
          scrub: 2
        }
      })
      .fromTo(
        `.skill-titles`,
        {
          y: -200,
          opacity: 0,
          duration: 0.25,
          ease: 'power4.in'
        },
        {
          opacity: 1,
          delay: 1,
          duration: 1,
          y: 0,
          ease: 'bounce.out'
        }
      )

    gsap
      .timeline({
        scrollTrigger: {
          trigger: '#devskill',
          start: 'top 30%',
          end: 'top bottom',
          scrub: 2
        }
      })
      .fromTo(
        '.see-all-skills',
        {
          y: -200,
          opacity: 0,
          duration: 0.25,
          ease: 'power4.in'
        },
        {
          opacity: 1,
          delay: 2,
          duration: 1,
          y: 0,
          ease: 'bounce.out'
        }
      )

    gsap
      .timeline({
        scrollTrigger: {
          trigger: '#devskill',
          start: 'top 30%',
          end: 'top bottom',
          scrub: 2
        }
      })
      .fromTo(
        '.all-skill-items',
        {
          y: -200,
          opacity: 0,
          duration: 0.25,
          ease: 'power4.in'
        },
        {
          opacity: 1,
          delay: 3,
          duration: 1,
          y: 0,
          ease: 'bounce.out'
        }
      )
  }, [])

  return (
    <div id='devskill' className='detail-container relative'>
      <img
        className='dark:block hidden absolute -bottom-70 left-1/4 -z-20'
        src='/assets/gif/BlueDark.gif'
        alt='Blue green'
      />
      <img
        className='absolute dark:hidden block -bottom-70 left-1/4 -z-20 bg-white'
        src='/assets/gif/Loop 1 Blue Light.gif'
        alt='Blue green'
      />
      <div className='px-5 mt-40 pt-10 md:px-12'>
        <div className='flex flex-col justify-center items-start gap-10 mb-10 md:flex-row md:justify-between md:items-center md:self-stretch'>
          <div className='flex'>
            <p className='text-50 dark:text-white text-black font-normal md:tracking-wider skill-titles'>
              <span className='block relative'>
                Primary
                <span className='absolute object5' style={{ transform: 'translate(-35%, -30%)' }}>
                  <div className='relative flex justify-center items-center '>
                    <BlueStarIcon />
                    <StarIcon className='absolute dark:bg-white bg-green blur-[3rem] lg:blur-[3rem] boaming-image ' />
                  </div>
                </span>
              </span>
              <span className='block break-all'>Development Skills</span>
            </p>
          </div>
          <div className='w-full md:w-auto see-all-skills'>
            <RadiusButton
              title='SEE ALL SKILLS'
              className='font-archivo font-semibold transition-all duration-500 ease-in-out filter hover:grayscale-0 lg:grayscale-[70%] bg-illicitGreen text-black dark:border-black px-6 py-4'
              to='/skill'
            />
          </div>
        </div>

        <div className='font-archivo all-skill-items'>
          {primarySkillsData.skills.map((skill, index) => (
            <div key={skill.id}>
              <Line />
              <SkillRow
                name={skill.name}
                description={skill.description}
                isOpen={openIndex === index}
                onToggle={() => handleRowClick(index)}
              >
                {skill.icons.map((IconComponent, index) => (
                  <div key={index} className=''>
                    <IconButton
                      title={skill.lang[index]}
                      icon={<IconComponent />}
                      darkIcon={<IconComponent />}
                      className='font-archivo'
                    />
                  </div>
                ))}
              </SkillRow>
            </div>
          ))}
          <Line />
        </div>
      </div>
    </div>
  )
}

export default PrimaryDevSkill
