import { Feedback } from 'src/types'

const feedbackData: Feedback[] = [
  {
    id: 0,
    title: 'Awesome Job!!',
    description:
      'I had the pleasure of working with SilverDigital Bus  on a recent project, and I was blown away by their creativity and attention to detail. I highly recommend Silver Digital Bus for anyone looking for a talented and professional software development team.',
    img: '/assets/avatar/avatar1.png',
    name: 'Amandha gUilre',
    position: 'CEO of Rackspace',
    stack: 'web'
  },
  {
    id: 1,
    title: 'I can’t compare!',
    description:
      'I highly recommend SilverDigital Bus for anyone looking for a talented and professional software development team.',
    img: '/assets/avatar/avatar2.png',
    name: 'Harry ahmed',
    position: 'CEO of Rackspace',
    stack: 'web'
  },
  {
    id: 2,
    title: 'Nice job!',
    description:
      'I had the pleasure of working with SilverDigital Bus on a recent project, and I was blown away by their creativity and attention to detail. I highly recommend Silver Digital Bus for anyone looking for a talented and professional software development team.',
    img: '/assets/avatar/avatar3.png',
    name: 'Amandha gUilr',
    position: 'CEO of Rackspace',
    stack: 'web'
  },
  {
    id: 3,
    title: 'Awesome Job!!',
    description:
      'I had the pleasure of working with SilverDigital Bus on a recent project, and I was blown away by their creativity and attention to detail. I highly recommend Silver Digital Bus for anyone looking for a talented and professional software development team.',
    img: '/assets/avatar/avatar4.png',
    name: 'Germondudh',
    position: 'CEO of Rackspace',
    stack: 'web'
  },
  {
    id: 4,
    title: 'Awesome Job!!',
    description:
      'I had the pleasure of working with SilverDigital Bus on a recent project, and I was blown away by their creativity and attention to detail. I highly recommend Silver Digital Bus for anyone looking for a talented and professional software development team.',
    img: '/assets/avatar/avatar5.png',
    name: 'Germondudh',
    position: 'CEO of Rackspace',
    stack: 'mobile'
  },
  {
    id: 5,
    title: 'Awesome Job!!',
    description:
      'I had the pleasure of working with SilverDigital Bus on a recent project, and I was blown away by their creativity and attention to detail. I highly recommend Silver Digital Bus for anyone looking for a talented and professional software development team.',
    img: '/assets/avatar/avatar6.png',
    name: 'Germondudh',
    position: 'CEO of Rackspace',
    stack: 'mobile'
  },
  {
    id: 6,
    title: 'Awesome Job!!',
    description:
      'I had the pleasure of working with SilverDigital Bus on a recent project, and I was blown away by their creativity and attention to detail. I highly recommend Silver Digital Bus for anyone looking for a talented and professional software development team.',
    img: '/assets/avatar/avatar7.png',
    name: 'Germondudh',
    position: 'CEO of Rackspace',
    stack: 'mobile'
  },
  {
    id: 7,
    title: 'Awesome Job!!',
    description:
      'I had the pleasure of working with SilverDigital Bus on a recent project, and I was blown away by their creativity and attention to detail. I highly recommend Silver Digital Bus for anyone looking for a talented and professional software development team.',
    img: '/assets/avatar/avatar8.png',
    name: 'Germondudh',
    position: 'CEO of Rackspace',
    stack: 'mobile'
  },
  {
    id: 8,
    title: 'Awesome Job!!',
    description:
      'I had the pleasure of working with SilverDigital Bus on a recent project, and I was blown away by their creativity and attention to detail. I highly recommend Silver Digital Bus for anyone looking for a talented and professional software development team.',
    img: '/assets/avatar/avatar9.png',
    name: 'Germondudh',
    position: 'CEO of Rackspace',
    stack: 'web3'
  },
  {
    id: 9,
    title: 'Awesome Job!!',
    description:
      'I had the pleasure of working with SilverDigital Bus on a recent project, and I was blown away by their creativity and attention to detail. I highly recommend Silver Digital Bus for anyone looking for a talented and professional software development team.',
    img: '/assets/avatar/avatar10.png',
    name: 'Germondudh',
    position: 'CEO of Rackspace',
    stack: 'web3'
  },
  {
    id: 10,
    title: 'Awesome Job!!',
    description:
      'I had the pleasure of working with SilverDigital Bus on a recent project, and I was blown away by their creativity and attention to detail. I highly recommend Silver Digital Bus for anyone looking for a talented and professional software development team.',
    img: '/assets/avatar/avatar11.png',
    name: 'Germondudh',
    position: 'CEO of Rackspace',
    stack: 'web3'
  },
  {
    id: 11,
    title: 'Awesome Job!!',
    description:
      'I had the pleasure of working with SilverDigital Bus on a recent project, and I was blown away by their creativity and attention to detail. I highly recommend Silver Digital Bus for anyone looking for a talented and professional software development team.',
    img: '/assets/avatar/avatar12.png',
    name: 'Germondudh',
    position: 'CEO of Rackspace',
    stack: 'ai'
  },
  {
    id: 12,
    title: 'Awesome Job!!',
    description:
      'I had the pleasure of working with SilverDigital Bus on a recent project, and I was blown away by their creativity and attention to detail. I highly recommend Silver Digital Bus for anyone looking for a talented and professional software development team.',
    img: '/assets/avatar/avatar5.png',
    name: 'Germondudh',
    position: 'CEO of Rackspace',
    stack: 'ai'
  },
  {
    id: 13,
    title: 'Awesome Job!!',
    description:
      'I had the pleasure of working with SilverDigital Bus on a recent project, and I was blown away by their creativity and attention to detail. I highly recommend Silver Digital Bus for anyone looking for a talented and professional software development team.',
    img: '/assets/avatar/avatar8.png',
    name: 'Germondudh',
    position: 'CEO of Rackspace',
    stack: 'ai'
  }
]

export default feedbackData
